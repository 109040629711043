import React from 'react';
import { useParams } from 'react-router';
import { Container, Grid } from '@material-ui/core';
import { useQuery } from 'react-query';
import Page from 'src/components/Page';
import Customer from 'src/api/customer';
import BalanceCard from './BalanceCard';
import WalletTransactions from './WalletTransactions';

const CustomerWallet = () => {
  const { id } = useParams();
  //   const classes = useStyles();
  //   const navigate = useNavigate();

  const { data = {}, isLoading, refetch } = useQuery(
    'customerWallet',
    () => Customer.getCustomerWallet(id),
    {
      keepPreviousData: true
    }
  );

  const handleRefetch = () => {
    refetch();
    console.log('refetched fired>>>');
  };

  console.log('data', data);
  return (
    <Page title="Customer Wallet">
      <Container maxWidth="lg">
        <Grid
          container
          spacing={3}
          direction="column"
          //   justify="center"
          alignItems="center"
        >
          <Grid style={{ minWidth: '50%' }}>
            <BalanceCard
              balance={data.balance}
              data={data}
              handleRefetch={handleRefetch}
            />
          </Grid>
          <Grid item xs={12}>
            <WalletTransactions
              isLoading={isLoading}
              recentTransactions={data.recentTransactions}
              handleRefetch={handleRefetch}
            />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default CustomerWallet;
