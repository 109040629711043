import {
  Badge,
  Box,
  Card,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import React, { useEffect, useState } from 'react';
import TableLoader from 'src/components/TableLoader';
import PropTypes from 'prop-types';
import moment from 'moment';
import truncateSentence from 'src/utils/truncateSentence';
// import ReportRow from 'src/components/ReportRow';
// import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
// import TripStatus from 'src/components/TripStatus';

const useStyles = makeStyles(theme => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  },
  tableRow: {
    cursor: 'pointer'
  },
  sortIcons: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    overflow: 'hidden',
    width: 'fit-content',
    margin: 0,
    fontWeight: 500
  },
  tableHeadStyling: {
    display: 'flex',
    flexDirection: 'row',
    overflow: 'hidden',
    backgroundColor: 'transparent',
    border: 'none'
  },
  verifiedSortedDriver: {
    display: 'flex',
    justifyContent: 'right'
  },
  tablecell: {
    borderBottom: '1px solid lightgrey'
  }
}));

export default function Results({
  isLoading,
  pagination,
  reports,
  setQueryString
}) {
  const classes = useStyles();

  const [customerReports, setCustomerReports] = useState(reports);
  // const navigate = useNavigate();
  // console.log('customers reports>>>', customerReports);
  console.log(isLoading);
  const handleRowsPerPageChange = event => {
    setQueryString({ rows: event.target.value });
  };

  const handlePageChange = (event, newPage) => {
    setQueryString({ pageNo: newPage + 1 });
  };

  useEffect(() => {
    if (reports && pagination) {
      setCustomerReports(reports);
    }
  }, [reports, pagination]);
  return (
    <Card className={classes.root}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          {isLoading ? (
            <TableLoader />
          ) : (
            <Table>
              <TableHead>
                <TableRow className={classes.tablecell}>
                  <TableCell>
                    <Typography className={classes.sortIcons}>
                      Reported Time
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography className={classes.sortIcons}>
                      Reporter Name
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography className={classes.sortIcons}>
                      Reported Name
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography className={classes.sortIcons}>
                      Description
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography className={classes.sortIcons}>
                      Reported By
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography className={classes.sortIcons}>
                      Reported Status
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography className={classes.sortIcons}>
                      View Report Details
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography className={classes.sortIcons}>
                      Booking ID
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {customerReports.map(report => (
                  // <ReportRow key={report.id} report={report} hover />
                  <TableRow
                    style={{ cursor: 'pointer' }}
                    // hover
                    // onClick={() => navigate()}
                  >
                    <TableCell>
                      <Typography className={classes.sortIcons}>
                        {moment(report.createdAt).format('MMM Do, YYYY h:mm A')}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography className={classes.sortIcons}>
                        {report.reportedByName}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography className={classes.sortIcons}>
                        {report.reportedName}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography className={classes.sortIcons}>
                        {truncateSentence(report.description, 30)}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography className={classes.sortIcons}>
                        {report.reportedBy}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Badge
                        badgeContent={report.resolved ? 'Resolved' : 'Disputed'}
                        color={report.resolved ? 'primary' : 'error'}
                      />
                    </TableCell>
                    <TableCell>
                      {/* redirect to a new tab */}
                      <Link
                        to="#"
                        onClick={e => {
                          e.preventDefault();
                          window.open(
                            `/app/reports/${report.id}`,
                            '_blank',
                            'noopener,noreferrer'
                          );
                        }}
                      >
                        View Report Details
                      </Link>
                    </TableCell>
                    <TableCell>
                      <Typography className={classes.sortIcons}>
                        <Link
                          to="#"
                          onClick={e => {
                            e.preventDefault();
                            window.open(
                              `/app/bookings/${report.bookingId}`,
                              '_blank',
                              'noopener,noreferrer'
                            );
                          }}
                        >
                          {report.bookingId !== null
                            ? report.bookingId
                            : 'No Available Booking ID'}
                        </Link>
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </Box>
      </PerfectScrollbar>
      {pagination && (
        <TablePagination
          count={pagination?.totalCount}
          page={pagination?.currentPage}
          rowsPerPage={pagination?.rows}
          onChangeRowsPerPage={handleRowsPerPageChange}
          onChangePage={handlePageChange}
          rowsPerPageOptions={[5, 10, 25]}
        />
      )}
    </Card>
  );
}

Results.propTypes = {
  isLoading: PropTypes.bool,
  pagination: PropTypes.object,
  reports: PropTypes.array,
  setQueryString: PropTypes.func
};
