import config from 'src/config';
import api from 'src/services/httpService';

class Auth {
  static signIn({ email, password }) {
    return api.post(`${config.rootUrl}/auth/admin/signin`, {
      email,
      password
    });
  }

  static signup(data) {
    return api.post(`${config.rootUrl}/auth/admin/signup`, data);
  }

  static resetPassword(data) {
    return api.post(`${config.rootUrl}/auth/admin/reset`, data);
  }
}

export default Auth;
