import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography,
  makeStyles
} from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import {
  BarChart as BarChartIcon,
  User as UserIcon,
  // New Icons
  // Radio as DMailIcon,
  // Twitter as TwitterIcon,
  UserCheck as DriverIcon,
  // MessageSquare as MessageIcon,
  // End New Icons
  Plus as PlusIcon,
  Users as UsersIcon,
  List as ListIcon,
  Mail as MailIcon
} from 'react-feather';
import {
  DriveEta as DriveEtaIcon,
  // History as HistoryIcon,
  Settings as SettingsIcon,
  Payment as PaymentIcon,
  // LibraryBooks as LibraryBooksIcon,
  Assignment as AssignmentIcon
} from '@material-ui/icons';
import { connect } from 'react-redux';
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';
import PersonAddDisabledIcon from '@material-ui/icons/PersonAddDisabled';
import BuildIcon from '@material-ui/icons/Build';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
// import HistoryIcon from '@material-ui/icons/History';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import EmojiTransportationIcon from '@material-ui/icons/EmojiTransportation';
import ReportIcon from '@material-ui/icons/Report';
import AddLocationIcon from '@material-ui/icons/AddLocation';

import NavItem from './NavItem';

const items = [
  {
    href: '/app/dashboard',
    icon: BarChartIcon,
    title: 'Dashboard'
  },
  {
    icon: UsersIcon,
    title: 'Customers',
    href: '/app/customers'
  },
  {
    icon: DriverIcon,
    title: 'Drivers',
    href: '/app/drivers'
  },
  {
    icon: UserIcon,
    title: 'Admins',
    subMenuItems: [
      {
        title: 'Manage Admins',
        href: '/app/admins',
        icon: ListIcon
      },
      {
        title: 'Add admin',
        href: '/app/admins/new',
        icon: PlusIcon
      }
    ]
  },

  {
    icon: EmojiTransportationIcon,
    title: 'Ride',
    subMenuItems: [
      {
        href: '/app/trips',
        icon: DriveEtaIcon,
        title: 'Trips'
      },
      {
        href: '/app/bookings',
        icon: PaymentIcon,
        title: 'Bookings'
      },
      {
        href: '/app/trips/locations',
        icon: AddLocationIcon,
        title: 'Avaliable Locations'
      }
    ]
  },

  // {
  //   href: '/app/wallet/transactions',
  //   icon: AssignmentIcon,
  //   title: 'Wallet transactions'
  // },

  {
    icon: AccountBalanceWalletIcon,
    title: 'Wallet',
    subMenuItems: [
      {
        href: '/app/wallet/transactions',
        icon: AssignmentIcon,
        title: 'Wallet Transactions'
      },
      {
        href: '/app/wallet/withdrawals',
        icon: AccountBalanceIcon,
        title: 'Wallet Withdrawals'
      }
    ]
  },
  // {
  //   icon: HistoryIcon,
  //   title: 'Wallet History',
  //   subMenuItems: [
  //     {
  //       title: 'Wallet Withdrawals',
  //       href: '/app/wallet/withdrawals',
  //       icon: LibraryBooksIcon
  //     },
  //     {
  //       title: 'Wallet transactions',
  //       href: '/app/wallet/transactions',
  //       icon: AssignmentIcon
  //     }
  //   ]
  // },

  {
    href: '/app/message/new',
    icon: MailIcon,
    title: 'Send Message'
  },
  {
    icon: EventAvailableIcon,
    title: 'Activity Log',
    href: '/app/activity-log'
  },

  {
    href: '/app/refund-requests',
    icon: CompareArrowsIcon,
    title: 'Refund Requests'
  },
  // {
  //   href: '/app/message/new',
  //   icon: TwitterIcon,
  //   title: 'Send User Bulk Email'
  // },
  // {
  //   href: '/app/message/new',
  //   icon: MessageIcon,
  //   title: 'Send Driver Bulk Email'
  // },
  // {
  //   href: '/app/message/new',
  //   icon: DMailIcon,
  //   title: 'All Users Email Logs'
  // },
  {
    icon: ReportIcon,
    title: 'Reports',
    href: '/app/reports'
  },

  {
    icon: SettingsIcon,
    title: 'Settings',
    subMenuItems: [
      {
        icon: PersonAddDisabledIcon,
        title: 'Deletion Requests',
        href: '/app/deletion-request'
      },
      {
        href: '/app/settings',
        icon: BuildIcon,
        title: 'Config'
      }
    ]
  }
];

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64,
    marginBottom: 10
  },
  green: {
    color: '#fff',
    backgroundColor: green[500]
  },

  capitalize: {
    textTransform: 'capitalize'
  }
}));

const NavBar = ({ onMobileClose, openMobile, user: userProps }) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const location = useLocation();
  const { firstName, lastName, role } = userProps;

  const user = {
    jobTitle: role,
    name: `${firstName} ${lastName}`
  };

  const avatar = (
    <Avatar
      onClick={() => navigate(`profile`)}
      className={`${classes.avatar} ${classes.green}`}
    >
      <UserIcon />
    </Avatar>
  );

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const filterItems =
    role === 'super'
      ? items
      : items.filter(
          item => item.title !== 'Admins' && item.title !== 'Settings'
        );

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <Box alignItems="center" display="flex" flexDirection="column" p={2}>
        {avatar}
        <Typography className={classes.name} color="textPrimary" variant="h5">
          {user.name}
        </Typography>
        <Typography
          color="textSecondary"
          variant="body2"
          className={classes.capitalize}
        >
          {user.jobTitle}
        </Typography>
      </Box>
      <Divider />
      <Box p={2}>
        <List>
          {filterItems.map(item => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
              subMenuItems={item.subMenuItems}
            />
          ))}
        </List>
      </Box>
      <Box flexGrow={1} />
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
  user: PropTypes.object
};

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false
};

const mapStateToProps = (state /* , ownProps */) => {
  return {
    user: state.user
  };
};

export default connect(mapStateToProps)(NavBar);
