import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import storage from 'src/storage';
import { useNavigate } from 'react-router';

const IsLoggedIn = (props) => {
  const { component } = props;
  const Component = component;
  const navigate = useNavigate();

  useEffect(() => {
    const isAuthenticated = storage.getUser();
    if (isAuthenticated) {
      navigate('/app/dashboard', { replace: true });
    }
  }, [navigate]);

  return <Component />;
};

IsLoggedIn.propTypes = {
  component: PropTypes.elementType
};
export default IsLoggedIn;
