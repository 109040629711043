import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  makeStyles,
  IconButton
} from '@material-ui/core';
import qs from 'qs';
import { Search as SearchIcon } from 'react-feather';
import { useLocation, useNavigate } from 'react-router';

const useStyles = makeStyles((theme) => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  searchBox: {
    display: 'flex',
    alignItems: 'center'
  },
  searchInput: {
    flex: 1,
    marginRight: 10
  },
  searchButton: {
    width: 50,
    height: 50
  }
}));

const Toolbar = ({ className, ...rest }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const classes = useStyles();
  const [searchWord, setSearchWord] = useState('');

  useEffect(() => {
    const { search } = location;
    const query = qs.parse(search, { ignoreQueryPrefix: true });
    if (query.searchWord) {
      setSearchWord(query.searchWord);
    }
  }, [location]);

  const handleSearch = () => {
    const { pathname } = location;
    navigate(`${pathname}?${qs.stringify({ searchWord })}`);
  };

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      {/* <Box
        display="flex"
        justifyContent="flex-end"
      >
        <Button className={classes.importButton}>
          Import
        </Button>
        <Button className={classes.exportButton}>
          Export
        </Button>
        <Button
          color="primary"
          variant="contained"
        >
          Add customer
        </Button>
      </Box> */}
      <Box mt={3}>
        <Card>
          <CardContent>
            <Box className={classes.searchBox} maxWidth={500}>
              <TextField
                className={classes.searchInput}
                value={searchWord}
                onChange={(event) => {
                  setSearchWord(event.target.value);
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon
                        fontSize="small"
                        color="action"
                      >
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  )
                }}
                placeholder="Search admin"
                variant="outlined"
              />
              <IconButton className={classes.searchButton} aria-label="search" onClick={handleSearch}>
                <SearchIcon />
              </IconButton>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string
};

export default Toolbar;
