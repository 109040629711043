import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';

export default function TableLoader() {
  return (
    <div>
      <LinearProgress />
    </div>
  );
}
