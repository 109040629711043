import {
  call, put, takeEvery
} from 'redux-saga/effects';
import Admin from 'src/api/admin';
import User from 'src/api/user';
import { adminActionList, axiosActionList } from '../action-lists';

function* getAdminsWorker(action) {
  try {
    const res = yield call(Admin.getAdmins, action.payload);
    yield put({ type: adminActionList.updateAdmins, payload: res.data });
  } catch (err) {
    yield put({ type: axiosActionList.axiosFailed, message: err.message });
  }
}

export function* getAdminsWatcher() {
  yield takeEvery(axiosActionList.axiosGetAdmins, getAdminsWorker);
}

function* getAdminWorker(id) {
  try {
    const res = yield call(User.getUser, id);
    yield put({ type: adminActionList.updateUser, payload: res });
  } catch (err) {
    yield put({ type: axiosActionList.axiosFailed, message: err.message });
  }
}

export function* getUserWatcher() {
  yield takeEvery(axiosActionList.axiosGetAdmin, getAdminWorker);
}

export default getAdminsWatcher;
