import React, { useEffect, useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  Container,
  makeStyles,
  TextField,
  CardHeader,
  Divider,
  Button,
  Grid,
  // MenuItem
} from '@material-ui/core';
import Page from 'src/components/Page';
import { useAdminSettings } from 'src/contexts/AdminSettingsContext';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const SettingsView = () => {
  const classes = useStyles();
  const {
    settings: defaultSettings,
    updateSettings,
    isLoading
  } = useAdminSettings();
  const [settings, setSettings] = useState(defaultSettings);

  useEffect(() => {
    setSettings(defaultSettings);
  }, [defaultSettings]);

  const handleChange = evt => {
    const { value, name } = evt.target;
    setSettings(prevSettings => ({ ...prevSettings, [name]: value }));
  };

  return (
    <Page className={classes.root} title="Settings">
      <Container maxWidth="lg">
        <Grid container justify="center">
          <Grid item lg={4}>
            <Card>
              <CardHeader title="App settings" />
              <Divider />
              <CardContent>
                <form
                  onSubmit={e => {
                    e.preventDefault();
                    updateSettings(settings);
                  }}
                >
                  {/* <TextField
                    variant="outlined"
                    label="Currency"
                    name="pricingCurrency"
                    select
                    value={settings.pricingCurrency}
                    fullWidth
                    onChange={handleChange}
                    margin="normal"
                    disabled={isLoading}
                  >
                    {['NGN', 'USD', 'GBP'].map(option => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField> */}
                  <TextField
                    variant="outlined"
                    label="Pricing"
                    name="pricing"
                    type="number"
                    value={settings.pricing}
                    fullWidth
                    onChange={handleChange}
                    margin="normal"
                    disabled={isLoading}
                  />
                  <TextField
                    variant="outlined"
                    label="Rate"
                    name="rate"
                    type="number"
                    value={settings.rate}
                    fullWidth
                    onChange={handleChange}
                    margin="normal"
                    disabled={isLoading}
                  />
                  <TextField
                    variant="outlined"
                    type="number"
                    name="refundProcessDays"
                    label="Refund Process Days"
                    value={settings.refundProcessDays}
                    fullWidth
                    onChange={handleChange}
                    margin="normal"
                    disabled={isLoading}
                  />
                  <TextField
                    label="Payout Process days"
                    variant="outlined"
                    name="payoutProcessDays"
                    type="number"
                    value={settings.payoutProcessDays}
                    fullWidth
                    onChange={handleChange}
                    margin="normal"
                    disabled={isLoading}
                  />
                  <Box display="flex" mt={2} justifyContent="flex-end">
                    <Button
                      disabled={isLoading}
                      color="primary"
                      variant="contained"
                      type="submit"
                    >
                      {isLoading ? 'Saving...' : 'Save'}
                    </Button>
                  </Box>
                </form>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default SettingsView;
