import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  makeStyles,
  Container,
  Box,
  Table,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
  Card,
  CircularProgress
} from '@material-ui/core';
import { useQuery } from 'react-query';
import PerfectScrollbar from 'react-perfect-scrollbar';
import config from 'src/config';
import storage from 'src/storage';
import BookingRow from 'src/components/BookingRow';
import Page from 'src/components/Page';
import TablePagination from 'src/components/TablePagination';
import useQueryString from 'src/hooks/useQueryString';
import api from 'src/services/httpService';
// import Filter from './Filter';

const useStyles = makeStyles(() => ({
  root: {},
  nowrap: {
    whiteSpace: 'nowrap'
  }
}));

const fetchBookings = async (id, query) => {
  const axiosConfig = {
    headers: {
      Authorization: `Bearer ${storage.getToken()}`
    },
    params: query
  };
  const { data } = await api.get(
    `${config.rootUrl}/admin/user/booking/${id}`,
    axiosConfig
  );
  return data;
};

export default function CustomerBookings() {
  const { id } = useParams();
  const classes = useStyles();
  const navigate = useNavigate();

  const [query, setQueryString] = useQueryString();

  const { data = {}, isFetching } = useQuery(
    ['bookings', query],
    () => fetchBookings(id, query),
    {
      keepPreviousData: true
    }
  );

  const { data: bookings = [] } = data;
  return (
    <Page className={classes.root} title="Trips">
      <Container maxWidth={false}>
        {/* <Box mb={4}>
          <Filter defaultValues={query} />
        </Box> */}
        <Card>
          <PerfectScrollbar>
            <Box minWidth={1050}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Departure</TableCell>
                    <TableCell>Destination</TableCell>
                    <TableCell>Amount</TableCell>
                    <TableCell>Payment Status</TableCell>
                    <TableCell>Booking Status</TableCell>
                    <TableCell>Departure Date</TableCell>
                    <TableCell>Departure Time</TableCell>
                    <TableCell>Paid On</TableCell>
                  </TableRow>
                </TableHead>
                {isFetching && <CircularProgress size="2rem" />}
                {bookings[0] ? (
                  <TableBody>
                    {bookings.map(booking => (
                      <BookingRow
                        key={booking.id}
                        booking={booking}
                        hover
                        onClick={() => navigate(`/app/trips/${booking.tripId}`)}
                        className={classes.tableRow}
                        style={{ cursor: 'pointer' }}
                      />
                    ))}
                  </TableBody>
                ) : (
                  <Box style={{ margin: '2rem' }}>
                    Customer is yet to book a ride
                  </Box>
                )}
              </Table>
            </Box>
          </PerfectScrollbar>
          {data.pagination && (
            <TablePagination
              count={data.pagination.totalCount}
              page={data.pagination.currentPage}
              rowsPerPage={data.pagination.rows}
              onChangeRowsPerPage={rows => setQueryString({ rows })}
              onChangePage={pageNo => setQueryString({ pageNo })}
            />
          )}
        </Card>
      </Container>
    </Page>
  );
}
