import React, { useEffect, useState } from 'react';
import { Box, Container, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import { connect } from 'react-redux';
import alertSnackbarActions from 'src/store/actions/alertSnackbarActions';
import axiosActions from 'src/store/actions/axiosActions';
import { useQuery } from 'react-query';
import User from 'src/api/user';
import useQueryString from 'src/hooks/useQueryString';
import Results from './Results';
import Toolbar from './Toolbar';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const CustomerListView = () => {
  // const location = useLocation();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [query, setQueryString] = useQueryString();
  const [drivers, setDrivers] = useState([]);
  const [paginationData, setPaginationData] = useState({});
  // useEffect(() => {
  //   const { search } = location;
  //   const queries = qs.parse(search, { ignoreQueryPrefix: true });
  //   axiosGetUsers(queries);
  // }, [location, axiosGetUsers]);

  // const [verifiedDrivers, setVerifiedDrivers] = useState([]);

  // const { users, pagination } = userList;

  const { data = {} } = useQuery(
    ['driver-list', query],
    () => User.getVerifiedDrivers(query),
    {
      keepPreviousData: true
    }
  );

  const { data: verifiedDrivers = [] } = data;

  const handleDatas = () => {
    setDrivers(verifiedDrivers.data);
    setPaginationData(verifiedDrivers.pagination);
  };

  useEffect(() => {
    if (verifiedDrivers) {
      handleDatas();
      setIsLoading(false);
    }
  }, [verifiedDrivers]);

  // console.log('verified api data>>', verifiedDrivers);
  // console.log('driver pagination>>', paginationData);
  // console.log('drivers list>>', drivers);

  return (
    <Page className={classes.root} title="Drivers">
      <Container maxWidth={false}>
        <Toolbar />
        <Box mt={3}>
          <Results
            // verify={}
            customers={drivers}
            pagination={paginationData}
            isLoading={isLoading}
            setQueryString={setQueryString}
          />
        </Box>
      </Container>
    </Page>
  );
};

// CustomerListView.propTypes = {
//   axiosGetUsers: PropTypes.func,
//   userList: PropTypes.object
// };

const mapStateToProps = (state /* , ownProps */) => {
  return {
    alertSnackbar: state.alertSnackbar,
    userList: state.userList
  };
};

const { setOpen, setMessage, setSeverity } = alertSnackbarActions;
const { axiosGetUsers } = axiosActions;

const mapDispatchToProps = {
  setOpen,
  setMessage,
  setSeverity,
  axiosGetUsers
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerListView);
