import React from 'react';
import PropTypes from 'prop-types';
import { TextField, Typography, colors } from '@material-ui/core';

const EditableField = ({
  isEditMode,
  label,
  value,
  renderValue,
  children,
  ...props
}) => {
  return (
    <div>
      {isEditMode ? (
        <TextField label={label} value={value} {...props}>
          {children}
        </TextField>
      ) : (
        <div>
          <Typography style={{ color: colors.grey[500] }} variant="body2">
            {label}
          </Typography>
          <Typography>
            {typeof renderValue === 'function' ? renderValue(value) : value}
          </Typography>
        </div>
      )}
    </div>
  );
};

EditableField.propTypes = {
  isEditMode: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.string,
  renderValue: PropTypes.func,
  children: PropTypes.element
};

export default EditableField;
