import OneSignal from 'react-onesignal';

export default async function runOneSignal() {
  console.log('Init one signal');
  OneSignal.Debug.setLogLevel('6');
  await OneSignal.init({
    appId: '9b51063a-dd1a-4f43-a622-06dca11b8d07',
    allowLocalhostAsSecureOrigin: true,
    notifyButton: {
      enable: true
    }
  });
  OneSignal.Notifications.requestPermission(true);
  // OneSignal.setConsentGiven(true);
  OneSignal.Slidedown.promptPush();
}
