import React from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import PropTypes from 'prop-types';
import Page from 'src/components/Page';
import Auth from 'src/api/auth';
import AlertSnackbar from 'src/components/AlertSnackbar';
import alertSnackbarActions from 'src/store/actions/alertSnackbarActions';
import { connect } from 'react-redux';
import storage from 'src/storage';
import userActions from 'src/store/actions/userActions';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const LoginView = (props) => {
  const {
    setMessage, setSeverity, setOpen, updateUser
  } = props;
  const classes = useStyles();

  const submit = async ({ email, password }) => {
    setOpen(false);
    setMessage('');
    try {
      const res = await Auth.signIn({ email, password });
      storage.setUser(res.data);
      updateUser(storage.getUser());
      window.location.replace('/app/dashboard');
    } catch (error) {
      if (error.response) {
        // Request made and server responded
        console.log('Response Error', error.response.data);
        console.log('Response Error', error.response.status);
        console.log('Response Error', error.response.headers);
        setMessage('Invalid email or password');
      } else if (error.request) {
        // The request was made but no response was received
        console.log('RequestError', error.request);
        setMessage('Network Error');
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('General Error', error.message);
        setMessage('Network Error');
      }
      setSeverity('error');
      setOpen(true);
    }
  };

  return (
    <Page
      className={classes.root}
      title="Login"
    >
      <AlertSnackbar />
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              email: '',
              password: ''
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
              password: Yup.string().max(255).required('Password is required')
            })}
            onSubmit={submit}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Box mb={3}>
                  <Typography
                    color="textPrimary"
                    variant="h2"
                  >
                    Sign in
                  </Typography>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    Sign in on the admin platform
                  </Typography>
                </Box>

                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label="Email Address"
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Password"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />
                <Box my={2}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Sign in now
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
};

LoginView.propTypes = {
  setOpen: PropTypes.func,
  setMessage: PropTypes.func,
  setSeverity: PropTypes.func,
  updateUser: PropTypes.func,
};

const mapStateToProps = (state /* , ownProps */) => {
  return {
    alertSnackbar: state.alertSnackbar
  };
};

const { setOpen, setMessage, setSeverity } = alertSnackbarActions;
const { updateUser } = userActions;

const mapDispatchToProps = {
  setOpen, setMessage, setSeverity, updateUser
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginView);
