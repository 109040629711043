import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import storage from 'src/storage';
import { useNavigate } from 'react-router';
import OneSignal from 'react-onesignal';

const TOKEN_EXPIRED_ERROR = 'TokenExpiredError';

const IsLoggedOut = props => {
  const { component } = props;
  const Component = component;
  const navigate = useNavigate();

  useEffect(() => {
    const isAuthenticated = storage.getUser();
    console.log('isAuthenticated', isAuthenticated);
    if (!isAuthenticated) {
      navigate('/login', { replace: true });
    } else {
      OneSignal.login('admin');
      OneSignal.User.addTag('admin', 'true');
      OneSignal.User.addTag('email', isAuthenticated.email);
      OneSignal.Notifications.addEventListener('click', event => {
        console.log('The notification was clicked!', event);
      });
    }
  }, [navigate]);

  useEffect(() => {
    const interceptor = axios.interceptors.response.use(null, err => {
      if (
        err.response &&
        err.response.status === 401 &&
        err.response.data.message === TOKEN_EXPIRED_ERROR
      ) {
        storage.removeUser();
        navigate('/login', { replace: true });
      }
      return Promise.reject(err);
    });
    return () => {
      axios.interceptors.response.eject(interceptor);
    };
  }, []);

  return <Component />;
};

IsLoggedOut.propTypes = {
  component: PropTypes.elementType
};
export default IsLoggedOut;
