import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles, Typography, Box } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  title: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '1.25rem'
  },
  body: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: 16
  }
}));

export default function Confirm({ title, body, children }) {
  const classes = useStyles();
  const [state, setState] = useState({
    isOpen: false,
    callback: null,
    callbackArgs: []
  });
  const confirm = (callback, ...callbackArgs) => () => {
    setState(prev => ({ ...prev, isOpen: true, callback, callbackArgs }));
  };
  const hide = () => {
    setState({ isOpen: false, callback: null, callbackArgs: [] });
  };
  const onAgree = () => {
    state.callback(...state.callbackArgs);
    hide();
  };
  return (
    <>
      <Dialog
        open={state.isOpen}
        onClose={hide}
        aria-labelledby={title}
        aria-describedby={body}
      >
        <DialogTitle>
          <Typography className={classes.title} variant="h4">
            {title}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Box className={classes.body}>{body}</Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={hide} color="primary">
            Disagree
          </Button>
          <Button onClick={onAgree} color="primary" autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
      {typeof children === 'function' && children(confirm)}
    </>
  );
}

Confirm.propTypes = {
  title: PropTypes.string,
  body: PropTypes.string,
  children: PropTypes.element
};
