/* eslint-disable no-shadow */
import React, { useState, useEffect } from 'react';
import {
  CircularProgress,
  Container,
  Grid,
  Box,
  makeStyles
} from '@material-ui/core';
import toast from 'react-hot-toast';
import Page from 'src/components/Page';
import { useParams } from 'react-router';
import Pages from 'src/api/pages';
import User from 'src/api/user';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import Admin from 'src/api/admin';
import Profile from './Profile';
import ProfileDetails from './ProfileDetails';
import BankDetails from './BankDetails';
import DocumentDetails from './DocumentDetails';
import VehicleDetails from './VehicleDetails';
import MoreDetails from './MoreDetails';
import DriverLicense from './DriverLicense';
// import User from 'src/api/user';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  }
}));

const CustomerView = () => {
  const { id } = useParams();
  const classes = useStyles();
  const [pageName, setPageName] = useState('Customer');
  const [customer, setCustomer] = useState({});
  const [summary, setCustomerSummary] = useState({});
  const [vehicle, setVehicle] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [profileFormdata, setProfileFormData] = useState({});
  const [isUpdating, setIsUpdating] = useState(false);
  const [driverVerifyFlag, setVerifyDriverFlag] = useState(
    customer.verifiedDriver
  );
  const [isLicenseNumberVerified, setIsLicenseNumberVerified] = useState(
    customer.isDriversLicenseVerified
  );
  const [verificationMessage, setVerificationMessage] = useState(
    customer.driverLicenseVerificationErrorMessage
  );
  const [openUnVerifyDialog, setOpenUnVerifyDialog] = useState(false);
  const [openCarUnverifyDialog, setOpenCarUnVerifyDialog] = useState(false);
  const [isPlateNumberVerified, setIsPlateNumberVerified] = useState(
    vehicle?.isVerified
  );

  // console.log('is verified driver>>', driverVerifyFlag);
  // console.log('isverified driver>>', isLicenseNumberVerified);
  // console.log('customer>>', customer);

  const loadUser = async () => {
    try {
      setIsLoading(true);
      const data = await Pages.getCustomerPage(id);
      const vehicleIndex = data.vehicle.length - 1;
      setPageName(`${data.user.firstName} profile details`);
      setCustomer(data.user);
      setVerifyDriverFlag(data.user.verifiedDriver);
      setIsLicenseNumberVerified(data.user.isDriversLicenseVerified);

      // console.log('vehicle info>>>', data);
      // TODO: abstract feature in a hook
      setProfileFormData(data.user);
      setCustomerSummary(data.summary);
      setVehicle(data.vehicle[vehicleIndex]);
      setIsPlateNumberVerified(data.vehicle[vehicleIndex].isVerified);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadUser();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  // console.log('customer>>>', customer);
  // console.log('verify driver flag>>>', driverVerifyFlag);

  const handleVerification = async data => {
    try {
      const res = await User.verifyUser(id, data);
      setCustomer(prev => ({ ...prev, ...res.data }));
      setVerifyDriverFlag(res.data.verifiedDriver);
      // console.log('response>>', res);
      toast.success(`Updated successfully!`);
    } catch (err) {
      console.log(err.response);
    }
  };

  // driver lincense unverification
  const handleUnverifyDriverVerification = async (userId, payload) => {
    try {
      const res = await await User.verifyUser(userId, payload);
      if (res) {
        console.log('unverify res>>>', res);
        setVerifyDriverFlag(res.data.verifiedDriver);
        setIsLicenseNumberVerified(res.data.isDriversLicenseVerified);
        setVerificationMessage(res.data.driverLicenseVerificationErrorMessage);
        toast.success('Driver License Verification Unverified!');
        setOpenUnVerifyDialog(prev => !prev);
      }
    } catch (e) {
      toast.error(e.message);
      setOpenUnVerifyDialog(prev => !prev);
    }
  };

  // car unverification
  const handleUnverifyCarVerification = async (userId, payload) => {
    try {
      const res = await Admin.verifyVehicle(userId, payload);
      if (res) {
        setVehicle(res.data.data);
        setVerifyDriverFlag(false);
        console.log('unverify res>>>', res);
        toast.success('Car Verification Unverified!');
        setIsPlateNumberVerified(false);
        setOpenCarUnVerifyDialog(prev => !prev);
      }
    } catch (e) {
      toast.error(e.message);
      setOpenCarUnVerifyDialog(prev => !prev);
    }
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setProfileFormData(prev => ({ ...prev, [name]: value }));
  };

  const updateProfileDetails = async () => {
    const changedFields = Object.entries(profileFormdata).reduce(
      (fields, curKeyValuePair) => {
        const [key, value] = curKeyValuePair;
        if (value !== customer[key]) fields[key] = value;
        return fields;
      },
      {}
    );

    // there are no changed fields, no point sending request
    if (!Object.keys(changedFields).length) return;

    try {
      setIsUpdating(true);
      const { data } = await User.updateUser(id, changedFields);
      setCustomer(data);
      setIsUpdating(false);
    } catch (error) {
      setIsUpdating(false);
      console.log(error);
    }
  };

  if (isLoading) {
    return (
      <Page className={classes.root} title={pageName}>
        <Box textAlign="center" py={10}>
          <CircularProgress />
        </Box>
      </Page>
    );
  }

  return (
    <Page className={classes.root} title={pageName}>
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item lg={4} md={6} xs={12}>
            <Profile
              customer={customer}
              formData={profileFormdata}
              summary={summary}
              driverVerifyFlag={driverVerifyFlag}
              handleVerification={handleVerification}
            />
            <MoreDetails customer={customer} />
          </Grid>
          <Grid item lg={8} md={6} xs={12}>
            <Box sx={{ marginBottom: '2rem' }}>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.heading}>Profile</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <ProfileDetails
                    formData={profileFormdata}
                    customer={customer}
                    onChange={handleChange}
                    onSave={updateProfileDetails}
                    isUpdating={isUpdating}
                    handleVerification={handleVerification}
                  />
                </AccordionDetails>
              </Accordion>
            </Box>
            <Box sx={{ marginY: '2rem' }}>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.heading}>Bank</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <BankDetails customer={customer} />
                </AccordionDetails>
              </Accordion>
            </Box>
            <Box sx={{ marginY: '2rem' }}>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.heading}>
                    Government Data Information
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <DocumentDetails
                    handleVerification={handleVerification}
                    customer={customer}
                  />
                </AccordionDetails>
              </Accordion>
            </Box>

            <Box sx={{ marginY: '2rem' }}>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.heading}>
                    Driver License Information
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <DriverLicense
                    handleVerification={handleVerification}
                    isLicenseNumberVerified={isLicenseNumberVerified}
                    verificationMessage={verificationMessage}
                    handleUnverifyDriverVerification={
                      handleUnverifyDriverVerification
                    }
                    setIsLicenseNumberVerified={setIsLicenseNumberVerified}
                    setVerificationMessage={setVerificationMessage}
                    customer={customer}
                    openUnVerifyDialog={openUnVerifyDialog}
                    setOpenUnVerifyDialog={setOpenUnVerifyDialog}
                  />
                </AccordionDetails>
              </Accordion>
            </Box>

            <Box sx={{ marginY: '2rem' }}>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.heading}>
                    Vehicle Information
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <VehicleDetails
                    vehicle={vehicle}
                    customer={customer}
                    openCarUnverifyDialog={openCarUnverifyDialog}
                    isPlateNumberVerified={isPlateNumberVerified}
                    setOpenCarUnVerifyDialog={setOpenCarUnVerifyDialog}
                    handleUnverifyCarVerification={
                      handleUnverifyCarVerification
                    }
                    setIsPlateNumberVerified={setIsPlateNumberVerified}
                    setVehicle={setVehicle}
                  />
                </AccordionDetails>
              </Accordion>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default CustomerView;
