import React from 'react';
import Rating from '@material-ui/lab/Rating';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';

const StyledRating = withStyles({
  iconFilled: {
    color: '#1b5e20'
  }

  // iconHover: {
  //   color: '#ff3d47'
  // }
})(Rating);

export default function Ratings({ ratingValue, precision, readOnly }) {
  // const [value, setValue] = React.useState(2);

  return (
    <div>
      <Box component="fieldset" mb={3} borderColor="transparent">
        <StyledRating
          name="half-rating-read"
          defaultValue={ratingValue}
          precision={precision ? 0.5 : 1} // Assuming you want 1 if precision is false
          readOnly={readOnly}
        />
      </Box>
    </div>
  );
}

Ratings.propTypes = {
  ratingValue: PropTypes.number.isRequired,
  precision: PropTypes.bool,
  readOnly: PropTypes.bool
};
