import React, { useEffect, useState } from 'react';
import { Box, Container, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import qs from 'qs';
import { useLocation } from 'react-router';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import alertSnackbarActions from 'src/store/actions/alertSnackbarActions';
import axiosActions from 'src/store/actions/axiosActions';
import Toolbar from './Toolbar';
import Results from './Results';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const CustomerListView = ({ axiosGetUsers, userList }) => {
  // console.log("userList", userList);
  const location = useLocation();
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const { search } = location;
    const queries = qs.parse(search, { ignoreQueryPrefix: true });
    axiosGetUsers(queries);
  }, [location, axiosGetUsers]);

  const { users, pagination } = userList;

  useEffect(() => {
    if (users) {
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }
  }, []);

  return (
    <Page className={classes.root} title="Customers">
      <Container maxWidth={false}>
        <Box mb={4}>
            <Toolbar />
        </Box>
        <Box mt={3}>
          <Results
            customers={users}
            pagination={pagination}
            isLoading={isLoading}
          />
        </Box>
      </Container>
    </Page>
  );
};

CustomerListView.propTypes = {
  axiosGetUsers: PropTypes.func,
  userList: PropTypes.object
};

const mapStateToProps = (state /* , ownProps */) => {
  return {
    alertSnackbar: state.alertSnackbar,
    userList: state.userList
  };
};

const { setOpen, setMessage, setSeverity } = alertSnackbarActions;
const { axiosGetUsers } = axiosActions;

const mapDispatchToProps = {
  setOpen,
  setMessage,
  setSeverity,
  axiosGetUsers
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerListView);
