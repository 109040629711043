import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  makeStyles,
  Container,
  Box,
  Table,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
  Card
} from '@material-ui/core';
import { useQuery } from 'react-query';
import PerfectScrollbar from 'react-perfect-scrollbar';
import config from 'src/config';
import storage from 'src/storage';
import TripRow from 'src/components/TripRow';
import Page from 'src/components/Page';
import TablePagination from 'src/components/TablePagination';
import useQueryString from 'src/hooks/useQueryString';
import api from 'src/services/httpService';
// import Filter from './Filter';

const useStyles = makeStyles(() => ({
  root: {},
  nowrap: {
    whiteSpace: 'nowrap'
  }
}));

const fetchTrips = async (id, query) => {
  const axiosConfig = {
    headers: {
      Authorization: `Bearer ${storage.getToken()}`
    },
    params: query
  };
  const { data } = await api.get(
    `${config.rootUrl}/admin/user/trip/${id}`,
    axiosConfig
  );
  return data;
};

export default function CustomerTrips() {
  const { id } = useParams();
  const classes = useStyles();
  const navigate = useNavigate();

  const [query, setQueryString] = useQueryString();

  const { data = {} } = useQuery(
    ['trips', query],
    () => fetchTrips(id, query),
    {
      keepPreviousData: true
    }
  );

  const { data: trips = [] } = data;
  return (
    <Page className={classes.root} title="Trips">
      <Container maxWidth={false}>
        {/* <Box mb={4}>
          <Filter defaultValues={query} />
        </Box> */}
        <Card>
          <PerfectScrollbar>
            <Box minWidth={1050}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Driver</TableCell>
                    <TableCell>Available Seats</TableCell>
                    <TableCell>Distance (Km)</TableCell>
                    <TableCell>Total Seats</TableCell>
                    <TableCell>Estimated Duration (Min)</TableCell>
                    <TableCell>Departure Date</TableCell>
                    <TableCell>Departure Time</TableCell>
                    <TableCell>Fare</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Verified</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {trips.map(trip => (
                    <TripRow
                      key={trip.id}
                      trip={trip}
                      hover
                      onClick={() => navigate(`/app/trips/${trip.id}`)}
                      className={classes.tableRow}
                      style={{ cursor: 'pointer' }}
                    />
                  ))}
                </TableBody>
              </Table>
            </Box>
          </PerfectScrollbar>
          {data.pagination && (
            <TablePagination
              count={data.pagination.totalCount}
              page={data.pagination.currentPage}
              rowsPerPage={data.pagination.rows}
              onChangeRowsPerPage={rows => setQueryString({ rows })}
              onChangePage={pageNo => setQueryString({ pageNo })}
            />
          )}
        </Card>
      </Container>
    </Page>
  );
}
