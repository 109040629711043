import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Avatar,
  Box,
  // Button,
  Card,
  CardContent,
  // Divider,
  Typography,
  makeStyles
} from '@material-ui/core';
// import { AlertCircle, CheckCircle } from 'react-feather';
// import { green, red } from '@material-ui/core/colors';
// import CustomDialog from 'src/components/CustomDialog';
// import storage from 'src/storage';

const useStyles = makeStyles(() => ({
  root: {},
  avatar: {
    height: 100,
    width: 100
  }
}));

const Profile = ({ className, admin, ...rest }) => {
  const classes = useStyles();
  let adminRole = '';

  if (admin.role) {
    if (admin.role === 'super') {
      adminRole = 'Super Admin';
    } else {
      adminRole = 'Admin';
    }
  }

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardContent>
        <Box alignItems="center" display="flex" flexDirection="column">
          <Avatar className={classes.avatar} src="/broken-image.jpg" />
          <Typography color="textPrimary" gutterBottom variant="h3">
            {`${admin.firstName || ''} ${admin.lastName || ''}`}
          </Typography>
          <Typography
            color="textSecondary"
            variant="body1"
            style={{
              display: 'flex',
              alignItems: 'center'
            }}
          >
            {adminRole}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

Profile.propTypes = {
  className: PropTypes.string,
  admin: PropTypes.object,
  summary: PropTypes.object
};

export default Profile;
