import React, { useEffect, useState } from 'react';
import { Container, Grid, makeStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import storage from 'src/storage';
import Page from 'src/components/Page';
import config from 'src/config';
import dashboardInfoActions from 'src/store/actions/dashboardInfoActions';
import ActivityLog from 'src/views/ActivityLog/index';
import Chart from 'src/components/chart';
import api from 'src/services/httpService';
import RideStats from './RideStats';
import LatestTrips from './LatestTrips';
import TasksProgress from './TasksProgress';
import TotalCustomers from './TotalCustomers';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  chartAndActivity: {
    marginTop: theme.spacing(2)
  }
}));

const fetchDashboardSummary = async () => {
  const axiosConfig = {
    headers: {
      Authorization: `Bearer ${storage.getToken()}`
    }
  };
  const { data } = await api.get(
    `${config.rootUrl}/pages/admin-view/dashboard`,
    axiosConfig
  );
  console.log(data);
  return data;
};

const Dashboard = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { setDashboardInfo } = dashboardInfoActions;
  const dashboardInfo = useSelector(state => state.adminList.dashboardInfo);
  const [isLoading, setIsLoading] = useState(true);

  const {
    totalUserCount,
    totalBookingsCount,
    totalTripCount,
    recentFiveTrips = []
  } = dashboardInfo;

  useEffect(() => {
    if (!dashboardInfo.totalTripCount) {
      fetchDashboardSummary().then(res => {
        dispatch(setDashboardInfo({ payload: res.summary }));
        setIsLoading(false);
      });
    }
  }, []);

  return (
    <Page className={classes.root} title="Dashboard">
      <Container maxWidth={false}>
        <Grid container spacing={3}>
          <Grid item xl={4} lg={4} sm={6} xs={12}>
            <RideStats totalTripCount={totalTripCount} isLoading={isLoading} />
          </Grid>
          {/* <Grid item lg={3} sm={6} xl={3} xs={12}>
            <Revenue totalTripCount={totalTripCount} />
          </Grid> */}
          <Grid item xl={4} lg={4} sm={6} xs={12}>
            <TasksProgress
              totalBookingsCount={totalBookingsCount}
              isLoading={isLoading}
            />
          </Grid>

          <Grid item xl={4} lg={4} sm={6} xs={12}>
            <TotalCustomers
              totalUserCount={totalUserCount}
              isLoading={isLoading}
            />
          </Grid>
        </Grid>
      </Container>
      <Container maxWidth={false} className={classes.chartAndActivity}>
        <Grid container spacing={3}>
          <Grid item lg={8} sm={12} xl={8} xs={12}>
            <ActivityLog showActivitySearch />
          </Grid>
          <Grid item lg={4} sm={12} xl={4} xs={12}>
            <Chart
              totalTripCount={totalTripCount}
              totalBookingsCount={totalBookingsCount}
              totalUserCount={totalUserCount}
              isLoading={isLoading}
            />
          </Grid>
          <Grid item lg={12} md={12} xl={12} xs={12}>
            <LatestTrips
              recentFiveTrips={recentFiveTrips}
              isLoading={isLoading}
            />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default Dashboard;
