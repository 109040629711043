import config from 'src/config';
import api from 'src/services/httpService';
import storage from 'src/storage';

const Customer = {
  axiosConfig: {
    headers: {
      Authorization: `Bearer ${storage.getToken()}`
    }
  },

  getCustomerWallet: id => {
    // console.log(axiosConfig);
    return api
      .get(`${config.rootUrl}/admin/wallet/${id}/get`, Customer.axiosConfig)
      .then(({ data }) => data);
  }
};

export default Customer;
