import { appSettingsActionList } from '../action-lists';

const appState = {
  pageLoading: false,
};

export default function adminListReducer(state = appState, action) {
  switch (action.type) {
    case appSettingsActionList.setPageLoading: {
      return {
        ...state,
        pageLoading: action.payload
      };
    }

    default: return state;
  }
}
