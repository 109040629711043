import { useNavigate, useLocation } from 'react-router-dom';
import qs from 'query-string';

const useQueryString = () => {
  const location = useLocation();
  const queries = qs.parse(location.search);
  const navigate = useNavigate();

  const updateQueryString = newQueries => {
    navigate(
      `?${qs.stringify(
        { ...queries, ...newQueries },
        { skipNull: true, skipEmptyString: true }
      )}`
    );
  };
  return [queries, updateQueryString];
};

export default useQueryString;
