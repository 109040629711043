import { userActionList } from '../action-lists';

const updateUser = (data) => {
  return { type: userActionList.updateUser, payload: data };
};

const updateUsers = (data) => {
  return { type: userActionList.updateUsers, payload: data };
};

export default {
  updateUser,
  updateUsers,
};
