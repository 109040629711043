import React, { useEffect, useState } from 'react';
import { Container, Grid, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import { useParams } from 'react-router';
import toast from 'react-hot-toast';
import Admin from 'src/api/admin';
import Profile from './Profile';
import ProfileDetails from './ProfileDetails';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const AdminView = () => {
  const { id } = useParams();
  const classes = useStyles();
  const [pageName, setPageName] = useState('Admin');
  const [admin, setAdmin] = useState({});
  const [profileFormdata, setProfileFormData] = useState({});
  const [isUpdating, setIsUpdating] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  console.log('isLoading>>>', isLoading);
  const getUser = async userId => {
    try {
      const res = await Admin.getAdmin(userId);
      setProfileFormData(res.data);
      setAdmin(res.data);
      setPageName(`${res.data.firstName} ${res.data.lastName}`);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getUser(id);
  }, [id]);

  const handleChange = e => {
    const { name, value } = e.target;
    setProfileFormData(prev => ({ ...prev, [name]: value }));
  };

  const updateProfileDetails = async () => {
    const changedFields = Object.entries(profileFormdata).reduce(
      (fields, curKeyValuePair) => {
        const [key, value] = curKeyValuePair;
        if (value !== admin[key]) fields[key] = value;
        return fields;
      },
      {}
    );

    // there are no changed fields, no point sending request
    if (!Object.keys(changedFields).length) return;

    try {
      setIsUpdating(true);
      const data = await Admin.superAdminEditAdmin(admin.id, changedFields);
      setAdmin(data[0]);
      setProfileFormData(data[0]);
      setIsUpdating(false);
    } catch (error) {
      setIsUpdating(false);
      console.log(error);
    }
  };

  const activateAdmin = async active => {
    try {
      const data = await Admin.superAdminEditAdmin(admin.id, { active });
      setAdmin(data[0]);
      setProfileFormData(data[0]);
      toast.success('Status Updated');
    } catch (err) {
      console.log(err.response);
    }
  };
  const sendResetPassword = async () => {
    try {
      await Admin.superAdminResetAdminPassword({ email: admin.email });
      toast.success('Reset password sent');
    } catch (err) {
      console.log(err.response);
    }
  };

  return (
    <Page className={classes.root} title={pageName}>
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item lg={4} md={6} xs={12}>
            <Profile
              admin={profileFormdata}
              activateAdmin={activateAdmin}
              sendResetPassword={sendResetPassword}
              isLoading={isLoading}
            />
          </Grid>
          <Grid item lg={8} md={6} xs={12}>
            <ProfileDetails
              formData={profileFormdata}
              admin={profileFormdata}
              onChange={handleChange}
              onSave={updateProfileDetails}
              isUpdating={isUpdating}
              isLoading={isLoading}
            />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default AdminView;
