import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { TextField } from '@material-ui/core';

export default function DateInput({
  value,
  onChange,
  format = 'DD/MM/YYYY',
  ...props
}) {
  const $onChange = e => {
    onChange(moment(e.target.value, 'YYYY-MM-DD').format(format));
  };

  const [date, setDate] = useState(value);
  useEffect(() => {
    if (value) {
      setDate(value);
    }
  }, [value]);

  const nativeValue = date ? moment(value, format).format('YYYY-MM-DD') : '';

  return (
    <TextField
      type="date"
      InputLabelProps={{
        shrink: true
      }}
      value={nativeValue}
      onChange={$onChange}
      {...props}
    />
  );
}

DateInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  format: PropTypes.string
};
