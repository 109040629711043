import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  makeStyles
} from '@material-ui/core';
import EditableField from './EditableField';

const useStyles = makeStyles(() => ({
  root: {
    marginBottom: 20,
    width: '100%'
  }
}));

const BankDetails = ({ className, customer, ...rest }) => {
  const classes = useStyles();

  const handleChange = () => {};

  return (
    <form
      autoComplete="off"
      noValidate
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Card>
        <CardHeader title="Bank Details" />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <EditableField
                fullWidth
                helperText="Please specify the first name"
                label="Bank Name"
                name="bankName"
                onChange={handleChange}
                value={customer.bankName || 'Not set'}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <EditableField
                fullWidth
                label="Bank Code"
                name="bankCode"
                onChange={handleChange}
                value={customer.bankCode || 'Not set'}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <EditableField
                fullWidth
                label="Account name"
                name="accountName"
                onChange={handleChange}
                value={customer.accountName || 'Not set'}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <EditableField
                fullWidth
                label="Account Number"
                name="accountNumber"
                onChange={handleChange}
                value={customer.accountNumber || 'Not set'}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
      </Card>
    </form>
  );
};

BankDetails.propTypes = {
  className: PropTypes.string,
  customer: PropTypes.object
};

export default BankDetails;
