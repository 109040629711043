import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

export default function CircularLoader() {
  return (
    <div>
      <CircularProgress size="1rem" />
    </div>
  );
}
