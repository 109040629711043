import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Card,
  CardContent,
  TextField,
  // InputAdornment,
  // SvgIcon,
  makeStyles,
  // IconButton,
  MenuItem,
  useMediaQuery
} from '@material-ui/core';
import qs from 'qs';
// import { Search as SearchIcon } from 'react-feather';
import { useLocation, useNavigate } from 'react-router';
// import moment from 'moment';
import SearchField from 'src/components/SearchField';

const useStyles = makeStyles(theme => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  searchBox: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'end'
  },

  searchInput: {
    flex: 1,
    marginRight: 10,
    width: '100%'
  },
  searchButton: {
    width: 50,
    height: 50
  },

  toolBox: {
    display: 'flex',
    justifyContent: 'space-around',
    gap: '5px'
  },

  mobileToolBox: {
    display: 'flex',
    justifyContent: 'space-around',
    gap: '15px',
    flexDirection: 'column'
  },

  dateBox: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },

  mobileDateBox: {
    width: '100%',
    display: 'flex',
    alignItems: 'center'
  },

  mobileFormBox: {
    width: '100%',
    display: 'flex',
    gap: '1rem',
    alignItems: 'center'
  },

  mobileSearchBox: {
    display: 'flex',
    alignItems: 'center',
    width: '15rem',
    justifyContent: 'start'
  },

  formBox: {
    width: '100%',
    display: 'flex',
    gap: '1rem',
    alignItems: 'center'
  },

  textField: {
    width: '100%'
  }
}));
const CustomerReportsToolBar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const classes = useStyles();
  const [searchWord, setSearchWord] = useState('');
  const [role, setRole] = React.useState('');
  const isMobileView = useMediaQuery('(max-width:768px)');
  const [queries, setQueries] = useState({});
  // console.log('filter date', formattedDate);

  const handleSearch = e => {
    e.preventDefault();
    const { pathname } = location;
    navigate(`${pathname}?${qs.stringify({ searchWord })}`);
  };

  const handleFilterByRole = event => {
    const selectedRole = event.target.value;
    setRole(selectedRole);
    const { pathname } = location;
    const newQueries = { ...queries, reportedBy: selectedRole };
    navigate(`${pathname}?${qs.stringify(newQueries)}`);
    console.log('selected role>>', selectedRole);
  };

  useEffect(() => {
    const { search } = location;
    const queryy = qs.parse(search, { ignoreQueryPrefix: true });
    if (queryy.searchWord || queryy.reportedBy) {
      setSearchWord(queryy.searchWord);
      setQueries(qs.parse(search, { ignoreQueryPrefix: true }));
    }
  }, [location, role]);

  return (
    <div className={clsx(classes.root)}>
      <Box mt={3}>
        <Card>
          <CardContent
            className={`${
              isMobileView ? classes.mobileToolBox : classes.toolBox
            }`}
          >
            <Box
              className={isMobileView ? classes.mobileDateBox : classes.dateBox}
            >
              <TextField
                className={classes.textField}
                variant="outlined"
                label="Filter By Role"
                fullWidth
                size="small"
                select
                name="selectRole"
                value={role}
                onChange={handleFilterByRole}
              >
                {' '}
                {['All', 'driver', 'rider'].map(status => (
                  <MenuItem value={status === 'All' ? '' : status} key={status}>
                    {status}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
            {/* <Box className={classes.searchBox} maxWidth={500}>
              <TextField
                className={classes.searchInput}
                value={searchWord}
                onChange={event => {
                  setSearchWord(event.target.value);
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon fontSize="small" color="action">
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  )
                }}
                placeholder="Search customer"
                variant="outlined"
              />
              <IconButton
                className={classes.searchButton}
                aria-label="search"
                onClick={handleSearch}
              >
                <SearchIcon />
              </IconButton>
            </Box> */}
            <Box
              className={`${
                isMobileView ? classes.mobileSearchBox : classes.searchBox
              }
        `}
            >
              <SearchField
                searchValue={searchWord}
                searchHandler={handleSearch}
                searchPlaceHolder="Search Reports"
                setSearch={setSearchWord}
                classes={classes}
              />
            </Box>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
};

export default CustomerReportsToolBar;
