import React from 'react';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles
} from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import TripRow from 'src/components/TripRow';
import TableLoader from 'src/components/TableLoader';

// import trips from './trips';

const useStyles = makeStyles(() => ({
  root: {},
  actions: {
    justifyContent: 'flex-end'
  }
}));

const LatestTrips = ({ className, recentFiveTrips, isLoading, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardHeader title="Latest Trips" />
      <Divider />
      {isLoading ? (
        <TableLoader />
      ) : (
        <>
          <PerfectScrollbar>
            <Box minWidth={800}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Driver</TableCell>
                    <TableCell>Available Seats</TableCell>
                    <TableCell>Distance</TableCell>
                    <TableCell>Total Seats</TableCell>
                    <TableCell>Estimated Duration</TableCell>
                    <TableCell>Departure Date</TableCell>
                    <TableCell>Departure Time</TableCell>
                    <TableCell>Fare</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Verified</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {recentFiveTrips.map(trip => (
                    <TripRow
                      key={trip.id}
                      hover
                      onClick={() => navigate(`/app/trips/${trip.id}`)}
                      trip={trip}
                    />
                  ))}
                </TableBody>
              </Table>
            </Box>
          </PerfectScrollbar>
          <Box display="flex" justifyContent="flex-end" p={2}>
            <Button
              color="primary"
              endIcon={<ArrowRightIcon />}
              size="small"
              variant="text"
              onClick={() => navigate('/app/trips')}
            >
              View all
            </Button>
          </Box>
        </>
      )}
    </Card>
  );
};

LatestTrips.propTypes = {
  className: PropTypes.string,
  recentFiveTrips: PropTypes.array,
  isLoading: PropTypes
};

export default LatestTrips;
