import React, { useEffect, useState } from 'react';
import { Container, makeStyles, Box } from '@material-ui/core';
import Page from 'src/components/Page';
import Admin from 'src/api/admin';
import useQueryString from 'src/hooks/useQueryString';
import { useQuery } from 'react-query';
import CustomerReportsToolBar from './CustomerReportsToolBar';
import Results from './Results';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

export default function CustomerReportsView() {
  const classes = useStyles();
  // const [reports, setReports] = useState([]);
  // const [pagination, setPagination] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [query, setQueryString] = useQueryString();
  const [customersReport, setCustomersReport] = useState([]);
  const [paginationData, setPaginationData] = useState({});

  const { data = {} } = useQuery(
    ['reports', query],
    () => Admin.getCustomersReports(query),
    {
      keepPreviousData: true
    }
  );

  const { data: dataReports = [] } = data;

  // console.log('react-query newReports>>', dataReports);

  const handleDatas = () => {
    setCustomersReport(dataReports.ridersReports);
    setPaginationData(dataReports.pagination);
  };

  useEffect(() => {
    setTimeout(() => {
      if (dataReports) {
        handleDatas();
        setIsLoading(false);
      }
    }, 1000);
  }, [dataReports]);

  return (
    <Page className={classes.root} title="Reports">
      <Container maxWidth={false}>
        <CustomerReportsToolBar />
        <Box mt={3}>
          <Results
            pagination={paginationData}
            reports={customersReport}
            isLoading={isLoading}
            setQueryString={setQueryString}
            query={query}
          />
        </Box>
      </Container>
    </Page>
  );
}
