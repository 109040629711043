import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import {
  Avatar,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles
} from '@material-ui/core';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import WarningIcon from '@material-ui/icons/Warning';
import { green, red } from '@material-ui/core/colors';
import getInitials from 'src/utils/getInitials';
import qs from 'qs';
import { useLocation, useNavigate } from 'react-router';
import TableLoader from 'src/components/TableLoader';

const useStyles = makeStyles(theme => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  },
  tableRow: {
    cursor: 'pointer'
  },
  sortIcons: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    overflow: 'hidden',
    width: 'fit-content',
    margin: 0,
    fontWeight: 500
  },
  tableHeadStyling: {
    display: 'flex',
    flexDirection: 'row',
    overflow: 'hidden',
    backgroundColor: 'transparent',
    border: 'none'
  },
  verifiedSortedDriver: {
    display: 'flex',
    justifyContent: 'right'
  },
  tablecell: {
    borderBottom: '1px solid lightgrey'
  }
}));

const Results = ({ className, customers, pagination, isLoading, ...rest }) => {
  const [sortedCustomers, setSortedCustomers] = useState(customers);
  const [paginationData, setPaginationData] = useState(pagination);

  console.log('pagination data>>', paginationData);
  const [switchSort, setSwitchSort] = useState('sorted');
  useEffect(() => {
    setSortedCustomers(customers);
    if (switchSort === 'sorted') {
      setSortedCustomers(
        customers?.sort((a, b) => (a.firstName < b.firstName ? 1 : -1))
      );
    } else if (switchSort === 'unsorted') {
      setSortedCustomers(
        customers?.sort((a, b) => (a.firstName < b.firstName ? -1 : 1))
      );
    } else if (switchSort === 'sortedDrivers') {
      setSortedCustomers(
        customers?.sort((a, b) =>
          a.verifiedDriver > b.verifiedDriver ? -1 : 1
        )
      );
    } else if (switchSort === 'unsortedDrivers') {
      setSortedCustomers(
        customers?.sort((a, b) =>
          a.verifiedDriver > b.verifiedDriver ? 1 : -1
        )
      );
    } else if (switchSort === 'sortedVerifiedRiders') {
      setSortedCustomers(
        customers?.sort((a, b) => (a.verifiedRider > b.verifiedRider ? -1 : 1))
      );
    } else if (switchSort === 'unsortedVerifiedRiders') {
      setSortedCustomers(
        customers?.sort((a, b) => (a.verifiedRider > b.verifiedRider ? 1 : -1))
      );
    }
  }, [switchSort, customers]);

  const location = useLocation();
  const navigate = useNavigate();

  const classes = useStyles();

  const [queries, setQueries] = useState({});

  useEffect(() => {
    const { search } = location;
    setQueries(qs.parse(search, { ignoreQueryPrefix: true }));
  }, [location]);

  const handleLimitChange = event => {
    const { pathname } = location;
    const newQueries = { ...queries, rows: event.target.value };
    navigate(`${pathname}?${qs.stringify(newQueries)}`);
  };

  const handlePageChange = (event, newPage) => {
    const { pathname } = location;
    const newQueries = { ...queries, pageNo: newPage + 1 };
    navigate(`${pathname}?${qs.stringify(newQueries)}`);
  };

  const handleRowClick = (event, id) => {
    navigate(`/app/customers/${id}`);
  };

  useEffect(() => {
    if (customers && pagination) {
      setSortedCustomers(customers);
      setPaginationData(pagination);
    }
  }, [customers, pagination]);

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          {isLoading ? (
            <TableLoader />
          ) : (
            <Table>
              <TableHead>
                <TableRow className={classes.tablecell}>
                  <TableCell
                    onClick={() => {
                      setSwitchSort(
                        switchSort === 'sorted' ? 'unsorted' : 'sorted'
                      );
                    }}
                  >
                    <Typography className={classes.sortIcons}>
                      Name
                      {switchSort === 'sorted' ? (
                        <ArrowUpward />
                      ) : (
                        <ArrowDownward />
                      )}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography className={classes.sortIcons}>
                      Driver Email
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography className={classes.sortIcons}>
                      Status
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography className={classes.sortIcons}>Phone</Typography>
                  </TableCell>
                  <TableCell
                    onClick={() => {
                      setSwitchSort(
                        switchSort === 'sortedDrivers'
                          ? 'unsortedDrivers'
                          : 'sortedDrivers'
                      );
                    }}
                  >
                    <Typography className={classes.sortIcons}>
                      Driver Status
                      {switchSort === 'sortedDrivers' ? (
                        <ArrowUpward />
                      ) : (
                        <ArrowDownward />
                      )}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedCustomers &&
                  sortedCustomers.map(customer => (
                    <TableRow
                      hover
                      key={customer.id}
                      onClick={event => {
                        handleRowClick(event, customer.id);
                      }}
                      className={classes.tableRow}
                    >
                      <TableCell>
                        <Box alignItems="center" display="flex">
                          <Avatar
                            className={classes.avatar}
                            src={customer.avatarUrl}
                          >
                            {getInitials(
                              `${customer.firstName}  ${customer.lastName}`
                            )}
                          </Avatar>
                          <Typography color="textPrimary" variant="body1">
                            {`${customer.firstName}  ${customer.lastName}`}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell>{customer.email}</TableCell>
                      <TableCell>
                        {customer.active ? 'Active' : 'Unactive'}
                      </TableCell>
                      <TableCell>
                        {customer.mobile &&
                          `${customer.mobileCode}${customer.mobile}`}
                      </TableCell>
                      <TableCell align="center">
                        {customer.verifiedDriver ? (
                          <VerifiedUserIcon style={{ color: green[500] }} />
                        ) : (
                          <WarningIcon style={{ color: red[500] }} />
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          )}
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={paginationData.totalCount}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleLimitChange}
        page={paginationData.currentPage}
        rowsPerPage={paginationData.rows}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  customers: PropTypes.array,
  pagination: PropTypes.object,
  isLoading: PropTypes.bool
};

export default Results;
