import { adminActionList } from '../action-lists';

const adminListState = {
  users: [],
  pagination: {
    totalCount: 0,
    rowsPerPage: 10,
    page: 0
  },
  dashboardInfo: {}
};

export default function adminListReducer(state = adminListState, action) {
  switch (action.type) {
    case adminActionList.updateAdmins: {
      const { data, pagination } = action.payload;

      return {
        ...state,
        users: data,
        pagination: {
          totalCount: pagination.totalCount,
          rowsPerPage: pagination.rows,
          page: pagination.currentPage - 1
        },
      };
    }
    case adminActionList.dashboardInfo: {
      const { payload } = action.payload;

      return {
        ...state,
        dashboardInfo: payload
      };
    }

    default:
      return state;
  }
}
