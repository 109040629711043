import React from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Button,
  Collapse,
  List,
  ListItem,
  makeStyles
} from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  button: {
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightMedium,
    justifyContent: 'flex-start',
    letterSpacing: 0,
    padding: '10px 8px',
    textTransform: 'none',
    width: '100%'
  },
  icon: {
    marginRight: theme.spacing(1)
  },
  title: {
    marginRight: 'auto'
  },
  active: {
    color: theme.palette.primary.main,
    '& $title': {
      fontWeight: theme.typography.fontWeightMedium
    },
    '& $icon': {
      color: theme.palette.primary.main
    }
  }
}));

const NavItem = ({
  className,
  href,
  icon: Icon,
  title,
  subMenuItems,
  ...rest
}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  let listItem;

  if (subMenuItems) {
    const subMenus = subMenuItems.map((subMenuItem) => (
      <ListItem key={subMenuItem.href} className={classes.nested}>
        <Button
          activeClassName={classes.active}
          className={classes.button}
          component={RouterLink}
          to={subMenuItem.href}
        >
          {subMenuItem.icon && (
          <subMenuItem.icon
            className={classes.icon}
            size="16"
          />
          )}
          <span className={classes.title}>
            {subMenuItem.title}
          </span>
        </Button>
      </ListItem>
    ));

    listItem = (
      <div>
        <ListItem
          className={clsx(classes.item, className)}
          disableGutters
          onClick={handleClick}
          {...rest}
        >
          <Button
            className={classes.button}
          >
            {Icon && (
            <Icon
              className={classes.icon}
              size="20"
            />
            )}
            <span className={classes.title}>
              {title}
            </span>
            {open ? <ExpandLess /> : <ExpandMore />}
          </Button>
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {subMenus}
          </List>
        </Collapse>
      </div>
    );
  } else {
    listItem = (
      <ListItem
        className={clsx(classes.item, className)}
        disableGutters
        {...rest}
      >
        <Button
          activeClassName={classes.active}
          className={classes.button}
          component={RouterLink}
          to={href}
        >
          {Icon && (
          <Icon
            className={classes.icon}
            size="20"
          />
          )}
          <span className={classes.title}>
            {title}
          </span>
        </Button>
      </ListItem>
    );
  }

  return listItem;
};

NavItem.propTypes = {
  className: PropTypes.string,
  href: PropTypes.string,
  icon: PropTypes.elementType,
  title: PropTypes.string,
  subMenuItems: PropTypes.array
};

export default NavItem;
