/* eslint-disable react/prop-types */
import React from 'react';
import { TablePagination } from '@material-ui/core';

export default function CustomTablePagination({
  count,
  rowsPerPage = 10,
  page,
  rowsPerPageOptions = [5, 10, 25],
  onChangePage,
  onChangeRowsPerPage
}) {
  return (
    <TablePagination
      component="div"
      count={count}
      onChangePage={(_, nextPage) => {
        onChangePage(nextPage + 1);
        console.log(nextPage);
      }}
      onChangeRowsPerPage={e => onChangeRowsPerPage(e.target.value)}
      page={page - 1}
      rowsPerPage={rowsPerPage}
      rowsPerPageOptions={rowsPerPageOptions}
    />
  );
}
