import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  makeStyles,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@material-ui/core';
import toast from 'react-hot-toast';
import TableLoader from 'src/components/TableLoader';

const useStyles = makeStyles(() => ({
  root: {
    marginBottom: 20
  }
}));

const ProfileDetails = ({
  className,
  customer,
  formData,
  onChange,
  isUpdating,
  onSave,
  handleVerification,
  isLoading,
  ...rest
}) => {
  const admin = formData;
  const classes = useStyles();

  const [isEditMode, setIsEditMode] = React.useState(false);

  const toggleEditMode = () => setIsEditMode(mode => !mode);

  const save = e => {
    e.preventDefault();
    // toggle mode when done saving
    onSave().then(() => {
      toggleEditMode();
      toast.success('Profile Updated');
    });
  };

  return (
    <form
      autoComplete="off"
      noValidate
      className={clsx(classes.root, className)}
      onSubmit={save}
      {...rest}
    >
      <Card>
        <CardHeader
          title="Profile"
          action={
            <div>
              {isEditMode ? (
                <div>
                  <Button
                    disabled={isUpdating}
                    color="default"
                    type="button"
                    onClick={toggleEditMode}
                    className={classes.cancelBtn}
                  >
                    Cancel
                  </Button>
                  <Button
                    disabled={isUpdating}
                    color="secondary"
                    variant="outlined"
                    type="submit"
                  >
                    {isUpdating ? 'Saving...' : 'Save'}
                  </Button>
                </div>
              ) : (
                <Button type="button" onClick={toggleEditMode}>
                  Edit
                </Button>
              )}
            </div>
          }
        />
        {isLoading ? <TableLoader /> : <Divider />}

        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <TextField
                disabled={!isEditMode}
                fullWidth
                helperText="Please specify the first name"
                label="First name"
                name="firstName"
                onChange={onChange}
                value={admin.firstName || ''}
                variant="outlined"
              />
            </Grid>

            <Grid item md={6} xs={12}>
              <TextField
                disabled={!isEditMode}
                fullWidth
                label="Last name"
                name="lastName"
                onChange={onChange}
                value={admin.lastName || ''}
                variant="outlined"
              />
            </Grid>

            <Grid item md={12} xs={12}>
              <TextField
                disabled={!isEditMode}
                fullWidth
                label="Email Address"
                name="email"
                onChange={onChange}
                value={admin.email || ''}
                variant="outlined"
              />
            </Grid>

            <Grid item md={12} xs={12}>
              <FormControl
                variant="outlined"
                style={{ marginTop: 10, marginBottom: 10 }}
              >
                <InputLabel id="demo-simple-select-outlined-label">
                  Role
                </InputLabel>
                <Select
                  disabled={!isEditMode}
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  name="role"
                  value={`${admin.role}`}
                  onChange={onChange}
                  label="Role"
                >
                  <MenuItem selected value="super">
                    Super Admin
                  </MenuItem>
                  <MenuItem value="admin">Admin</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item md={3} xs={3}>
              <FormControl
                variant="outlined"
                margin="normal"
                style={{ width: '100px' }}
              >
                <InputLabel id="demo-simple-select-outlined-label">
                  Mobile code
                </InputLabel>
                <Select
                  disabled={!isEditMode}
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={`${admin.mobileCode}`}
                  onChange={onChange}
                  label="Mobile code"
                >
                  <MenuItem value="+234">+234</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={6} xs={6}>
              <TextField
                disabled={!isEditMode}
                style={{ width: '200px' }}
                label="Mobile Number"
                margin="normal"
                name="mobile"
                onChange={onChange}
                type="text"
                value={`${admin.mobile}`}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
      </Card>
    </form>
  );
};

ProfileDetails.propTypes = {
  className: PropTypes.string,
  formData: PropTypes.object,
  customer: PropTypes.object,
  onChange: PropTypes.func,
  onSave: PropTypes.func,
  isUpdating: PropTypes.bool,
  handleVerification: PropTypes.func,
  isLoading: PropTypes.bool.isRequired
};

export default ProfileDetails;
