import clsx from 'clsx';
import React from 'react';
import { makeStyles, colors, Chip } from '@material-ui/core';
import PropTypes from 'prop-types';

const statusColors = {
  Pending: colors.orange[300],
  Cancelled: colors.red[300],
  Finished: colors.green[300],
  Started: colors.blue[300],
  Success: colors.green[300],
  Failed: colors.red[300],
  Authorized: colors.red[400],
  Disputed: colors.red[500],
  Acknowleged: 'lightgrey'
};

const useStyles = makeStyles(() => ({
  status: {
    color: '#fff',
    background: props => statusColors[props.status]
  }
}));

export default function TripStatus({
  className,
  size = 'small',
  status,
  ...props
}) {
  const classes = useStyles({ status });
  return (
    <Chip
      {...props}
      size={size}
      className={clsx(classes.status, className)}
      label={status}
    />
  );
}

TripStatus.propTypes = {
  className: PropTypes.string,
  status: PropTypes.string,
  size: PropTypes.string
};
