import React, { useEffect, useState } from 'react';
import {
  makeStyles,
  Container,
  Box,
  Table,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
  Card,
  colors
} from '@material-ui/core';
import { useQuery } from 'react-query';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Page from 'src/components/Page';
import TablePagination from 'src/components/TablePagination';
import useQueryString from 'src/hooks/useQueryString';
// import wallet from 'src/api/wallet';
// import TransactionRow from 'src/components/TransactionRow';
import TableLoader from 'src/components/TableLoader';
import Wallet from 'src/api/wallet';
import formatPrice from 'src/utils/formatPrice';
import { toNaira } from 'src/utils/helper';

import TripStatus from 'src/components/TripStatus';
import { useNavigate } from 'react-router';
import moment from 'moment';
import Toolbar from './Toolbar';

// import Filter from './Filter';

const useStyles = makeStyles(() => ({
  apprvingBtn: {
    color: colors.yellow['400'],
    borderColor: colors.yellow['400'],
    fontSize: '9px'
  },

  retryBtn: {
    color: colors.green[300],
    fontSize: '9px'
  },

  cancelBtn: {
    color: colors.red[300],
    fontSize: '9px'
  },

  root: {},
  nowrap: {
    whiteSpace: 'nowrap'
  }
}));

export default function WalletWithdrawals() {
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(true);
  const [query, setQueryString] = useQueryString();
  const navigate = useNavigate();

  const { data = {} } = useQuery(
    ['allWithdrawals', query],
    () => Wallet.getAllWalletWithdrawals(query),
    {
      keepPreviousData: true
    }
  );

  const { data: withdrawals = [] } = data;

  // console.log('withdrawals>>>', withdrawals);
  const setLoading = () => {
    if (withdrawals.length > 0) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setLoading();
  }, [withdrawals]);

  return (
    <Page className={classes.root} title="Wallet Withdrawals">
      {/* <CustomDialog
        open={openDialog}
        title="Are you sure want to Retry Withdrawal for this User?"
        message=""
        // action={handleDeleteUserRequestAction}
        handleDialogClose={handleRetryWithDrawalDialog}
      /> */}
      <Container maxWidth={false}>
        <Box mb={4}>
          <Toolbar />
          {/* <Filter defaultValues={query} /> */}
        </Box>
        <Card>
          <PerfectScrollbar>
            <Box minWidth={1050}>
              {isLoading ? (
                <TableLoader />
              ) : (
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Type</TableCell>
                      <TableCell>Withdrawal Amount</TableCell>
                      <TableCell>Withdrawal Status</TableCell>
                      {/* <TableCell>Withdrawal Action</TableCell> */}
                      <TableCell>Date&Time</TableCell>
                      <TableCell>Withdrawal Description</TableCell>
                    </TableRow>
                  </TableHead>
                  <>
                    {withdrawals.map(withdrawal => (
                      <TableBody>
                        <TableRow
                          key={withdrawal.id}
                          style={{ cursor: 'pointer' }}
                          onClick={() =>
                            navigate(`/app/wallet/withdrawals/${withdrawal.id}`)
                          }
                        >
                          <TableCell>DEBIT</TableCell>
                          <TableCell>
                            {formatPrice(
                              withdrawal.amount,
                              withdrawal.amountCurrency,
                              toNaira
                            )}
                          </TableCell>
                          <TableCell>
                            <TripStatus status={withdrawal.status} />
                          </TableCell>
                          <TableCell>
                            {moment(withdrawal.createdAt).format(
                              'DD/MM/YYYY HH:mm:ss A'
                            )}
                          </TableCell>
                          <TableCell>{withdrawal.description}</TableCell>
                        </TableRow>
                      </TableBody>
                    ))}
                  </>
                </Table>
              )}
            </Box>
          </PerfectScrollbar>
          {data.pagination && (
            <TablePagination
              count={data.pagination.totalCount}
              page={data.pagination.currentPage}
              rowsPerPage={data.pagination.rows}
              onChangeRowsPerPage={rows => setQueryString({ rows })}
              onChangePage={pageNo => setQueryString({ pageNo })}
            />
          )}
        </Card>
      </Container>
    </Page>
  );
}
