import storage from 'src/storage';

const userState = {
  ...storage.getUser()
};

export default function userReducer(state = userState, action) {
  switch (action.type) {
    case 'user/update':
      return {
        ...state,
        ...action.payload,
      };

    default: return state;
  }
}
