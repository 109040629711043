import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Typography,
  makeStyles,
  Fab,
  Link,
  IconButton,
  Menu,
  MenuItem
} from '@material-ui/core';
import { AlertCircle, CheckCircle, Mail } from 'react-feather';
import { green, red } from '@material-ui/core/colors';
import { AccountBalanceWallet } from '@material-ui/icons';
import moment from 'moment';
import CustomDialog from 'src/components/CustomDialog';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { withStyles } from '@material-ui/core/styles';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import BlockIcon from '@material-ui/icons/Block';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import Admin from 'src/api/admin';
import toast from 'react-hot-toast';

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative'
  },
  avatar: {
    height: 100,
    width: 100
  },

  icon: {
    position: 'absolute',
    right: 0
  }
}));

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5'
  }
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center'
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles(theme => ({
  root: {
    '&:focus': {
      // backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.black
      }
    }
  }
}))(MenuItem);

const Profile = ({
  className,
  customer,
  summary,
  handleVerification,
  driverVerifyFlag,
  ...rest
}) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [dialogOpen, setDialogOpen] = useState(false);

  const [dialogObj, setDialogObj] = useState({
    title: '',
    message: '',
    open: dialogOpen,
    action: () => console.log('No action specified')
  });

  // block user state menu and options and handlers
  const [anchorEl, setAnchorEl] = useState(null);
  const { id } = useParams();
  const [isBlocked, setIsBlocked] = useState(customer.isBlocked);

  const handleBlock = () => {
    setIsBlocked(prev => !prev);
  };

  const handleOpenTopOptions = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseTopOptions = () => {
    setAnchorEl(null);
  };

  const blockUserHandler = async () => {
    try {
      const res = await Admin.blockUserAccount(id);
      console.log('user block res>>', res);
      if (res) {
        toast.success(res.message);
        handleBlock();
      }
    } catch (e) {
      toast.error(e.message);
      console.log('unable to block user>>', e);
    }
  };

  const unBlockUserHandler = async () => {
    try {
      const res = await Admin.unBlockUserAccount(id);
      console.log('user unblock res>>', res);
      if (res) {
        toast.success(res.message);
        handleBlock();
      }
    } catch (e) {
      toast.error(e.message);
      console.log('unable to block user>>', e);
    }
  };

  const handleBlockUser = () => {
    setDialogObj({
      title: `Are you sure you want to block ${customer.firstName} ${customer.lastName}?`,
      message:
        'Please note blocking this user account will disable the user account from logging in!',
      action: () => {
        blockUserHandler();
        setDialogOpen(false);
      }
    });
    setDialogOpen(true);
    handleCloseTopOptions();
  };

  const handleUnblockUser = () => {
    setDialogObj({
      title: `Are you sure you want to Unblock ${customer.firstName} ${customer.lastName}?`,
      message:
        'Please note unblocking this user account will reactivate this user account access!',
      action: () => {
        unBlockUserHandler();
        setDialogOpen(false);
      }
    });
    setDialogOpen(true);
    handleCloseTopOptions();
  };

  // useEffect(() => {}, [isBlocked]);

  console.log('driver flag on >>>', driverVerifyFlag);

  const riderButton = customer.verifiedRider ? (
    <Button
      style={{ color: green[600] }}
      size="small"
      className={classes.button}
      startIcon={<CheckCircle />}
      onClick={() => {
        setDialogObj({
          title: `Unverify ${customer.firstName} ${customer.lastName}?`,
          message: 'Are you sure you want to unverify this user as rider',
          action: () => {
            handleVerification({ riderVerify: false });
            setDialogOpen(false);
          }
        });

        setDialogOpen(true);
      }}
    >
      Rider
    </Button>
  ) : (
    <Button
      style={{ color: red[600] }}
      size="small"
      className={classes.button}
      startIcon={<AlertCircle />}
      onClick={() => {
        setDialogObj({
          title: `Verify ${customer.firstName} ${customer.lastName}?`,
          message: 'Are you sure you want to verify this user as rider',
          action: () => {
            handleVerification({ riderVerify: true });
            setDialogOpen(false);
          }
        });
        setDialogOpen(true);
      }}
    >
      Rider
    </Button>
  );

  const driverButton = driverVerifyFlag ? (
    <Button
      style={{ color: green[600] }}
      size="small"
      className={classes.button}
      startIcon={<CheckCircle />}
      onClick={() => {
        setDialogObj({
          title: `Unverify ${customer.firstName} ${customer.lastName}?`,
          message: 'Are you sure you want to unverify this user as driver',
          action: () => {
            handleVerification({ driverVerify: false });
            setDialogOpen(false);
          }
        });

        setDialogOpen(true);
      }}
    >
      Driver
    </Button>
  ) : (
    <Button
      style={{ color: red[600] }}
      size="small"
      className={classes.button}
      startIcon={<AlertCircle />}
      onClick={() => {
        setDialogObj({
          title: `Verify ${customer.firstName} ${customer.lastName}?`,
          message: 'Are you sure you want to verify this user as driver',
          action: () => {
            handleVerification({ driverVerify: true });
            setDialogOpen(false);
          }
        });
        setDialogOpen(true);
      }}
    >
      Driver
    </Button>
  );

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CustomDialog
        title={dialogObj.title}
        message={dialogObj.message}
        open={dialogOpen}
        handleDialogClose={() => setDialogOpen(false)}
        action={dialogObj.action}
      />

      <IconButton
        aria-label="delete"
        className={classes.icon}
        onClick={handleOpenTopOptions}
      >
        <MoreVertIcon />
      </IconButton>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseTopOptions}
      >
        {isBlocked ? (
          <StyledMenuItem onClick={handleUnblockUser}>
            <ListItemIcon>
              <LockOpenIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Unblock User" />
          </StyledMenuItem>
        ) : (
          <StyledMenuItem onClick={handleBlockUser}>
            <ListItemIcon>
              <BlockIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Block User" />
          </StyledMenuItem>
        )}
      </StyledMenu>
      <CardContent>
        <Box alignItems="center" display="flex" flexDirection="column">
          <Avatar
            className={classes.avatar}
            src={customer.photographUrl || '/broken-image.jpg'}
          />
          <Typography color="textPrimary" gutterBottom variant="h3">
            {`${customer.firstName || ''} ${customer.lastName || ''}`}
          </Typography>
          <Typography color="textSecondary" gutterBottom variant="body1">
            {customer.miniBio || ''}
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            Member since: {moment(customer.createdAt).format('DD MMM YYYY')}
          </Typography>
        </Box>
      </CardContent>
      <Divider />
      <CardContent
        style={{
          display: 'flex',
          justifyContent: 'space-around',
          alignItems: 'center'
        }}
      >
        {riderButton}

        {driverButton}
        <Fab
          color="primary"
          size="small"
          aria-label="add"
          component={RouterLink}
          to={`/app/message/new?email=${customer.email}`}
        >
          <Mail />
        </Fab>
      </CardContent>
      <Divider />
      <CardContent
        style={{
          display: 'flex',
          justifyContent: 'space-around',
          textAlign: 'center'
        }}
      >
        <Link
          style={{ cursor: 'pointer', textDecoration: 'none' }}
          onClick={() => navigate(`/app/customers/trips/${customer.id}`)}
        >
          <Typography color="textPrimary" gutterBottom variant="h5">
            {summary.totalSuccessfulTripCount || 0}
          </Typography>
          <Typography variant="overline" display="block" gutterBottom>
            Trips
          </Typography>
        </Link>
        <Link
          style={{ cursor: 'pointer', textDecoration: 'none' }}
          onClick={() => navigate(`/app/customers/bookings/${customer.id}`)}
        >
          <Typography color="textPrimary" gutterBottom variant="h5">
            {summary.totalBookingsCount || 0}
          </Typography>
          <Typography variant="overline" display="block" gutterBottom>
            Bookings
          </Typography>
        </Link>
        <Link
          style={{ cursor: 'pointer', textDecoration: 'none' }}
          // onClick={() => navigate(`/app/customers/bookings/${customer.id}`)}
        >
          <Typography color="textPrimary" gutterBottom variant="h5">
            0
          </Typography>
          <Typography variant="overline" display="block" gutterBottom>
            Payouts
          </Typography>
        </Link>
      </CardContent>
      <Divider />
      <CardContent
        style={{
          display: 'flex',
          justifyContent: 'space-around',
          textAlign: 'center'
        }}
      >
        <Link
          style={{ cursor: 'pointer', textDecoration: 'none' }}
          onClick={() => navigate(`/app/customers/wallet/${customer.walletId}`)}
        >
          <AccountBalanceWallet fontSize="large" />
          <Typography color="textPrimary" gutterBottom variant="h5">
            Wallet
          </Typography>
        </Link>
      </CardContent>
    </Card>
  );
};

Profile.propTypes = {
  className: PropTypes.string,
  customer: PropTypes.object,
  summary: PropTypes.object,
  handleVerification: PropTypes.func,
  driverVerifyFlag: PropTypes.bool
};

export default Profile;
