import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import Admin from 'src/api/admin';
import useQueryString from 'src/hooks/useQueryString';
import {
  Box,
  Card,
  Container,
  makeStyles,
  Table,
  TableCell,
  TableHead,
  TablePagination,
  TableRow
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Page from 'src/components/Page';
import TableLoader from 'src/components/TableLoader';
import Results from './Results';
import LocationToolbar from './LocationToolbar';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },

  tableHead: {
    textAlign: 'center'
  },

  nowrap: {
    whiteSpace: 'nowrap'
  }
}));

export default function TripLocations() {
  const classes = useStyles();
  const [query, setQueryString] = useQueryString();
  const [isLoading, setIsLoading] = useState(true);

  const { data = {} } = useQuery(
    ['trip-locations', query],
    () => Admin.getAvaliableLocations(query),
    {
      keepPreviousData: true
    }
  );

  const setLoading = () => {
    if (data?.data?.votes?.length > 0) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setLoading();
  }, [data?.data?.votes]);

  console.log(' data>>', data);
  const { data: locations } = data;
  console.log('locations data>>', locations);

  return (
    <Page className={classes.root} title="Trips">
      <Container maxWidth={false}>
        <Box mb={4}>
          <LocationToolbar />
        </Box>
        <Card>
          <PerfectScrollbar>
            <Box minWidth={1050}>
              {isLoading ? (
                <TableLoader />
              ) : (
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Created At</TableCell>
                      <TableCell>Location Tag</TableCell>
                      <TableCell>Location Name</TableCell>
                      <TableCell>Release Date</TableCell>
                      <TableCell className={classes.tableHead}>
                        Active Location
                      </TableCell>
                      <TableCell>Votes</TableCell>
                      <TableCell className={classes.tableHead}>
                        Updated At
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  {locations?.votes?.map(location => (
                    <Results location={location} key={location.id} />
                  ))}
                </Table>
              )}
            </Box>
          </PerfectScrollbar>
          {data?.data?.pagination && (
            <TablePagination
              count={data?.data?.pagination?.totalCount}
              page={data?.data?.pagination?.currentPage}
              rowsPerPage={data?.data?.pagination?.rows}
              onChangeRowsPerPage={rows => setQueryString({ rows })}
              onChangePage={pageNo => setQueryString({ pageNo })}
            />
          )}
        </Card>
      </Container>
    </Page>
  );
}
