import { userActivityList } from '../action-lists';

const userActivityState = {
  users: [],
  pagination: {
    totalCount: 0,
    rowsPerPage: 10,
    page: 0
  }
};

export default function userActivityReducer(state = userActivityState, action) {
  switch (action.type) {
    case userActivityList.userActivity: {
      const { data, pagination } = action.payload;

      return {
        ...state,
        users: data,
        pagination: {
          totalCount: pagination.totalCount,
          rowsPerPage: pagination.rows,
          page: pagination.currentPage - 1,
        }
      };
    }

    default: return state;
  }
}
