import config from 'src/config';
import api from 'src/services/httpService';
import storage from 'src/storage';

class User {
  static getUsers(queries) {
    const axiosConfig = {
      headers: {
        Authorization: `Bearer ${storage.getToken()}`
      }
    };
    const searchParams = new URLSearchParams(queries);
    return api.get(
      `${config.rootUrl}/admin/user/?${searchParams}`,
      axiosConfig
    );
  }

  // get verified drivers
  static getVerifiedDrivers(queries) {
    const axiosConfig = {
      headers: {
        Authorization: `Bearer ${storage.getToken()}`
      }
    };
    const searchParams = new URLSearchParams(queries);
    return api
      .get(
        `${config.rootUrl}/admin/verified/drivers?${searchParams}`,
        axiosConfig
      )
      .then(data => data);
  }

  // filter customers by date
  // static getFilteredUsersByDate(queries) {
  //   const axiosConfig = {
  //     headers: {
  //       Authorization: `Bearer ${storage.getToken()}`
  //     }
  //   };
  //   const searchParams = new URLSearchParams(queries);
  //   return api.get(
  //     `${config.rootUrl}/admin/user/?sort=DESC&date=${searchParams}`,
  //     axiosConfig
  //   );
  // }

  // Get activity Log
  static getActivityLog(queries) {
    const axiosConfig = {
      headers: {
        Authorization: `Bearer ${storage.getToken()}`
      }
    };
    const searchParams = new URLSearchParams(queries);
    return api.get(
      `${config.rootUrl}/admin/user/?${searchParams}`,
      axiosConfig
    );
  }

  static getUser(id) {
    const axiosConfig = {
      headers: {
        Authorization: `Bearer ${storage.getToken()}`
      }
    };
    return api.get(`${config.rootUrl}/admin/user/${id}`, axiosConfig);
  }

  static updateUser(id, update) {
    const axiosConfig = {
      headers: {
        Authorization: `Bearer ${storage.getToken()}`
      }
    };
    return api.patch(`${config.rootUrl}/admin/user/${id}`, update, axiosConfig);
  }

  static verifyUser(id, data = {}) {
    const axiosConfig = {
      headers: {
        Authorization: `Bearer ${storage.getToken()}`
      }
    };
    return api.post(
      `${config.rootUrl}/admin/user/${id}/verify`,
      data,
      axiosConfig
    );
  }

  static retryUserVerification(id) {
    const axiosConfig = {
      headers: {
        Authorization: `Bearer ${storage.getToken()}`
      }
    };

    return api
      .put(
        `${config.rootUrl}/admin/reset-user-verify-ref-id/${id}`,
        axiosConfig
      )
      .then(data => data);
  }

  static sendEmail(data) {
    const axiosConfig = {
      headers: {
        Authorization: `Bearer ${storage.getToken()}`
      }
    };

    return api.post(
      `${config.rootUrl}/admin/email${data?.param ? `/?${data.param}` : ''}`,
      data.data,
      axiosConfig
    );
  }
}

export default User;
