import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { makeStyles, colors } from '@material-ui/core';

const useStyles = makeStyles({
  header: {
    background: colors.green[500],
    color: '#fff',
    padding: '5rem 1rem 4rem',
    textAlign: 'center'
  },
  title: {
    fontSize: 30
  },
  content: {
    marginTop: -30,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: 40,
    background: '#fff',
    maxWidth: 800,
    padding: '3rem 2rem'
  }
});

export default function TermsAndCondition({ children, title }) {
  const classes = useStyles();

  return (
    <div className="">
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <header className={classes.header}>
        <h3 className={classes.title}>{title}</h3>
      </header>
      <div className={classes.content}>{children}</div>
    </div>
  );
}

TermsAndCondition.propTypes = {
  children: PropTypes.element,
  title: PropTypes.string
};
