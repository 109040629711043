import config from 'src/config';
import api from 'src/services/httpService';
import storage from 'src/storage';

class Admin {
  static getAdmins(queries) {
    const axiosConfig = {
      headers: {
        Authorization: `Bearer ${storage.getToken()}`
      }
    };
    const searchParams = new URLSearchParams(queries);
    // console.log(axiosConfig);
    return api.get(
      `${config.rootUrl}/admin/staff/?${searchParams}`,
      axiosConfig
    );
  }

  static getAdmin(id) {
    const axiosConfig = {
      headers: {
        Authorization: `Bearer ${storage.getToken()}`
      }
    };
    return api.get(`${config.rootUrl}/admin/staff/${id}`, axiosConfig);
  }

  static async getSettings() {
    const axiosConfig = {
      headers: {
        Authorization: `Bearer ${storage.getToken()}`
      }
    };
    return api
      .get(`${config.rootUrl}/admin/settings`, axiosConfig)
      .then(({ data }) => data);
  }

  static async updateSettings(settings) {
    const axiosConfig = {
      headers: {
        Authorization: `Bearer ${storage.getToken()}`
      }
    };
    return api
      .patch(`${config.rootUrl}/admin/settings`, settings, axiosConfig)
      .then(({ data }) => data);
  }

  static verifyUser(id, { driverVerify, riderVerify }) {
    const axiosConfig = {
      headers: {
        Authorization: `Bearer ${storage.getToken()}`
      }
    };
    const data = {};

    if (typeof driverVerify !== 'undefined') {
      data.driverVerify = driverVerify;
    }

    if (typeof riderVerify !== 'undefined') {
      data.riderVerify = riderVerify;
    }
    return api.post(
      `${config.rootUrl}/admin/user/${id}/verify`,
      data,
      axiosConfig
    );
  }

  // verify driver plate number
  static verifyDriverPlateNumber(payload) {
    const axiosConfig = {
      headers: {
        Authorization: `Bearer ${storage.getToken()}`
      }
    };

    return api
      .put(
        `${config.rootUrl}/admin/create-plate-number-expiry-date`,
        payload,
        axiosConfig
      )
      .then(data => data);
  }

  static verifyVehicle(id, payload) {
    const axiosConfig = {
      headers: {
        Authorization: `Bearer ${storage.getToken()}`
      }
    };

    return api
      .post(
        `${config.rootUrl}/admin/vehicle/${id}/verify`,
        payload,
        axiosConfig
      )
      .then(data => data);
  }

  // unverify driver vehicle
  static unVerifyDriver(id) {
    const axiosConfig = {
      headers: {
        Authorization: `Bearer ${storage.getToken()}`
      }
    };

    return api
      .put(`${config.rootUrl}/admin/un-verify-car/${id}`, axiosConfig)
      .then(data => data);
  }

  // retry car verification
  static retryCarVerication(id) {
    const axiosConfig = {
      headers: {
        Authorization: `Bearer ${storage.getToken()}`
      }
    };

    return api
      .put(
        `${config.rootUrl}/admin/reset-user-verify-ref-id/${id}`,
        axiosConfig
      )
      .then(data => data);
  }

  // retry driver license verification
  static retryDriverLicenseVerification(id) {
    const axiosConfig = {
      headers: {
        Authorization: `Bearer ${storage.getToken()}`
      }
    };

    return api
      .put(
        `${config.rootUrl}/admin/reset-driver-license-verify-ref-id/${id}`,
        axiosConfig
      )
      .then(data => data);
  }

  //  trips
  static cancelTrip(id) {
    const axiosConfig = {
      headers: {
        Authorization: `Bearer ${storage.getToken()}`
      }
    };
    return api
      .patch(`${config.rootUrl}/admin/trip/${id}/cancel`, null, axiosConfig)
      .then(({ data }) => data);
  }

  //  cancel booking
  static cancelBooking(id) {
    const axiosConfig = {
      headers: {
        Authorization: `Bearer ${storage.getToken()}`
      }
    };
    return api
      .patch(`${config.rootUrl}/admin/booking/${id}/cancel`, null, axiosConfig)
      .then(({ data }) => data);
  }

  // refunds
  static getRefunds(query) {
    const axiosConfig = {
      headers: {
        Authorization: `Bearer ${storage.getToken()}`
      },
      params: query
    };
    return api
      .get(`${config.rootUrl}/admin/refund`, axiosConfig)
      .then(({ data }) => data);
  }

  // refunds
  static getRefund(id) {
    const axiosConfig = {
      headers: {
        Authorization: `Bearer ${storage.getToken()}`
      }
    };
    return api
      .get(`${config.rootUrl}/admin/refund/${id}`, axiosConfig)
      .then(({ data }) => data);
  }

  // accept refund request
  static approveRefundRequest(bookingId, payload) {
    const axiosConfig = {
      headers: {
        Authorization: `Bearer ${storage.getToken()}`
      }
    };
    return api
      .post(
        `${config.rootUrl}/admin/refund/${bookingId}/authorize`,
        payload,
        axiosConfig
      )
      .then(({ data }) => data);
  }

  // accept refund request
  static reAuthorizeRefundRequest(bookingId, payload) {
    const axiosConfig = {
      headers: {
        Authorization: `Bearer ${storage.getToken()}`
      }
    };
    return api
      .patch(
        `${config.rootUrl}/admin/refund/${bookingId}/authorize-retry`,
        payload,
        axiosConfig
      )
      .then(({ data }) => data);
  }

  // Admin edit self
  static adminEditSelf(payload) {
    const axiosConfig = {
      headers: {
        Authorization: `Bearer ${storage.getToken()}`
      }
    };
    return api
      .patch(`${config.rootUrl}/admin`, payload, axiosConfig)
      .then(({ data }) => data);
  }

  // Super admin edit admin
  static superAdminEditAdmin(id, payload) {
    const axiosConfig = {
      headers: {
        Authorization: `Bearer ${storage.getToken()}`
      }
    };
    return api
      .patch(`${config.rootUrl}/admin/staff/${id}/edit`, payload, axiosConfig)
      .then(({ data }) => data);
  }

  // Super admin sent reset password link admin
  static superAdminResetAdminPassword(payload) {
    const axiosConfig = {
      headers: {
        Authorization: `Bearer ${storage.getToken()}`
      }
    };
    return api
      .post(`${config.rootUrl}/auth/admin/forgot`, payload, axiosConfig)
      .then(({ data }) => data);
  }

  // delete deactivated admin
  static deleteDeactivatedAdmin(id) {
    const axiosConfig = {
      headers: {
        Authorization: `Bearer ${storage.getToken()}`
      }
    };
    return api.delete(`${config.rootUrl}/admin/staff/${id}`, axiosConfig);
  }

  // get all deletion requests from users.
  static getAllDeletionRequests(query) {
    const axiosConfig = {
      headers: {
        Authorization: `Bearer ${storage.getToken()}`
      },

      params: query
    };

    return api
      .get(`${config.rootUrl}/admin/delete-request`, axiosConfig)
      .then(({ data }) => data);
  }

  // approve a pending deletion request.
  static approveDeletionRequest(id) {
    const axiosConfig = {
      headers: {
        Authorization: `Bearer ${storage.getToken()}`
      }
    };

    return api.post(
      `${config.rootUrl}/admin/delete-request/${id}`,
      axiosConfig
    );
  }

  // block user's wallet
  static blockUserWallet(id) {
    const axiosConfig = {
      headers: {
        Authorization: `Bearer ${storage.getToken()}`
      }
    };

    return api
      .post(`${config.rootUrl}/admin/block-wallet/${id}`, axiosConfig)
      .then(({ data }) => data);
  }

  // unblock user wallet
  static unBlockUserWallet(id) {
    const axiosConfig = {
      headers: {
        Authorization: `Bearer ${storage.getToken()}`
      }
    };

    return api
      .post(`${config.rootUrl}/admin/unblock-wallet/${id}`, axiosConfig)
      .then(({ data }) => data);
  }

  // block user account
  static blockUserAccount(id) {
    const axiosConfig = {
      headers: {
        Authorization: `Bearer ${storage.getToken()}`
      }
    };

    return api
      .post(`${config.rootUrl}/admin/block-user/${id}`, axiosConfig)
      .then(({ data }) => data);
  }

  // unblock user account
  static unBlockUserAccount(id) {
    const axiosConfig = {
      headers: {
        Authorization: `Bearer ${storage.getToken()}`
      }
    };

    return api
      .post(`${config.rootUrl}/admin/unblock-user/${id}`, axiosConfig)
      .then(({ data }) => data);
  }

  // get customer's ratings
  static getCustomerRatings(id) {
    const axiosConfig = {
      headers: {
        Authorization: `Bearer ${storage.getToken()}`
      }
    };
    return api.get(
      `${config.rootUrl}/admin/rider-driver-ratings/${id}`,
      axiosConfig
    );
  }

  // eslint-disable-next-line consistent-return
  // getCustomers report
  static getCustomersReports(queries) {
    const axiosConfig = {
      headers: {
        Authorization: `Bearer ${storage.getToken()}`
      }
    };

    const searchParams = new URLSearchParams(queries);

    return api
      .get(`${config.rootUrl}/admin/reports?${searchParams}`, axiosConfig)
      .then(data => data);
  }

  static getCustomersReportDetails(id) {
    const axiosConfig = {
      headers: {
        Authorization: `Bearer ${storage.getToken()}`
      }
    };

    return api
      .get(`${config.rootUrl}/admin/report/${id}`, axiosConfig)
      .then(data => data?.data?.data);
  }

  static resolveCustomerReport(id) {
    const axiosConfig = {
      headers: {
        Authorization: `Bearer ${storage.getToken()}`
      }
    };

    return api
      .patch(`${config.rootUrl}/admin/resolve-report/${id}`, axiosConfig)
      .then(data => data);
  }

  static getCustomerBookingDetails(id) {
    const axiosConfig = {
      headers: {
        Authorization: `Bearer ${storage.getToken()}`
      }
    };

    return api
      .get(`${config.rootUrl}/admin/booking/${id}`, axiosConfig)
      .then(data => data);
  }

  static getAvaliableLocations(queries) {
    const axiosConfig = {
      headers: {
        Authorization: `Bearer ${storage.getToken()}`
      }
    };

    const searchParams = new URLSearchParams(queries);
    return api
      .get(`${config.rootUrl}/admin/trip-vote?${searchParams}`, axiosConfig)
      .then(data => data);
  }

  static changeAvaliableLocationStatus(status, payload) {
    const axiosConfig = {
      headers: {
        Authorization: `Bearer ${storage.getToken()}`
      }
    };
    return api
      .put(
        `${config.rootUrl}/admin/update-vote-status/${status}`,
        payload,
        axiosConfig
      )
      .then(data => data);
  }

  static adminUsersResolveReport(reportId, payload) {
    const axiosConfig = {
      headers: {
        Authorization: `Bearer ${storage.getToken()}`
      }
    };
    return api
      .post(
        `${config.rootUrl}/admin/settle-rider-report/${reportId}`,
        payload,
        axiosConfig
      )
      .then(data => data);
  }
}

export default Admin;
