import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Card,
  CardContent,
  TextField,
  // InputAdornment,
  // SvgIcon,
  makeStyles,
  // IconButton,
  Button
} from '@material-ui/core';
import qs from 'qs';
// import { Search as SearchIcon } from 'react-feather';
import { useLocation, useNavigate } from 'react-router';
import moment from 'moment';
import SearchField from 'src/components/SearchField';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles(theme => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  searchBox: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'center'
  },
  mobileSearchBox: {
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  },
  searchInput: {
    flex: 1,
    marginRight: 10,
    width: '100%'
  },
  searchButton: {
    width: 50,
    height: 50
  },

  toolBox: {
    display: 'flex',
    justifyContent: 'space-around',
    gap: '5px'
  },

  mobileToolBox: {
    display: 'flex',
    justifyContent: 'space-around',
    gap: '15px',
    flexDirection: 'column'
  },

  dateBox: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },

  mobileDateBox: {
    width: '100%',
    display: 'flex',
    alignItems: 'center'
  },

  mobileForm: {
    width: '100%',
    display: 'flex',
    gap: '1rem',
    alignItems: 'center'
  },

  form: {
    width: '70%',
    display: 'flex',
    gap: '1rem',
    alignItems: 'center'
  },

  textField: {
    width: '100%'
  }
}));

const Toolbar = ({ className, ...rest }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const classes = useStyles();
  const [searchWord, setSearchWord] = useState('');
  const [filterDate, setFilterDate] = useState('');
  const isMobileView = useMediaQuery('(max-width:768px)');

  console.log('isMobileView>>', isMobileView);

  const [queries, setQueries] = useState({});

  const formattedDate = moment(filterDate).format('DD/MM/YYYY');
  console.log('filter date', formattedDate);

  const handleSearch = e => {
    e.preventDefault();
    const { pathname } = location;
    navigate(`${pathname}?${qs.stringify({ searchWord })}`);
  };

  const handleFilterUserByDate = e => {
    e.preventDefault();
    const { pathname } = location;
    const newQueries = { ...queries, date: formattedDate };
    navigate(`${pathname}?${qs.stringify(newQueries)}`);
    console.log('filter submit date>>', formattedDate);
  };

  useEffect(() => {
    const { search } = location;
    const query = qs.parse(search, { ignoreQueryPrefix: true });
    if (query.searchWord) {
      setSearchWord(query.searchWord);
      setQueries(qs.parse(search, { ignoreQueryPrefix: true }));
    }
    // } else if (filterDate) {
    //   setFilterDate(formattedDate);
    // }
  }, [location]);

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      {/* <Box
        display="flex"
        justifyContent="flex-end"
      >
        <Button className={classes.importButton}>
          Import
        </Button>
        <Button className={classes.exportButton}>
          Export
        </Button>
        <Button
          color="primary"
          variant="contained"
        >
          Add customer
        </Button>
      </Box> */}
      <Box mt={3}>
        <Card>
          <CardContent
            className={`${
              isMobileView ? classes.mobileToolBox : classes.toolBox
            }`}
          >
            <Box
              className={isMobileView ? classes.mobileDateBox : classes.dateBox}
            >
              <form
                onSubmit={handleFilterUserByDate}
                className={isMobileView ? classes.mobileForm : classes.form}
              >
                <TextField
                  className={classes.textField}
                  onChange={e => setFilterDate(e.target.value)}
                  size="small"
                  id="filter-date"
                  label="Filter by Registered Date"
                  name="filter-date"
                  variant="outlined"
                  type="date"
                  InputLabelProps={{
                    shrink: true
                  }}
                />
                <Button type="submit" variant="contained">
                  Filter
                </Button>
              </form>
            </Box>
            {/* <Box className={classes.searchBox} maxWidth={500}>
              <TextField
                className={classes.searchInput}
                value={searchWord}
                onChange={event => {
                  setSearchWord(event.target.value);
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon fontSize="small" color="action">
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  )
                }}
                placeholder="Search customer"
                variant="outlined"
              />
              <IconButton
                className={classes.searchButton}
                aria-label="search"
                onClick={handleSearch}
              >
                <SearchIcon />
              </IconButton>
            </Box> */}
            <Box
              className={
                isMobileView ? classes.mobileSearchBox : classes.searchBox
              }
            >
              <SearchField
                searchValue={searchWord}
                searchHandler={handleSearch}
                searchPlaceHolder="Search Customers"
                setSearch={setSearchWord}
                classes={classes}
              />
            </Box>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string
};

export default Toolbar;
