import config from 'src/config';
import api from 'src/services/httpService';
import storage from 'src/storage';

const Wallet = {
  axiosConfig: {
    headers: {
      Authorization: `Bearer ${storage.getToken()}`
    }
  },

  // getAllWalletWithdrawals: () => {
  //   // console.log(axiosConfig);
  //   return api
  //     .get(`${config.rootUrl}/admin/wallet/withdrawals`, Wallet.axiosConfig)
  //     .then(({ data }) => data);
  // },

  getAllWalletWithdrawals: queries => {
    const axiosConfig = {
      headers: {
        Authorization: `Bearer ${storage.getToken()}`
      }
    };

    const searchParams = new URLSearchParams(queries);

    return api
      .get(
        `${config.rootUrl}/admin/wallet/withdrawals?${searchParams}`,
        axiosConfig
      )
      .then(({ data }) => data);
  },

  // all wallet transactions
  getAllWalletTransactions: queries => {
    const axiosConfig = {
      headers: {
        Authorization: `Bearer ${storage.getToken()}`
      }
    };
    const searchParams = new URLSearchParams(queries);

    return api
      .get(
        `${config.rootUrl}/admin/wallet/transactions?${searchParams}`,
        axiosConfig
      )
      .then(({ data }) => data);
  },

  // transaction details
  getWalletTransactionDetails: id => {
    return api
      .get(
        `${config.rootUrl}/admin/wallet/transactions/${id}`,
        Wallet.axiosConfig
      )
      .then(({ data }) => data);
  },

  // withdrawal details
  getWalletWithDrawalDetails: id => {
    return api
      .get(
        `${config.rootUrl}/admin/wallet/withdrawals/${id}`,
        Wallet.axiosConfig
      )
      .then(({ data }) => data);
  },

  retryWalletWithDrawal: id => {
    return api
      .post(
        `${config.rootUrl}/admin/retry-withdrawal/${id}`,
        Wallet.axiosConfig
      )
      .then(({ data }) => data);
  },

  cancelWalletWithDrawal: id => {
    return api
      .post(`${config.rootUrl}/admin/cancel-withdraw/${id}`, Wallet.axiosConfig)
      .then(({ data }) => data);
    // wallet withdrawal options for admins
  },
  walletWithDrawalOption: id => {
    return api.post(
      `${config.rootUrl}/admin/withdraw-money-for-user/${id}`,
      Wallet.axiosConfig
    );
  }

  // cancelWalletWithDrawal: id => {
  //   return api
  //     .post(`${config.rootUrl}/admin/cancel-withdraw/${id}`, Wallet.axiosConfig)
  //     .then(({ data }) => data);
  // },
};
export default Wallet;
