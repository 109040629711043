import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  colors,
  makeStyles,
  TableCell,
  TableRow,
  Box,
  Typography
} from '@material-ui/core';
// import { toNaira } from 'src/utils/helper';
import formatPrice from 'src/utils/formatPrice';
import TripStatus from 'src/components/TripStatus';
import moment from 'moment';
import toast from 'react-hot-toast';
import Wallet from 'src/api/wallet';
import truncateSentence from 'src/utils/truncateSentence';

const useStyles = makeStyles(() => ({
  apprvingBtn: {
    color: colors.yellow['400'],
    borderColor: colors.yellow['400'],
    fontSize: '9px'
  },
  retryBtn: {
    color: colors.green[300],
    fontSize: '9px'
  },
  cancelBtn: {
    color: colors.red[300],
    fontSize: '9px'
  },
  root: {},
  nowrap: {
    whiteSpace: 'nowrap'
  }
}));

const TransactionRow = ({ transaction, hover, handleRefetch, ...props }) => {
  const {
    amount,
    amountCurrency,
    transactionStatus,
    transactionType,
    balance,
    id,
    previousBalance,
    summary,
    createdAt,
    cancelledBy,
    retryByAdmin
  } = transaction;
  const [isRetrying, setIsRetrying] = useState(false);
  const [isCancelling, setIsCancelling] = useState(false);
  const [withDrawalAction, setWithDrawalAction] = useState(transactionStatus);
  const [transacSummary, setTransacSummary] = useState(summary);

  const classes = useStyles();

  const handleRetryWithDrawal = async transacId => {
    setIsRetrying(true);
    try {
      const res = await Wallet.retryWalletWithDrawal(transacId);
      setIsRetrying(false);
      toast.success(res.message);
      handleRefetch();
    } catch (error) {
      setIsRetrying(false);
      toast.error(error.message);
    }
  };

  const handleCancelWithDrawal = async transacId => {
    setIsCancelling(true);
    try {
      const res = await Wallet.cancelWalletWithDrawal(transacId);
      setIsCancelling(false);
      toast.success(res.message);
      handleRefetch();
    } catch (error) {
      setIsCancelling(false);
      toast.error(error.message);
    }
  };

  const handleButtonClick = event => {
    event.stopPropagation();
  };

  useEffect(() => {
    if (transactionStatus) {
      setWithDrawalAction(transactionStatus);
      setTransacSummary(truncateSentence(summary, 30));
    }
  }, [transactionStatus]);

  console.log('transaction row rerender>>');
  return (
    <TableRow
      hover={hover}
      style={{ cursor: hover ? 'pointer' : 'auto' }}
      {...props}
    >
      <TableCell>{transactionType === 'DR' ? 'DEBIT' : 'CREDIT'}</TableCell>
      {previousBalance && (
        <TableCell>
          {formatPrice((previousBalance / 100).toFixed(2), amountCurrency)}
        </TableCell>
      )}
      <TableCell>
        {formatPrice((amount / 100).toFixed(2), amountCurrency)}
      </TableCell>
      {balance && (
        <TableCell className={classes.nowrap}>
          {formatPrice((balance / 100).toFixed(2), amountCurrency)}
        </TableCell>
      )}
      {transactionStatus && (
        <TableCell size="smalls">
          <TripStatus status={withDrawalAction} />
        </TableCell>
      )}
      {createdAt && (
        <TableCell>
          {moment(createdAt).format('DD/MM/YYYY HH:mm:ss A')}
        </TableCell>
      )}
      {withDrawalAction === 'Failed' ? (
        <TableCell>
          <Box sx={{ display: 'flex', gap: 4 }}>
            <Button
              variant="contained"
              onClick={event => {
                handleButtonClick(event);
                handleRetryWithDrawal(id);
              }}
              className={isRetrying ? classes.apprvingBtn : classes.retryBtn}
            >
              {isRetrying ? 'Retrying...' : 'Retry'}
            </Button>
            <Button
              variant="contained"
              onClick={event => {
                handleButtonClick(event);
                handleCancelWithDrawal(id);
              }}
              className={isCancelling ? classes.apprvingBtn : classes.cancelBtn}
            >
              {isCancelling ? 'Cancelling...' : 'Cancel'}
            </Button>
          </Box>
        </TableCell>
      ) : (
        <TableCell>No action</TableCell>
      )}
      {retryByAdmin ? (
        <TableCell>
          <Box>
            <Typography>{retryByAdmin?.name}</Typography>
          </Box>
        </TableCell>
      ) : (
        <TableCell>No Admin action</TableCell>
      )}
      {cancelledBy ? (
        <TableCell>
          <Box>
            <Typography>{cancelledBy?.name}</Typography>
          </Box>
        </TableCell>
      ) : (
        <TableCell>No Admin action</TableCell>
      )}
      {summary ? (
        <TableCell>
          <Box sx={{ display: 'flex', gap: 4 }}>
            <Typography>{transacSummary}</Typography>
          </Box>
        </TableCell>
      ) : (
        <TableCell>No Transaction Summary</TableCell>
      )}
    </TableRow>
  );
};

TransactionRow.propTypes = {
  transaction: PropTypes.object,
  hover: PropTypes.bool,
  handleRefetch: PropTypes.func.isRequired
};

export default TransactionRow;
