import React from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  makeStyles,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@material-ui/core';
import Page from 'src/components/Page';
import Auth from 'src/api/auth';
import { connect } from 'react-redux';
import alertSnackbarActions from 'src/store/actions/alertSnackbarActions';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const AdminCreateView = ({ setOpen, setMessage, setSeverity }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  return (
    <Page className={classes.root} title="Register">
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              email: '',
              firstName: '',
              lastName: '',
              role: 'admin',
              mobileCode: '+234',
              mobile: '',
              password: ''
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email('Must be a valid email')
                .max(255)
                .required('Email is required'),
              firstName: Yup.string()
                .max(255)
                .required('First name is required'),
              lastName: Yup.string()
                .max(255)
                .required('Last name is required'),
              password: Yup.string()
                .max(255)
                .required('password is required'),
              mobile: Yup.string()
                .max(20)
                .required('Mobile number is required'),
              role: Yup.string()
                .oneOf(['super', 'admin'], 'Can be one of admin or super admin')
                .required('Role is required')
            })}
            onSubmit={async (values, { resetForm }) => {
              setOpen(false);
              setMessage('');
              try {
                await Auth.signup(values);
                setSeverity('success');
                setMessage('Admin added successfully');
                setOpen(true);
                resetForm({ values: '' });
                navigate('/app/admins', { replace: true });
              } catch (err) {
                const { errors } = err.response.data;
                setSeverity('error');
                setMessage(errors[0]);
                setOpen(true);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Box mb={3} mt={3}>
                  <Typography color="textPrimary" variant="h6">
                    Create new admin account
                  </Typography>
                </Box>
                <TextField
                  error={Boolean(touched.firstName && errors.firstName)}
                  fullWidth
                  helperText={touched.firstName && errors.firstName}
                  label="First name"
                  margin="normal"
                  name="firstName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.firstName}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.lastName && errors.lastName)}
                  fullWidth
                  helperText={touched.lastName && errors.lastName}
                  label="Last name"
                  margin="normal"
                  name="lastName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.lastName}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label="Email Address"
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  variant="outlined"
                />
                <FormControl
                  variant="outlined"
                  style={{ marginTop: 10, marginBottom: 10 }}
                >
                  <InputLabel id="demo-simple-select-outlined-label">
                    Role
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    name="role"
                    value={values.role}
                    onChange={handleChange}
                    label="Role"
                  >
                    <MenuItem value="super">Super Admin</MenuItem>
                    <MenuItem value="admin">Admin</MenuItem>
                  </Select>
                </FormControl>
                <div style={{ display: 'flex' }}>
                  <FormControl
                    variant="outlined"
                    margin="normal"
                    style={{ flex: 1, marginRight: 10 }}
                  >
                    <InputLabel id="demo-simple-select-outlined-label">
                      Mobile code
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={values.mobileCode}
                      onChange={handleChange}
                      label="Mobile code"
                    >
                      <MenuItem value="+234">+234</MenuItem>
                    </Select>
                  </FormControl>
                  <TextField
                    style={{ flex: 2 }}
                    error={Boolean(touched.password && errors.password)}
                    helperText={touched.password && errors.password}
                    label="Mobile Number"
                    margin="normal"
                    name="mobile"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.mobile}
                    variant="outlined"
                  />
                </div>
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Password"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />
                <Box my={2}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Signup Admin
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
};

AdminCreateView.propTypes = {
  setOpen: PropTypes.func,
  setMessage: PropTypes.func,
  setSeverity: PropTypes.func
};

const { setOpen, setMessage, setSeverity } = alertSnackbarActions;

const mapDispatchToProps = {
  setOpen,
  setMessage,
  setSeverity
};

export default connect(null, mapDispatchToProps)(AdminCreateView);
