import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import Admin from 'src/api/admin';

const AdminSettingsContext = React.createContext({});

export function AdminSettingsProvider({ children }) {
  const [settings, setSettings] = useState({
    pricingCurrency: '',
    pricing: '',
    rate: '',
    refundProcessDays: '',
    payoutProcessDays: ''
  });

  const [isLoading, setIsLoading] = useState(false);
  const loadSettings = async () => {
    try {
      const data = await Admin.getSettings();
      setSettings(data);
    } catch (error) {
      console.log('error fetching ');
    }
  };
  useEffect(() => {
    loadSettings();
  }, []);
  const updateSettings = async newSettings => {
    try {
      setIsLoading(true);
      const data = await Admin.updateSettings(newSettings);
      setSettings(data);
      setIsLoading(false);
      toast.success('Settings saved');
    } catch (error) {
      setIsLoading(false);
      toast.error('Error saving');
    }
  };
  const value = { settings, isLoading, updateSettings };

  return (
    <AdminSettingsContext.Provider value={value}>
      {children}
    </AdminSettingsContext.Provider>
  );
}

export function AdminSettingsConsumer({ children }) {
  return (
    <AdminSettingsContext.Consumer>{children}</AdminSettingsContext.Consumer>
  );
}

export function useAdminSettings() {
  try {
    return useContext(AdminSettingsContext);
  } catch (error) {
    return null;
  }
}

AdminSettingsProvider.propTypes = {
  children: PropTypes.element
};

AdminSettingsConsumer.propTypes = {
  children: PropTypes.element
};
