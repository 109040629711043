import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';

import {
  Avatar,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles
} from '@material-ui/core';
import getInitials from 'src/utils/getInitials';
import qs from 'qs';
import { useLocation, useNavigate } from 'react-router';
import TableLoader from 'src/components/TableLoader';

const useStyles = makeStyles(theme => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  },
  tableRow: {
    cursor: 'pointer'
  }
}));

const Results = ({ className, admins, pagination, isLoading, ...rest }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const classes = useStyles();

  const [queries, setQueries] = useState({});

  useEffect(() => {
    const { search } = location;
    setQueries(qs.parse(search, { ignoreQueryPrefix: true }));
  }, [location]);

  const handleLimitChange = event => {
    const { pathname } = location;
    const newQueries = { ...queries, rows: event.target.value };
    navigate(`${pathname}?${qs.stringify(newQueries)}`);
  };

  const handlePageChange = (event, newPage) => {
    const { pathname } = location;
    const newQueries = { ...queries, pageNo: newPage + 1 };
    navigate(`${pathname}?${qs.stringify(newQueries)}`);
  };

  const handleRowClick = (event, id) => {
    navigate(`/app/admins/${id}`);
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          {isLoading ? (
            <TableLoader />
          ) : (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Phone</TableCell>
                  <TableCell>Type</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {admins.map(admin => (
                  <TableRow
                    hover
                    key={admin.id}
                    onClick={event => {
                      handleRowClick(event, admin.id);
                    }}
                    className={classes.tableRow}
                  >
                    <TableCell>
                      <Box alignItems="center" display="flex">
                        <Avatar
                          className={classes.avatar}
                          src={admin.avatarUrl}
                        >
                          {getInitials(`${admin.firstName}  ${admin.lastName}`)}
                        </Avatar>
                        <Typography color="textPrimary" variant="body1">
                          {`${admin.firstName}  ${admin.lastName}`}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell>{admin.email}</TableCell>
                    <TableCell>
                      {admin.active ? 'Active' : 'Unactive'}
                    </TableCell>
                    <TableCell>{`${admin.mobileCode}${admin.mobile}`}</TableCell>
                    <TableCell>
                      {admin.role === 'super' ? 'Super Admin' : 'Admin'}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={pagination.totalCount}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleLimitChange}
        page={pagination.page}
        rowsPerPage={pagination.rowsPerPage}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  admins: PropTypes.array.isRequired,
  pagination: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired
};

export default Results;
