const setOpen = (isOpen) => {
  return { type: 'alertSnackBar/open', payload: isOpen };
};

const setMessage = (message) => {
  return { type: 'alertSnackBar/message', payload: message };
};

const setSeverity = (severity) => {
  return { type: 'alertSnackBar/severity', payload: severity };
};

export default {
  setOpen,
  setMessage,
  setSeverity
};
