import { call, put, takeEvery } from 'redux-saga/effects';
import User from 'src/api/user';
import {
  userActionList,
  userActivityList,
  axiosActionList
} from '../action-lists';

// get users activities
// ----------Desmond-------------
function* getUsersActivitiesWorker(action) {
  try {
    const res = yield call(User.getActivityLog, action.payload);
    yield put({ type: userActivityList.userActivity, payload: res.data });
  } catch (err) {
    yield put({
      type: axiosActionList.axiosFailed,
      message: err.message
    });
  }
}

export function* getUsersActivitiesWatcher() {
  yield takeEvery(
    axiosActionList.axiosGetActivityLog,
    getUsersActivitiesWorker
  );
}
// ----------Desmond-------------

function* getUsersWorker(action) {
  try {
    const res = yield call(User.getUsers, action.payload);
    yield put({ type: userActionList.updateUsers, payload: res.data });
  } catch (err) {
    yield put({ type: axiosActionList.axiosFailed, message: err.message });
  }
}

export function* getUsersWatcher() {
  yield takeEvery(axiosActionList.axiosGetUsers, getUsersWorker);
}

function* getUserWorker(id) {
  try {
    const res = yield call(User.getUser, id);
    yield put({ type: userActionList.getUser, payload: res });
  } catch (err) {
    yield put({ type: axiosActionList.axiosFailed, message: err.message });
  }
}

export function* getUserWatcher() {
  yield takeEvery('user/axios/get', getUserWorker);
}

export default getUsersWatcher;
