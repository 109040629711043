import React, { useEffect, useState } from 'react';
import {
  Box,
  CircularProgress,
  Container,
  makeStyles,
  colors,
  ListItem,
  ListItemText,
  Divider,
  Card,
  CardContent,
  Grid,
  List,
  Typography
} from '@material-ui/core';
import Page from 'src/components/Page';
import { useParams } from 'react-router';
import toast from 'react-hot-toast';
import Wallet from 'src/api/wallet';
import formatPrice from 'src/utils/formatPrice';
import moment from 'moment';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },

  infoItem: {
    '&:not(:last-child)': {
      borderBottom: `1px solid ${colors.grey[100]}`
    }
  },
  label: {
    fontWeight: 'bold'
  },

  grid: {
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column-reverse'
    }
  },

  timelineRoot: {
    paddingLeft: 0
  },
  itemRoot: {
    '&::before': {
      display: 'none'
    }
  },
  name: {
    color: colors.grey[600],
    marginBottom: 4
  },
  address: {
    fontWeight: 700
  }
}));

export default function WalletTransactionDetails() {
  const classes = useStyles();
  const { id } = useParams();
  const [isLoadingDetails, setIsLoadingDetails] = useState(true);
  const [transactionDetails, setTransactionDetails] = useState(null);

  const fetchWalletTransaction = async () => {
    try {
      const res = await Wallet.getWalletTransactionDetails(id);
      setTransactionDetails(res.data);
      setIsLoadingDetails(false);
      console.log('res>>', res);
      console.log('res transac>>>', res.data);
    } catch (e) {
      toast.error(e.message);
      console.log('unable to fetch transaction details>>', e);
    }
  };

  // console.log('wallet id>>', id);
  // console.log('transaction details>>', transactionDetails);
  useEffect(() => {
    fetchWalletTransaction();
  }, []);

  if (isLoadingDetails) {
    return (
      <Page className={classes.root}>
        <Box textAlign="center" py={10}>
          <CircularProgress />
        </Box>
      </Page>
    );
  }

  const Bal = transactionDetails.balance / 100;
  const previousBal = transactionDetails.previousBalance / 100;
  const transcAmount = transactionDetails.amount / 100;

  return (
    <Page title="Transaction details">
      <Container>
        <Grid
          container
          direction="row"
          justifyContent="center"
          className={classes.grid}
          spacing={3}
        >
          <Grid item xs={12} md={8}>
            <Box mb={4}>
              <Card>
                <CardContent>
                  <List>
                    <ListItem className={classes.infoI}>
                      <Typography className={classes.label}>
                        Transaction Details
                      </Typography>
                    </ListItem>
                    <Divider />
                    <ListItem className={classes.infoI}>
                      <ListItemText>
                        <Grid container>
                          <Grid item lg={4}>
                            <Typography className={classes.label}>
                              Customer`s FullName:
                            </Typography>
                          </Grid>
                          <Grid item lg={8}>
                            <Box className={classes.value}>
                              {transactionDetails?.userDetails?.firstName}{' '}
                              {transactionDetails?.userDetails?.lastName}
                            </Box>
                          </Grid>
                        </Grid>
                      </ListItemText>
                    </ListItem>
                    <Divider />
                    <ListItem className={classes.infoI}>
                      <ListItemText>
                        <Grid container>
                          <Grid item lg={4}>
                            <Typography className={classes.label}>
                              Customer`s Email:
                            </Typography>
                          </Grid>
                          <Grid item lg={8}>
                            <Box className={classes.value}>
                              {transactionDetails?.userDetails?.email}
                            </Box>
                          </Grid>
                        </Grid>
                      </ListItemText>
                    </ListItem>
                    <Divider />
                    <ListItem className={classes.infoI}>
                      <ListItemText>
                        <Grid container>
                          <Grid item lg={4}>
                            <Typography className={classes.label}>
                              Previous Balance:
                            </Typography>
                          </Grid>
                          <Grid item lg={8}>
                            <Box className={classes.value}>
                              {formatPrice(
                                previousBal,
                                transactionDetails?.amountCurrency
                              )}
                            </Box>
                          </Grid>
                        </Grid>
                      </ListItemText>
                    </ListItem>
                    <Divider />
                    <ListItem className={classes.infoI}>
                      <ListItemText>
                        <Grid container>
                          <Grid item lg={4}>
                            <Typography className={classes.label}>
                              Transaction Amount:
                            </Typography>
                          </Grid>
                          <Grid item lg={8}>
                            <Box className={classes.value}>
                              {formatPrice(
                                transcAmount,
                                transactionDetails?.amountCurrency
                              )}
                            </Box>
                          </Grid>
                        </Grid>
                      </ListItemText>
                    </ListItem>
                    <Divider />
                    <ListItem className={classes.infoI}>
                      <ListItemText>
                        <Grid container>
                          <Grid item lg={4}>
                            <Typography className={classes.label}>
                              Balance:
                            </Typography>
                          </Grid>
                          <Grid item lg={8}>
                            <Box className={classes.value}>
                              {formatPrice(
                                Bal,
                                transactionDetails?.amountCurrency
                              )}
                            </Box>
                          </Grid>
                        </Grid>
                      </ListItemText>
                    </ListItem>
                    <Divider />
                    <ListItem className={classes.infoI}>
                      <ListItemText>
                        <Grid container>
                          <Grid item lg={4}>
                            <Typography className={classes.label}>
                              Transaction Description:
                            </Typography>
                          </Grid>
                          <Grid item lg={8}>
                            <Box className={classes.value}>
                              {transactionDetails?.summary}
                            </Box>
                          </Grid>
                        </Grid>
                      </ListItemText>
                    </ListItem>
                    <Divider />
                    <ListItem className={classes.infoI}>
                      <ListItemText>
                        <Grid container>
                          <Grid item lg={4}>
                            <Typography className={classes.label}>
                              Transaction Type:
                            </Typography>
                          </Grid>
                          <Grid item lg={8}>
                            <Box className={classes.value}>
                              {transactionDetails.transactionType === 'CR'
                                ? 'CREDIT'
                                : 'DEBIT'}
                            </Box>
                          </Grid>
                        </Grid>
                      </ListItemText>
                    </ListItem>
                    <Divider />
                    <ListItem className={classes.infoI}>
                      <ListItemText>
                        <Grid container>
                          <Grid item lg={4}>
                            <Typography className={classes.label}>
                              Transaction Status:
                            </Typography>
                          </Grid>
                          <Grid item lg={8}>
                            <Box className={classes.value}>
                              {transactionDetails?.transactionStatus}
                            </Box>
                          </Grid>
                        </Grid>
                      </ListItemText>
                    </ListItem>
                    <Divider />
                    <ListItem className={classes.infoI}>
                      <ListItemText>
                        <Grid container>
                          <Grid item lg={4}>
                            <Typography className={classes.label}>
                              Retried Admin Name:
                            </Typography>
                          </Grid>
                          <Grid item lg={8}>
                            <Box className={classes.value}>
                              {transactionDetails?.retryByAdmin?.name
                                ? transactionDetails?.retryByAdmin?.name
                                : 'none'}
                            </Box>
                          </Grid>
                        </Grid>
                      </ListItemText>
                    </ListItem>
                    <Divider />
                    <ListItem className={classes.infoI}>
                      <ListItemText>
                        <Grid container>
                          <Grid item lg={4}>
                            <Typography className={classes.label}>
                              Retried Admin Role:
                            </Typography>
                          </Grid>
                          <Grid item lg={8}>
                            <Box className={classes.value}>
                              {transactionDetails?.retryByAdmin?.role
                                ? transactionDetails?.retryByAdmin?.role
                                : 'none'}
                            </Box>
                          </Grid>
                        </Grid>
                      </ListItemText>
                    </ListItem>
                    <Divider />
                    <ListItem className={classes.infoI}>
                      <ListItemText>
                        <Grid container>
                          <Grid item lg={4}>
                            <Typography className={classes.label}>
                              Retried Admin Id:
                            </Typography>
                          </Grid>
                          <Grid item lg={8}>
                            <Box className={classes.value}>
                              {transactionDetails?.retryByAdmin?.id ? (
                                <Link to="/app/profile">
                                  {transactionDetails?.retryByAdmin?.id}
                                </Link>
                              ) : (
                                'none'
                              )}
                            </Box>
                          </Grid>
                        </Grid>
                      </ListItemText>
                    </ListItem>
                    <Divider />
                    <ListItem className={classes.infoI}>
                      <ListItemText>
                        <Grid container>
                          <Grid item lg={4}>
                            <Typography className={classes.label}>
                              Cancelled Admin Name:
                            </Typography>
                          </Grid>
                          <Grid item lg={8}>
                            <Box className={classes.value}>
                              {transactionDetails?.cancelledBy?.name
                                ? transactionDetails?.cancelledBy?.name
                                : 'none'}
                            </Box>
                          </Grid>
                        </Grid>
                      </ListItemText>
                    </ListItem>
                    <Divider />
                    <ListItem className={classes.infoI}>
                      <ListItemText>
                        <Grid container>
                          <Grid item lg={4}>
                            <Typography className={classes.label}>
                              Cancelled Admin Role:
                            </Typography>
                          </Grid>
                          <Grid item lg={8}>
                            <Box className={classes.value}>
                              {transactionDetails?.cancelledBy?.role
                                ? transactionDetails?.cancelledBy?.role
                                : 'none'}
                            </Box>
                          </Grid>
                        </Grid>
                      </ListItemText>
                    </ListItem>
                    <Divider />
                    <ListItem className={classes.infoI}>
                      <ListItemText>
                        <Grid container>
                          <Grid item lg={4}>
                            <Typography className={classes.label}>
                              Cancelled Admin Id:
                            </Typography>
                          </Grid>
                          <Grid item lg={8}>
                            <Box className={classes.value}>
                              {transactionDetails?.cancelledBy?.id ? (
                                <Link to="/app/profile">
                                  {transactionDetails?.cancelledBy?.id}
                                </Link>
                              ) : (
                                'none'
                              )}
                            </Box>
                          </Grid>
                        </Grid>
                      </ListItemText>
                    </ListItem>
                    <ListItem className={classes.infoI}>
                      <ListItemText>
                        <Grid container>
                          <Grid item lg={4}>
                            <Typography className={classes.label}>
                              Transaction Time:
                            </Typography>
                          </Grid>
                          <Grid item lg={8}>
                            <Box className={classes.value}>
                              {moment(transactionDetails?.createdAt).format(
                                'DD/MM/YYYY'
                              )}
                            </Box>
                          </Grid>
                        </Grid>
                      </ListItemText>
                    </ListItem>
                    <Divider />
                    <ListItem className={classes.infoI}>
                      <ListItemText>
                        <Grid container>
                          <Grid item lg={4}>
                            <Typography className={classes.label}>
                              Wallet Id:
                            </Typography>
                          </Grid>
                          <Grid item lg={8}>
                            <Box className={classes.value}>
                              <Link
                                to={`/app/customers/wallet/${transactionDetails?.walletId}`}
                              >
                                {transactionDetails?.walletId}
                              </Link>
                            </Box>
                          </Grid>
                        </Grid>
                      </ListItemText>
                    </ListItem>
                    <Divider />
                    <ListItem className={classes.infoI}>
                      <ListItemText>
                        <Grid container>
                          <Grid item lg={4}>
                            <Typography className={classes.label}>
                              User Id:
                            </Typography>
                          </Grid>
                          <Grid item lg={8}>
                            <Box className={classes.value}>
                              <Link
                                to={`/app/customers/${transactionDetails?.userDetails?.userId}`}
                              >
                                {transactionDetails?.userDetails?.userId}
                              </Link>
                            </Box>
                          </Grid>
                        </Grid>
                      </ListItemText>
                    </ListItem>
                  </List>
                </CardContent>
              </Card>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
