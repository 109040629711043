import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  makeStyles,
  Button,
  colors
} from '@material-ui/core';
import toast from 'react-hot-toast';
import EditableField from '../customer/CustomerView/EditableField';

const useStyles = makeStyles(() => ({
  root: {
    marginBottom: 20
  },
  cancelBtn: {
    color: colors.grey[600],
    marginRight: 10,
    '&:hover': {
      background: colors.grey[100]
    }
  }
}));

const ProfileDetails = ({
  className,
  customer,
  formData,
  onChange,
  isUpdating,
  onSave,
  handleVerification,
  ...rest
}) => {
  const classes = useStyles();
  const [isEditMode, setIsEditMode] = React.useState(false);

  const toggleEditMode = () => setIsEditMode(mode => !mode);
  const save = e => {
    e.preventDefault();
    // toggle mode when done saving
    onSave().then(() => {
      toggleEditMode();
      toast.success('Profile updated');
    });
  };

  return (
    <form
      autoComplete="off"
      className={clsx(classes.root, className)}
      {...rest}
      onSubmit={save}
      disabled={isUpdating}
    >
      <Card>
        <CardHeader
          title="Profile"
          action={
            <div>
              {isEditMode ? (
                <div>
                  <Button
                    disabled={isUpdating}
                    color="default"
                    type="button"
                    onClick={toggleEditMode}
                    className={classes.cancelBtn}
                  >
                    Cancel
                  </Button>
                  <Button
                    disabled={isUpdating}
                    color="secondary"
                    variant="outlined"
                    type="submit"
                  >
                    {isUpdating ? 'Saving...' : 'Save'}
                  </Button>
                </div>
              ) : (
                <Button type="button" onClick={toggleEditMode}>
                  Edit
                </Button>
              )}
            </div>
          }
        />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <EditableField
                isEditMode={isEditMode}
                fullWidth
                helperText="Please specify the first name"
                label="First name"
                name="firstName"
                onChange={onChange}
                value={formData.firstName || ''}
                variant="outlined"
                required
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <EditableField
                isEditMode={isEditMode}
                fullWidth
                label="Last name"
                name="lastName"
                onChange={onChange}
                value={formData.lastName || ''}
                variant="outlined"
                required
              />
            </Grid>

            <Grid item md={6} xs={12}>
              <EditableField
                isEditMode={isEditMode}
                fullWidth
                label="Phone Number"
                name="mobile"
                onChange={onChange}
                value={
                  isEditMode
                    ? `${formData?.mobile || ''}`
                    : `${formData?.mobileCode + formData?.mobile || ''}`
                }
                variant="outlined"
                required
              />
            </Grid>

            <Grid item md={6} xs={12}>
              <EditableField
                isEditMode={isEditMode}
                fullWidth
                label="email"
                name="email"
                onChange={onChange}
                value={formData.email || ''}
                variant="outlined"
                required
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
      </Card>
    </form>
  );
};

ProfileDetails.propTypes = {
  className: PropTypes.string,
  formData: PropTypes.object,
  customer: PropTypes.object,
  onChange: PropTypes.func,
  onSave: PropTypes.func,
  isUpdating: PropTypes.bool,
  handleVerification: PropTypes.func
};

export default ProfileDetails;
