import 'react-perfect-scrollbar/dist/css/styles.css';
import React, { useEffect } from 'react';
import { ThemeProvider } from '@material-ui/core';
import { QueryClient, QueryClientProvider } from 'react-query';
import GlobalStyles from 'src/components/GlobalStyles';
import 'src/mixins/chartjs';
import theme from 'src/theme';
import { Toaster } from 'react-hot-toast';
import { Provider } from 'react-redux';
import store from './store/store';
import Views from './views';
import runOneSignal from './services/oneSignal';

// Create a query client
const queryClient = new QueryClient();

const App = () => {
  useEffect(() => {
    runOneSignal();
  });
  return (
    <>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <GlobalStyles />
          <QueryClientProvider client={queryClient}>
            <Views />
          </QueryClientProvider>
        </Provider>
      </ThemeProvider>
      <Toaster
        position="bottom-right"
        reverseOrder
        toastOptions={{
          style: {
            minWidth: 250,
            fontSize: 18,
            fontWeight: 400
          }
        }}
      />
    </>
  );
};

export default App;
