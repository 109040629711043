import React from 'react';
import { Navigate } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import AccountView from 'src/views/account/AccountView';
import CustomerListView from 'src/views/customer/CustomerListView';
import DriverListView from 'src/views/customer/DriverListView';
import DashboardView from 'src/views/reports/DashboardView';
import LoginView from 'src/views/auth/LoginView';
import NotFoundView from 'src/views/errors/NotFoundView';
import ProductListView from 'src/views/product/ProductListView';
import AdminCreateView from 'src/views/admin/AdminCreateView';
import RegisterView from 'src/views/auth/RegisterView';
import SettingsView from 'src/views/settings/SettingsView';
import IsLoggedIn from './layouts/AuthLayout/IsLoggedIn';
import IsLoggedOut from './layouts/AuthLayout/IsLoggedOut';
import AdminListView from './views/admin/AdminListView';
import CustomerView from './views/customer/CustomerView';
import NewMessage from './views/message/NewMessage';
import AdminView from './views/admin/AdminView';
import ActivityLog from './views/ActivityLog';
import TripRoutes from './views/trip';
import RefundRequestRoutes from './views/refund';
import Policy from './Policy';
import TermsAndCondition from './TermsAndCondition';
import AdminProfile from './views/AdminProfile';
import BookingsList from './views/BookingsList';
import CustomerBookings from './views/customer/CustomerBookings';
import CustomerTrips from './views/customer/CustomerTrips';
import CustomerWallet from './views/customer/CustomerWallet';
import WalletWithdrawals from './views/wallet/WalletWithdrawals';
import WalletTransactions from './views/wallet/WalletTransactions';
import ResetView from './views/auth/ResetView';
import DeletionRequest from './views/deletion-requests/index';
import WalletTransactionDetails from './views/wallet/WalletTransactions/TransactionDetails';
import WalletWithDrawalDetails from './views/wallet/WalletWithdrawals/WithDrawalDetails';
import CustomerReportsView from './views/customers-reports';
import ReportDetails from './views/customers-reports/CustomerReportsDetails';
import BookingDetails from './views/BookingsList/BookingDetails';
import TripLocations from './views/trip/locations';

const routes = [
  {
    path: 'app',
    element: <IsLoggedOut component={DashboardLayout} />,
    children: [
      { path: 'account', element: <AccountView /> },
      { path: 'customers', element: <CustomerListView /> },
      { path: 'drivers', element: <DriverListView /> },
      { path: 'customers/:id', element: <CustomerView /> },
      { path: 'customers/bookings/:id', element: <CustomerBookings /> },
      { path: 'customers/trips/:id', element: <CustomerTrips /> },
      { path: 'customers/wallet/:id', element: <CustomerWallet /> },
      { path: 'wallet/withdrawals', element: <WalletWithdrawals /> },
      { path: 'wallet/transactions', element: <WalletTransactions /> },
      {
        path: 'wallet/transactions/:id',
        element: <WalletTransactionDetails />
      },
      {
        path: 'wallet/withdrawals/:id',
        element: <WalletWithDrawalDetails />
      },
      { path: 'admins', element: <AdminListView /> },
      { path: 'admins/new', element: <AdminCreateView /> },
      { path: 'admins/:id', element: <AdminView /> },
      { path: 'profile', element: <AdminProfile /> },
      { path: 'bookings', element: <BookingsList /> },
      { path: 'dashboard', element: <DashboardView /> },
      { path: 'products', element: <ProductListView /> },
      { path: 'settings', element: <SettingsView /> },
      { path: 'message/new', element: <NewMessage /> },
      { path: 'activity-log', element: <ActivityLog /> },
      { path: 'deletion-request', element: <DeletionRequest /> },
      { path: 'reports', element: <CustomerReportsView /> },
      { path: 'reports/:id', element: <ReportDetails /> },
      { path: 'bookings/:id', element: <BookingDetails /> },
      { path: 'trips/locations', element: <TripLocations /> },

      ...TripRoutes,
      ...RefundRequestRoutes,
      { path: '*', element: <Navigate replace to="/404" /> }
    ]
  },
  { path: 'policy', element: <Policy /> },
  { path: 'terms-and-condition', element: <TermsAndCondition /> },
  {
    path: '/',
    element: <IsLoggedIn component={MainLayout} />,
    children: [
      { path: 'login', element: <LoginView /> },
      { path: 'reset/:id', element: <ResetView /> },
      { path: 'register', element: <RegisterView /> },
      { path: '/', element: <Navigate replace to="/app/dashboard" /> },
      { path: '*', element: <Navigate replace to="/404" /> }
    ]
  },

  {
    path: '404',
    element: <NotFoundView />
  }
];

export default routes;
