const axiosActionList = {
  axiosFailed: 'axios/failed',
  axiosGetUsers: 'userList/axios/get',
  axiosGetUser: 'user/axios/get',
  axiosGetAdmins: 'adminList/axios/get',
  axiosGetAdmin: 'admin/axios/get',
  axiosGetActivityLog: 'admin/activities'
};

export default axiosActionList;
