import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  // CardHeader,
  Divider,
  Grid,
  makeStyles,
  Typography,
  Link,
  Box,
  colors,
  Button,
  TextField
} from '@material-ui/core';
import UnverifiedIcon from 'src/components/icons/UnverifiedIcon';
import CustomDialog from 'src/components/CustomDialog';
// import DateInput from 'src/components/DateInput';
import toast from 'react-hot-toast';
import VerifiedIcon from 'src/components/icons/VerifiedIcon';
import { useParams } from 'react-router';
import Admin from 'src/api/admin';
import moment from 'moment';
import NativeSelects from 'src/components/SelectField';
import UnverifyDialog from 'src/components/UnverifyDialog';
import RetryDialog from 'src/components/RetryDialog';

// import VerifiedIcon from 'src/components/icons/VerifiedIcon';
// import UnverifiedIcon from 'src/components/icons/UnverifiedIcon';
// import ConfirmationDialog from 'src/components/ConfirmationDialog';

const useStyles = makeStyles(() => ({
  trueBtn: {
    backgroundColor: colors.green[300],
    fontSize: '9px',
    color: 'white',
    '&:hover': {
      backgroundColor: colors.green[500]
    }
  },
  falseBtn: {
    backgroundColor: colors.red[300],
    color: 'white',
    fontSize: '9px',
    '&:hover': {
      backgroundColor: colors.red[500]
    }
  },

  retryBtn: {
    color: 'black',
    backgroundColor: colors.yellow['400'],
    fontSize: '9px',
    '&:hover': {
      backgroundColor: colors.yellow['400']
    }
  },

  errorMssg: {
    color: colors.red[500]
  },

  successMssg: {
    color: 'green'
  },

  root: {
    marginBottom: 20,
    width: '100%'
  }
}));

const DetailsText = ({ title, type }) => {
  return (
    <>
      <Typography style={{ color: colors.grey[500] }} variant="h6">
        {title}
      </Typography>

      <Typography>{type}</Typography>
    </>
  );
};

const VehicleDetails = ({
  className,
  vehicle,
  customer,
  handleVerification,
  openCarUnverifyDialog,
  isPlateNumberVerified,
  setOpenCarUnVerifyDialog,
  handleUnverifyCarVerification,
  setIsPlateNumberVerified,
  setVehicle,
  ...rest
}) => {
  // const today = new Date();
  // const dateYear = today.getFullYear();
  // const month = String(today.getMonth() + 1).padStart(2, '0');
  // const day = String(today.getDate()).padStart(2, '0');
  // console.log(dateYear, month, day);
  // const todayDate = `${day}/${month}/${dateYear}`;
  const classes = useStyles();
  const { id } = useParams();
  const [dialogMssg, setDialogMssg] = useState({ message: '', title: '' });
  const [openVerifyDialog, setOpenVerifyDialog] = useState(false);
  // const [openUnVerifyDialog, setOpenUnVerifyDialog] = useState(false);
  const [openRetryDialog, setOpenRetryDialog] = useState(false);
  const [isRetrying, setIsRetrying] = useState(false);

  // const [customerData, setCustomerData] = useState(customer);
  // const [vehicleData, setVehicleData] = useState(vehicle);
  // const [isPlateNumberVerified, setIsPlateNumberVerified] = useState(
  //   vehicleData?.isVerified
  // );
  const [date, setDate] = useState(vehicle?.plateNumberExpiryDate);
  // const [customDate, setCustomDate] = useState(
  //   vehicleData.plateNumberExpiryDate
  // );

  const [selectValue, setSelectValue] = useState();
  const reasonOptions = [
    'Car verification failed due to no Plate Number uploaded!',
    'Car verification failed due to Plate Number Date Expired!',
    'Car verification failed due to no Car Image uploaded!',
    'Car verification failed due to Car Plate Number Image not clear!'
  ];

  // console.log('vehicle data>>>', vehicleData);
  // console.log('selected date>>>', date);

  const handleSelect = event => {
    setSelectValue(event);
  };
  // const dateFormat = moment(date).format('DD/MM/YYYY');

  const handleDateChange = event => {
    const { value } = event.target;
    setDate(value);
    // setCustomDate(moment(date, 'DD/MM/YYYY').format('MMMM Do YYYY'));
    // console.log('date', formattedDate);
  };

  // {moment(row.refundDate).format('DD/MM/YYYY')}
  const formattedDate = moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY');
  const momentDate = moment(formattedDate, 'DD/MM/YYYY').format('MMMM Do YYYY');

  // console.log('moment date>>', momentDate);

  const handleOpenVerifyDialog = () => {
    setOpenVerifyDialog(prev => !prev);
  };

  // const handleOpenUnVerifyDialog = () => {
  //   setOpenUnVerifyDialog(prev => !prev);
  // };

  const handleOpenRetryDialog = () => {
    setOpenRetryDialog(prev => !prev);
  };

  const handleVerifyDialog = () => {
    handleOpenVerifyDialog();
    setDialogMssg({
      message:
        "Please Verify by inputing the accurate Driver plate number expiry date below and press ok to verify! Note this will enable Driver's access to publish ride!",
      title: "Are you sure you want to verify this Driver's Car?"
    });
  };

  const handleUnVerifyDialog = () => {
    setOpenCarUnVerifyDialog(prev => !prev);
    setDialogMssg({
      message: "Note this will disable Driver's access to publish ride!",
      title: "Are you sure you want to unverify this Driver's Car?"
    });
  };

  const handleRetryDialog = () => {
    handleOpenRetryDialog();
    setDialogMssg({
      message:
        "Note this will restart Driver's Car verification process for this Driver!",
      title: "Are you sure you want to retry this Driver's Car verification?"
    });
  };
  const handleVehicleVerification = async (userId, payload) => {
    try {
      const res = await Admin.verifyVehicle(userId, payload);

      if (res) {
        setVehicle(res.data.data);
        // console.log('verify res>>>', res.data.data);

        toast.success('Car Verification successful!');
        setIsPlateNumberVerified(true);
        handleOpenVerifyDialog();
      }
    } catch (e) {
      console.log('errors', e);
      toast.error(e.message);
      handleOpenVerifyDialog();
    }
  };

  const handleUnverifyDriverVerification = async (userId, payload) => {
    try {
      const res = await Admin.verifyVehicle(userId, payload);
      if (res) {
        setVehicle(res.data.data);
        // console.log('unverify res>>>', res);
        toast.success('Car Verification Unverified!');
        setIsPlateNumberVerified(false);
        setOpenCarUnVerifyDialog(prev => !prev);
      }
    } catch (e) {
      toast.error(e.message);
      setOpenCarUnVerifyDialog(prev => !prev);
    }
  };

  const handleRetryCarVerification = async userId => {
    setIsRetrying(true);
    try {
      const res = await Admin.retryCarVerication(userId);
      if (res) {
        // console.log('unverify res>>>', res);
        toast.success('Car retry verification process successful!');
        setIsRetrying(false);
        handleOpenRetryDialog();
      }
    } catch (e) {
      setIsRetrying(false);
      // toast.error(e.message);
      console.log('error>>', e.message);
      handleOpenRetryDialog();
    }
  };

  // useEffect(() => {
  //   if (customer && vehicle) {
  //     setVehicleData(vehicle);
  //     // setCustomDate(moment(date, 'DD/MM/YYYY').format('MMMM Do YYYY'));
  //     // setCustomerData(customer);maur
  //   }
  // }, [customer, vehicle]);

  // // console.log('customer>>>', customer);
  // console.log('vehicle state>>', vehicleData);
  // console.log('vehicle props>>>', vehicle);

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Card>
        {openVerifyDialog && (
          <CustomDialog
            title={dialogMssg.title}
            message={dialogMssg.message}
            open={openVerifyDialog}
            handleDialogClose={handleOpenVerifyDialog}
            action={() =>
              handleVehicleVerification(id, {
                vehicleId: vehicle?.id,
                plateNumberExpiryDate: date,
                isCarVerify: true
              })
            }
            component={
              // <DateInput
              //   value={date}
              //   onChange={handleDateChange}
              //   label="Select Date"
              //   required
              // />
              <TextField
                // variant="inline"

                // format={dateFormat}
                type="date"
                label="Select Date"
                InputLabelProps={{
                  shrink: true
                }}
                value={date}
                onChange={handleDateChange}
                // KeyboardButtonProps={{
                //   'aria-label': 'change date'
                // }}
              />
            }
          />
        )}
        {openCarUnverifyDialog && (
          <UnverifyDialog
            title={dialogMssg.title}
            message={dialogMssg.message}
            open={openCarUnverifyDialog}
            handleDialogClose={() => setOpenCarUnVerifyDialog(prev => !prev)}
            action={() =>
              handleUnverifyDriverVerification(id, {
                vehicleId: vehicle.id,
                carVerificationErrorMessage: selectValue,
                isCarVerify: false
              })
            }
            component={
              <NativeSelects
                value={selectValue}
                selectHandler={handleSelect}
                selectOptions={reasonOptions}
                label="Select Error Message:"
              />
            }
          />
        )}

        {openRetryDialog && (
          <RetryDialog
            title={dialogMssg.title}
            message={dialogMssg.message}
            open={openRetryDialog}
            handleDialogClose={handleOpenRetryDialog}
            action={() => handleRetryCarVerification(id)}
          />
        )}
        <Divider />
        <CardContent>
          <Box sx={{ display: 'flex', width: '100%' }}>
            <Box
              sx={{
                mr: '1rem',
                display: 'flex',
                alignItems: 'center',
                width: '20%'
              }}
            >
              <Typography>Car verification</Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                width: '80%',
                gap: 5,
                alignItems: 'center'
              }}
            >
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  gap: 3,
                  justifyContent: 'start'
                }}
              >
                <Button
                  variant="contained"
                  className={classes.trueBtn}
                  onClick={handleVerifyDialog}
                >
                  verify
                </Button>
                <Button
                  variant="contained"
                  className={classes.falseBtn}
                  onClick={handleUnVerifyDialog}
                >
                  unverify
                </Button>
              </Box>
              <Box
                sx={{ width: '100%', display: 'flex', justifyContent: 'end' }}
              >
                <Button
                  variant="contained"
                  className={classes.retryBtn}
                  onClick={handleRetryDialog}
                >
                  {isRetrying ? 'retrying...' : 'retry'}
                </Button>
              </Box>
            </Box>
          </Box>
        </CardContent>
        <Divider />
        <CardContent>
          {vehicle?.id ? (
            <>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <DetailsText title="Car Make" type={vehicle?.make} />
                </Grid>
                <Grid item md={6} xs={12}>
                  <DetailsText title="Car Model" type={vehicle?.model} />
                </Grid>
                <Grid item md={6} xs={12}>
                  <DetailsText title="Car Color" type={vehicle?.color} />
                </Grid>
                <Grid item md={6} xs={12}>
                  <DetailsText
                    title="Car Plate Number"
                    type={vehicle?.licensePlate}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <DetailsText
                    title="Plate Number Expiring Date"
                    type={momentDate}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <DetailsText title="Car Type" type={vehicle?.type} />
                </Grid>
                <Grid item md={6} xs={12}>
                  <DetailsText title="Car Year" type={vehicle?.year} />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography variant="h6">Car Image</Typography>
                  <Typography>
                    {vehicle?.imageUrls[0] ? (
                      <Link
                        href={vehicle?.imageUrls[0]}
                        target="_blank"
                        rel="noopener"
                        style={{ color: 'green' }}
                      >
                        View Car Image
                      </Link>
                    ) : (
                      'Not car image uploaded yet'
                    )}
                  </Typography>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography variant="h6">Verification Status</Typography>
                  <Typography>
                    {isPlateNumberVerified ? (
                      <VerifiedIcon />
                    ) : (
                      <UnverifiedIcon />
                    )}
                  </Typography>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography variant="h6">Verification Message</Typography>
                  <Typography
                    className={
                      isPlateNumberVerified
                        ? classes.successMssg
                        : classes.errorMssg
                    }
                  >
                    {isPlateNumberVerified
                      ? 'Car Verication Successful!'
                      : vehicle.carVerificationErrorMessage}
                  </Typography>
                </Grid>
              </Grid>
            </>
          ) : (
            <Grid>
              <Typography>No car details yet</Typography>
            </Grid>
          )}
        </CardContent>
        <Divider />
      </Card>
    </div>
  );
};

VehicleDetails.propTypes = {
  className: PropTypes.string,
  vehicle: PropTypes.object,
  customer: PropTypes.object,
  handleVerification: PropTypes.func,
  openCarUnverifyDialog: PropTypes.func,
  isPlateNumberVerified: PropTypes.bool,
  setOpenCarUnVerifyDialog: PropTypes.bool,
  handleUnverifyCarVerification: PropTypes.func,
  setIsPlateNumberVerified: PropTypes.func,
  setVehicle: PropTypes.func
};

DetailsText.propTypes = {
  title: PropTypes.string,
  type: PropTypes.string
};

export default VehicleDetails;
