import React, { useEffect, useState } from 'react';
import Admin from 'src/api/admin';
import {
  Container,
  Box,
  Table,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
  Card,
  Button,
  CardHeader,
  colors,
  TablePagination
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Page from 'src/components/Page';
import TableLoader from 'src/components/TableLoader';
import moment from 'moment';
import toast from 'react-hot-toast';
import TripStatus from 'src/components/TripStatus';
import PerfectScrollbar from 'react-perfect-scrollbar';
import useQueryString from 'src/hooks/useQueryString';
import { useQuery } from 'react-query';
import CustomDialog from 'src/components/CustomDialog';

export default function DeletionRequestList() {
  const [query, setQueryString] = useQueryString();
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthorizing, setIsAuthorizing] = useState(false);
  const [paginationData, setPaginationData] = useState({});
  const [getRequestId, setGetRequestId] = useState(null);
  const [openRequestDialog, setOpenRequestDialog] = useState(false);

  const useStyles = makeStyles(() => ({
    deleteBtn: {
      color: colors.red['400'],
      borderColor: colors.red['400'],
      fontSize: '9px'
    },

    apprvingBtn: {
      color: colors.yellow['400'],
      borderColor: colors.yellow['400'],
      fontSize: '9px'
    },

    authBtn: {
      fontSize: '9px'
    },

    root: {},
    nowrap: {
      whiteSpace: 'nowrap'
    },

    pagStyle: {
      display: 'flex',
      justifyContent: 'end'
    }
  }));

  const classes = useStyles();

  const fetchDeletedUsers = async () => {
    const res = await Admin.getAllDeletionRequests();
    setPaginationData(res.pagination);
    console.log('pagination d>>>', paginationData);
  };

  const handleRowsPerPageChange = event => {
    setQueryString({ rows: event.target.value });
  };

  const handlePageChange = (event, newPage) => {
    setQueryString({ pageNo: newPage + 1 });
  };

  const { data = {}, refetch } = useQuery(
    ['delete-requests', query],
    () => Admin.getAllDeletionRequests(query),
    {
      keepPreviousData: true
    }
  );

  console.log('data from query>>>', data);
  console.log(' queriess>>>', query);

  const approveDeletionRequestHandler = async id => {
    setIsAuthorizing(true);
    try {
      const res = await Admin.approveDeletionRequest(id);
      console.log('res>>>', res);
      setIsAuthorizing(false);
      toast.success('User deleted successfully!');
      refetch();
    } catch (e) {
      console.log('error authorizing approve deletion', e);
    }
  };

  const handleDeleteUserRequestAction = () => {
    approveDeletionRequestHandler(getRequestId);
  };

  const handleOpenRequestDialog = () => {
    setOpenRequestDialog(prevState => !prevState);
  };
  // console.log('user deletion request id>>>', getRequestId);
  // console.log('dialog state>>>', openRequestDialog);

  const setLoading = () => {
    if (data?.recentRequests?.length > 0) {
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }
  };

  useEffect(() => {
    setLoading();
    fetchDeletedUsers();
  }, [isAuthorizing, data.recentRequests]);

  return (
    <Page title="Deletion Requests" className={classes.root}>
      <Container maxWidth={false}>
        <Box mb={4} component={Card}>
          <CardHeader title="Users Deletion Requests" />
        </Box>
        <Card>
          <PerfectScrollbar>
            <Box minWidth={1050}>
              {isLoading ? (
                <TableLoader />
              ) : (
                <Box>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>First Name</TableCell>
                        <TableCell>Last Name</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Gender</TableCell>
                        <TableCell>Mobile No</TableCell>
                        <TableCell>Ratings</TableCell>
                        <TableCell>Rider Status</TableCell>
                        <TableCell>Driver Status</TableCell>
                        <TableCell>Delete Status</TableCell>
                        <TableCell>Approve Deletion</TableCell>
                        <TableCell>Created At</TableCell>
                        <TableCell />
                      </TableRow>
                    </TableHead>
                    {data.recentRequests && (
                      <TableBody>
                        {data.recentRequests.map(request => (
                          <TableRow key={request.id}>
                            <TableCell>{request.user.firstName}</TableCell>
                            <TableCell>{request.user.lastName}</TableCell>
                            <TableCell>{request.user.email}</TableCell>
                            <TableCell>{request.user.gender}</TableCell>
                            <TableCell>
                              {request.user.mobileCode + request.user.mobile}
                            </TableCell>
                            <TableCell>
                              {request.user.rating.rating &&
                                request.user.rating.rating.toFixed(2)}
                              %
                            </TableCell>
                            <TableCell>
                              {request.user.verifiedDriver
                                ? 'Verified Diver'
                                : 'Not a Verified Driver'}
                            </TableCell>
                            <TableCell>
                              {request.user.verifiedUser
                                ? 'Verified User'
                                : 'Not a Verified User'}
                            </TableCell>
                            <TableCell>
                              <TripStatus status={request.status} />
                            </TableCell>
                            <TableCell>
                              {request.status === 'Authorized' && (
                                <Button
                                  size="small"
                                  variant="outlined"
                                  className={classes.authBtn}
                                  disabled={request.status && 'Authorized'}
                                >
                                  User Deletion Authorized
                                </Button>
                              )}

                              {request.status === 'Pending' &&
                                (isAuthorizing ? (
                                  <Button
                                    size="small"
                                    variant="outlined"
                                    className={classes.apprvingBtn}
                                  >
                                    Approving & Authorizing...
                                  </Button>
                                ) : (
                                  <Button
                                    size="small"
                                    variant="outlined"
                                    className={classes.deleteBtn}
                                    onClick={() => {
                                      setGetRequestId(request.id);
                                      handleOpenRequestDialog();
                                    }}
                                  >
                                    Approve User Deletion
                                  </Button>
                                ))}
                            </TableCell>
                            <TableCell>
                              {`${moment(request.createdAt).format(
                                'DD/MM/YYYY'
                              )}`}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    )}
                  </Table>
                </Box>
              )}
            </Box>
          </PerfectScrollbar>
          {data.pagination && (
            <Box className={classes.pagStyle}>
              <TablePagination
                count={data.pagination.totalCount}
                page={data.pagination.currentPage - 1}
                rowsPerPage={data.pagination.rows}
                onChangeRowsPerPage={handleRowsPerPageChange}
                onChangePage={handlePageChange}
              />
            </Box>
          )}
        </Card>
        {openRequestDialog && (
          <CustomDialog
            open={openRequestDialog}
            title="Are you want to Authorize Deletion for this User?"
            message="Please note deletion authorization will result in loss of all user information and account!"
            action={handleDeleteUserRequestAction}
            handleDialogClose={handleOpenRequestDialog}
          />
        )}
      </Container>
    </Page>
  );
}
