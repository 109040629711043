import { Box } from '@material-ui/core';
import React from 'react';
import CustomerDataCard from 'src/views/customer/CustomerView/CustomerDataCard';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  boxStyl: {
    marginBottom: 20,
    marginTop: 20
  }
}));

export default function MoreDetails({ customer }) {
  const classes = useStyles();

  return (
    <Box className={classes.boxStyl}>
      <CustomerDataCard
        customer={customer}
        cardTitle="Other Customer information"
      />
    </Box>
  );
}

MoreDetails.propTypes = {
  customer: PropTypes.object
};
