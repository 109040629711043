import axios from 'axios';
import toast from 'react-hot-toast';
import config from 'src/config';
import storage from 'src/storage';

const api = axios.create({ baseURL: config.rootUrl });

api.interceptors.request.use(
  async configs => {
    try {
      const token = await storage.getToken();
      if (token) {
        configs.headers = {
          Authorization: `Bearer ${token}`
        };
      }
    } catch (error) {
      console.log('err token...', error?.message, '..');
    }
    return configs;
  },
  error => Promise.reject(error)
);

api.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error?.code === 'ERR_NETWORK') {
      return toast.error('Check your internet connection');
    }
    if (error?.response) {
      const errMessage = error?.response?.data?.errors[0];
      if (errMessage === 'invalid token' || errMessage === 'Admin not found') {
        storage.removeUser();
        return toast.error('Unauthorized User');
      }
      if (
        error.response &&
        error.response.status === 401 &&
        error.response.data.message === 'TokenExpiredError'
      ) {
        storage.removeUser();
      }
    }

    if (error?.response.status === 409) {
      console.log('error from http>>>', error?.response?.data?.errors[0]);
      toast.error(error?.response?.data?.errors[0]);
    }

    console.log('err token2...', error?.response);
    return Promise.reject(error);
  }
);

export default api;
