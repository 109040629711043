/* eslint-disable no-nested-ternary */
import {
  Box,
  CircularProgress,
  Container,
  makeStyles,
  colors,
  ListItem,
  ListItemText,
  Divider,
  Card,
  CardContent,
  Grid,
  List,
  Typography,
  useMediaQuery,
  TextField,
  Button
} from '@material-ui/core';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import Admin from 'src/api/admin';
import Page from 'src/components/Page';
// import UnverifyDialog from 'src/components/UnverifyDialog';
import CustomDialog from 'src/components/CustomDialog';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';
import TripStatus from 'src/components/TripStatus';
import formatPrice from 'src/utils/formatPrice';
import storage from 'src/storage';
import RetryDialog from 'src/components/RetryDialog';
// import Badge from '@material-ui/core/Badge';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },

  cardRoot: {
    display: 'flex',
    justifyContent: 'center'
  },

  infoItem: {
    '&:not(:last-child)': {
      borderBottom: `1px solid ${colors.grey[100]}`
    }
  },
  label: {
    fontWeight: 'bold'
  },

  grid: {
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column-reverse'
    }
  },

  timelineRoot: {
    paddingLeft: 0
  },
  itemRoot: {
    '&::before': {
      display: 'none'
    }
  },
  name: {
    color: colors.grey[600],
    marginBottom: 4
  },
  address: {
    fontWeight: 700
  },

  resolveRow: {
    position: 'relative',
    '&:not(:last-child)': {
      borderBottom: `1px solid ${colors.grey[100]}`
    }
  },

  resolved: {
    cursor: 'pointer',
    position: 'absolute',
    top: '10px',
    left: '27px'
  },

  disputed: {
    cursor: 'pointer',
    position: 'absolute',
    top: '10px',
    left: '27px'
  },

  resolveButton: {
    backgroundColor: 'lightgrey',
    color: 'white',
    height: '2rem',
    fontSize: '9px',
    width: '7rem',
    '&:hover': {
      backgroundColor: 'lightgrey',
      boxShadow: 'none'
    }
  },

  disputeText: {
    color: colors.red[500]
  },

  disableButton: {
    background: 'lightgrey',
    color: 'white',
    width: '7rem',
    height: '2rem',
    fontSize: '9px',
    backgroundColor: '#f44336'
  },

  disputeButton: {
    backgroundColor: colors.red[500],
    color: 'white',
    height: '2rem',
    fontSize: '9px',
    width: '7rem',
    '&:hover': {
      backgroundColor: colors.red[500],
      boxShadow: 'none'
    }
  },

  disputedCont: {
    position: 'relative',
    cursor: 'pointer'
  },

  mobileCard: {
    width: '100%'
  },

  desktopCard: {
    width: '80%'
  },

  value: {
    marginLeft: '1rem'
  },

  toolTip: {
    position: 'absolute',
    top: 0
  },

  ackText: {
    top: '13px',
    left: '1rem',
    position: 'absolute',
    width: '31rem'
  },

  ackStat: {
    top: '13px',
    left: '15rem',
    position: 'absolute',
    width: '31rem'
  },

  ackFundDis: {
    top: '13px',
    left: '1rem',
    position: 'absolute',
    width: '31rem'
  },

  modalComponent: {
    marginTop: '0.7rem'
  }
}));

export default function ReportDetails() {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [reportDetails, setReportDetails] = useState({});
  const [openResolveDialog, setOpenResolveDialog] = useState(false);
  // const [openUnResolveDialog, setOpenUnResolveDialog] = useState(false);
  const [adminUser, setAdminUser] = useState({});
  const [riderFunds, setRiderFunds] = useState('');
  const [driverFunds, setDriverFunds] = useState('');
  const [resolveStatus, setResolveStatus] = useState(reportDetails?.resolved);
  // const [superAdminResolveStatus, setSuperAdminResoveStatus] = useState(
  //   reportDetails?.data?.riderReportResolved
  // );
  const [bookingStatus, setBookingStatus] = useState(
    reportDetails?.bookingStatus
  );
  const isMobileView = useMediaQuery('(max-width:768px)');
  const [openSuperAdminResolveDialog, setSuperOpenAdminResolve] = useState(
    false
  );

  // console.log('adminUser>>', adminUser);
  // console.log('report details', reportDetails);
  // console.log('super admin stat>>', superAdminResolveStatus);
  const handleDialogOpenResolve = () => {
    setOpenResolveDialog(prev => !prev);
  };

  const handleDialogSuperAdminDialog = () => {
    setSuperOpenAdminResolve(prev => !prev);
  };

  const classes = useStyles();

  const fetchAdminUser = () => {
    const logInuser = storage.getUser();
    setAdminUser(logInuser);
  };

  const handleGetReportDetails = async reportId => {
    try {
      const res = await Admin.getCustomersReportDetails(reportId);
      // console.log('response>>>', res);
      if (res) {
        setReportDetails(res);
        setResolveStatus(res?.data?.resolved);
        setBookingStatus(res?.data?.bookingStatus);
        fetchAdminUser();
        setIsLoading(false);
      }
    } catch (e) {
      console.log('Cant fetch report details>>>', e);
    }
  };

  const handleResolveReport = async reportId => {
    try {
      const res = await Admin.resolveCustomerReport(reportId);

      // setReportDetails(res);
      setResolveStatus(res?.data?.resolved);
      toast.success('Reported Dispute resolved successfully!');
      handleDialogOpenResolve();
      // console.log('res from resolve>>', res);
    } catch (e) {
      toast.error('Unable to resolve this report dispute!');
      handleDialogOpenResolve();
      console.log('Cant resolve report>>>', e);
    }
  };

  const handleAdminUserReport = async (reportId, payload) => {
    try {
      const res = await Admin.adminUsersResolveReport(reportId, payload);
      if (res) {
        // setSuperAdminResoveStatus();
        setResolveStatus(res?.data?.resolved);
        setBookingStatus(res?.data?.bookingStatus);
        toast.success(res?.data?.message);
        handleDialogSuperAdminDialog();
        // console.log('res from resolve>>', res);
      }
    } catch (e) {
      // const errorMessage = e?.data?.errors?.[0];
      // console.log(errorMessage);
      toast.error('Can not resolve dispute');
      handleDialogSuperAdminDialog();
    }
  };

  useEffect(() => {
    if (id) {
      handleGetReportDetails(id);
    }
  }, []);

  // console.log('resolve state>>', reportDetails);
  // console.log('reportdetails>>', reportDetails);

  if (isLoading) {
    return (
      <Page className={classes.root}>
        <Box textAlign="center" py={10}>
          <CircularProgress />
        </Box>
      </Page>
    );
  }

  // console.log('local status>.', resolveStatus);
  // eslint-disable-next-line no-return-assign
  return (
    <Page title="Report Details">
      <Container>
        {/* refund details */}
        {openSuperAdminResolveDialog && (
          <RetryDialog
            title="Are you sure want to Resolve this User Report by Fund sharing?"
            message={`Note! the amount of ${formatPrice(
              reportDetails?.data?.bookingAmount,
              'NGN'
            )} funds will be sent to both the Rider and the Driver's wallet respectively depending on the input Amount!`}
            open={openSuperAdminResolveDialog}
            handleDialogClose={handleDialogSuperAdminDialog}
            component={
              <Box>
                <Box className={classes.modalComponent}>
                  <TextField
                    type="text"
                    label="Input Driver Amount"
                    InputLabelProps={{
                      shrink: true
                    }}
                    value={driverFunds}
                    onChange={e => setDriverFunds(e.target.value)}
                  />
                </Box>
                <Box className={classes.modalComponent}>
                  <TextField
                    type="text"
                    label="Input Rider's Amount"
                    InputLabelProps={{
                      shrink: true
                    }}
                    value={riderFunds}
                    onChange={e => setRiderFunds(e.target.value)}
                  />
                </Box>
              </Box>
            }
            action={() =>
              handleAdminUserReport(id, {
                riderAmount: Number(riderFunds),
                driverAmount: Number(driverFunds),
                bookingId: reportDetails.data.bookingId
              })
            }
          />
        )}
        {openResolveDialog && (
          <CustomDialog
            title="Are you sure want to Resolve this User Report"
            message="Note! this User will be restrictions will be Resolved!"
            open={openResolveDialog}
            handleDialogClose={handleDialogOpenResolve}
            action={() => handleResolveReport(id)}
          />
        )}
        <Grid container className={classes.grid} spacing={3}>
          <Grid item xs={12}>
            <Box mb={4} className={classes.cardRoot}>
              <Card
                className={
                  isMobileView ? classes.mobileCard : classes.desktopCard
                }
              >
                <CardContent>
                  <List>
                    <ListItem className={classes.infoI}>
                      <Typography className={classes.label}>
                        Report Details
                      </Typography>
                    </ListItem>
                    <Divider />
                    <ListItem className={classes.infoI}>
                      <ListItemText>
                        <Grid container>
                          <Grid item lg={4}>
                            <Typography className={classes.label}>
                              Time of Report:
                            </Typography>
                          </Grid>
                          <Grid item lg={8}>
                            <Box className={classes.value}>
                              {moment(reportDetails?.data?.createdAt).format(
                                'MMMM Do, YYYY h:mm A'
                              )}
                            </Box>
                          </Grid>
                        </Grid>
                      </ListItemText>
                    </ListItem>
                    <Divider />
                    <ListItem className={classes.infoI}>
                      <ListItemText>
                        <Grid container>
                          <Grid item lg={4}>
                            <Typography className={classes.label}>
                              Reporter Name:
                            </Typography>
                          </Grid>
                          <Grid item lg={8}>
                            <Box className={classes.value}>
                              {reportDetails?.reportedByName}
                            </Box>
                          </Grid>
                        </Grid>
                      </ListItemText>
                    </ListItem>
                    <Divider />
                    <ListItem className={classes.infoI}>
                      <ListItemText>
                        <Grid container>
                          <Grid item lg={4}>
                            <Typography className={classes.label}>
                              Reporter Id:
                            </Typography>
                          </Grid>
                          <Grid item lg={8}>
                            <Box className={classes.value}>
                              <Link
                                to="#"
                                onClick={e => {
                                  e.preventDefault();
                                  window.open(
                                    `/app/customers/${reportDetails?.data?.reporterId}`,
                                    '_blank',
                                    'noopener,noreferrer'
                                  );
                                }}
                              >
                                {reportDetails?.data?.reporterId}
                              </Link>
                            </Box>
                          </Grid>
                        </Grid>
                      </ListItemText>
                    </ListItem>
                    <Divider />
                    <ListItem className={classes.infoI}>
                      <ListItemText>
                        <Grid container>
                          <Grid item lg={4}>
                            <Typography className={classes.label}>
                              Reported Name:
                            </Typography>
                          </Grid>
                          <Grid item lg={8}>
                            <Box className={classes.value}>
                              {reportDetails?.reportedName}
                            </Box>
                          </Grid>
                        </Grid>
                      </ListItemText>
                    </ListItem>
                    <Divider />
                    <ListItem className={classes.infoI}>
                      <ListItemText>
                        <Grid container>
                          <Grid item lg={4}>
                            <Typography className={classes.label}>
                              Reported Id:
                            </Typography>
                          </Grid>
                          <Grid item lg={8}>
                            <Box className={classes.value}>
                              <Link
                                to="#"
                                onClick={e => {
                                  e.preventDefault();
                                  window.open(
                                    `/app/customers/${reportDetails?.data?.reportedUserId}`,
                                    '_blank',
                                    'noopener,noreferrer'
                                  );
                                }}
                              >
                                {reportDetails?.data?.reportedUserId}
                              </Link>
                            </Box>
                          </Grid>
                        </Grid>
                      </ListItemText>
                    </ListItem>
                    <Divider />
                    <ListItem className={classes.infoI}>
                      <ListItemText>
                        <Grid container>
                          <Grid item lg={4}>
                            <Typography className={classes.label}>
                              Reported By:
                            </Typography>
                          </Grid>
                          <Grid item lg={8}>
                            <Box className={classes.value}>
                              {reportDetails?.data?.reportedBy}
                            </Box>
                          </Grid>
                        </Grid>
                      </ListItemText>
                    </ListItem>
                    <Divider />
                    <ListItem className={classes.infoI}>
                      <ListItemText>
                        <Grid container>
                          <Grid item lg={4}>
                            <Typography className={classes.label}>
                              Report Description:
                            </Typography>
                          </Grid>
                          <Grid item lg={8}>
                            <Box className={classes.value}>
                              {reportDetails?.data?.description}
                            </Box>
                          </Grid>
                        </Grid>
                      </ListItemText>
                    </ListItem>
                    <Divider />
                    <ListItem className={classes.infoI}>
                      <ListItemText>
                        <Grid container>
                          <Grid item lg={4}>
                            <Typography className={classes.label}>
                              Trip Id:
                            </Typography>
                          </Grid>
                          <Grid item lg={8}>
                            <Box className={classes.value}>
                              <Link
                                to="#"
                                onClick={e => {
                                  e.preventDefault();
                                  window.open(
                                    `/app/trips/${reportDetails?.data?.tripId}`,
                                    '_blank',
                                    'noopener,noreferrer'
                                  );
                                }}
                              >
                                {reportDetails?.data?.tripId}
                              </Link>
                            </Box>
                          </Grid>
                        </Grid>
                      </ListItemText>
                    </ListItem>
                    <Divider />
                    <ListItem className={classes.infoI}>
                      <ListItemText>
                        <Grid container>
                          <Grid item lg={4}>
                            <Typography className={classes.label}>
                              Booking ID:
                            </Typography>
                          </Grid>
                          <Grid item lg={8}>
                            <Box className={classes.value}>
                              {reportDetails?.data?.bookingId !== null ? (
                                <Link
                                  to="#"
                                  onClick={e => {
                                    e.preventDefault();
                                    window.open(
                                      `/app/bookings/${reportDetails?.data?.bookingId}`,
                                      '_blank',
                                      'noopener,noreferrer'
                                    );
                                  }}
                                >
                                  {reportDetails?.data?.bookingId}
                                </Link>
                              ) : (
                                <Typography>No Available Booking ID</Typography>
                              )}
                            </Box>
                          </Grid>
                        </Grid>
                      </ListItemText>
                    </ListItem>
                    <Divider />
                    <ListItem className={classes.infoI}>
                      <ListItemText>
                        <Grid container>
                          <Grid item lg={4}>
                            <Typography className={classes.label}>
                              Booking Amount:
                            </Typography>
                          </Grid>
                          <Grid item lg={8}>
                            {!reportDetails?.data?.bookingAmount ? (
                              <Box className={classes.value}>
                                <Typography>
                                  No Available booking amount
                                </Typography>
                              </Box>
                            ) : (
                              <Box className={classes.value}>
                                {formatPrice(
                                  reportDetails?.data?.bookingAmount,
                                  reportDetails?.data?.amountCurrency
                                )}
                              </Box>
                            )}
                          </Grid>
                        </Grid>
                      </ListItemText>
                    </ListItem>
                    <Divider />
                    <ListItem className={classes.infoI}>
                      <ListItemText>
                        <Grid container>
                          <Grid item lg={4}>
                            <Typography className={classes.label}>
                              Booking Status:
                            </Typography>
                          </Grid>
                          <Grid item lg={8}>
                            <Box className={classes.value}>
                              {reportDetails?.data?.reportedBy === 'rider' && (
                                <TripStatus
                                  status={
                                    !resolveStatus ||
                                    bookingStatus === 'Disputed'
                                      ? 'Disputed'
                                      : 'Acknowleged'
                                  }
                                />
                              )}
                              {reportDetails?.data?.reportedBy === 'driver' && (
                                <TripStatus
                                  status={
                                    !resolveStatus ? 'Disputed' : 'Acknowleged'
                                  }
                                />
                              )}
                            </Box>
                          </Grid>
                        </Grid>
                      </ListItemText>
                    </ListItem>
                    <Divider />
                    <ListItem className={classes.infoI}>
                      <ListItemText>
                        <Grid container>
                          <Grid item lg={4}>
                            <Typography className={classes.label}>
                              Booking Start Location:
                            </Typography>
                          </Grid>
                          <Grid item lg={8}>
                            <Box className={classes.value}>
                              {reportDetails?.data?.departureText}
                            </Box>
                          </Grid>
                        </Grid>
                      </ListItemText>
                    </ListItem>
                    <Divider />
                    <ListItem className={classes.infoI}>
                      <ListItemText>
                        <Grid container>
                          <Grid item lg={4}>
                            <Typography className={classes.label}>
                              Booking Stop Destination:
                            </Typography>
                          </Grid>
                          <Grid item lg={8}>
                            <Box className={classes.value}>
                              {reportDetails?.data?.destinationText}
                            </Box>
                          </Grid>
                        </Grid>
                      </ListItemText>
                    </ListItem>
                    <Divider />
                    <ListItem className={classes.infoI}>
                      <ListItemText>
                        <Grid container>
                          <Grid item lg={4}>
                            <Typography className={classes.label}>
                              Report Resolve Status:
                            </Typography>
                          </Grid>
                          <Grid item lg={8}>
                            <Box className={classes.value}>
                              {!resolveStatus ? (
                                <Typography className={classes.disputeText}>
                                  Reported Dispute!
                                </Typography>
                              ) : (
                                <Typography>Dispute Resolved</Typography>
                              )}
                            </Box>
                          </Grid>
                        </Grid>
                      </ListItemText>
                    </ListItem>
                    <Divider />
                    <ListItem className={classes.resolveRow}>
                      <ListItemText>
                        <Grid container spacing={2}>
                          <Grid item lg={4} xs={12}>
                            <Typography className={classes.label}>
                              Resolve Dispute:
                            </Typography>
                          </Grid>
                          <Grid item lg={8} xs={12}>
                            {/* // eslint-disable-next-line no-nested-ternary */}
                            {reportDetails?.data?.reportedBy === 'driver' &&
                            !resolveStatus ? (
                              <Box className={classes.toolTip}>
                                <Box className={classes.disputedCont}>
                                  <Box className={classes.disputed}>
                                    <Button
                                      onClick={handleDialogOpenResolve}
                                      disabled={
                                        reportDetails?.data.reportedBy ===
                                        'rider'
                                      }
                                      className={
                                        reportDetails?.data.reportedBy ===
                                        'rider'
                                          ? classes.disableButton
                                          : classes.disputeButton
                                      }
                                      variant="contained"
                                    >
                                      Resolve Driver Dispute
                                    </Button>
                                  </Box>
                                </Box>
                              </Box>
                            ) : reportDetails?.data?.reportedBy === 'driver' &&
                              resolveStatus ? (
                              <Box className={classes.resolvedCont}>
                                <Typography className={classes.ackStat}>
                                  <TripStatus status="Driver's Reported Dispute Acknowledged" />
                                </Typography>
                              </Box>
                            ) : (
                              <Box className={classes.resolvedCont}>
                                <Typography className={classes.ackStat}>
                                  No Reported Dispute by Driver.
                                </Typography>
                              </Box>
                            )}
                          </Grid>
                        </Grid>
                      </ListItemText>
                    </ListItem>

                    {adminUser.role === 'super' &&
                      reportDetails?.data?.reportedBy === 'rider' && (
                        <>
                          <Divider />
                          <ListItem className={classes.resolveRow}>
                            <ListItemText>
                              <Grid container>
                                <Grid item lg={4}>
                                  <Typography className={classes.label}>
                                    Resolve Funds Dispute:
                                  </Typography>
                                </Grid>
                                <Grid item lg={8}>
                                  <Box className={classes.toolTip}>
                                    {!resolveStatus ||
                                    bookingStatus === 'Disputed' ? (
                                      <Box
                                        className={classes.disputedCont}
                                        onClick={handleDialogSuperAdminDialog}
                                      >
                                        <Box className={classes.disputed}>
                                          <Button
                                            variant="contained"
                                            className={classes.disputeButton}
                                          >
                                            Resolve Funds Dispute
                                          </Button>
                                        </Box>
                                      </Box>
                                    ) : (
                                      <Box className={classes.resolvedCont}>
                                        <Typography
                                          className={classes.ackFundDis}
                                        >
                                          <TripStatus status="Rider's Reported Dispute Acknowledged" />
                                        </Typography>
                                      </Box>
                                    )}
                                  </Box>
                                </Grid>
                              </Grid>
                            </ListItemText>
                          </ListItem>
                        </>
                      )}
                  </List>
                </CardContent>
              </Card>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
