import { all, call, spawn } from 'redux-saga/effects';
import { getAdminsWatcher } from './adminSaga';
import { getUsersWatcher, getUsersActivitiesWatcher } from './userSaga';

/**
 * This is the root saga. all sagas are yielded here
 */
export default function* rootSaga() {
  // Yield your sagas here
  const sagas = [
    getUsersWatcher,
    getAdminsWatcher,
    getUsersActivitiesWatcher,
  ];

  // eslint-disable-next-line func-names
  yield all(sagas.map((saga) => spawn(function* () {
    while (true) {
      try {
        yield call(saga);
        break;
      } catch (e) {
        console.log(e);
      }
    }
  })));
}
