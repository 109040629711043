import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {
  Box,
  CardHeader,
  Divider,
  Menu,
  MenuItem,
  colors
} from '@material-ui/core';
import PropTypes from 'prop-types';
import moment from 'moment';
import Ratings from 'src/components/Ratings';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import StarsIcon from '@material-ui/icons/Stars';
import { useParams } from 'react-router';
import Admin from 'src/api/admin';
import CustomDialog from 'src/components/CustomDialog';
// import Admin from 'src/api/admin';
// import { useParams } from 'react-router';

const useStyles = makeStyles({
  root: {
    minWidth: 275
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)'
  },
  title: {
    fontSize: 14
  },
  pos: {
    marginBottom: 12
  },

  py: {
    padding: '8px 0px'
  },

  menuItem: {
    // Add your custom styles here
    '& .MuiMenuItem-root': {
      display: 'flex',
      gap: '3px'
    }
  }
});

const RatersComponent = ({ rateList }) => {
  console.log('rateList form rate comp', rateList);
  return (
    <>
      {rateList.length > 0 ? (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          {rateList?.map(rate => (
            <Box sx={{ display: 'flex', gap: 3 }}>
              <Ratings ratingValue={rate?.rating} precision readOnly />
              <Typography>{rate?.ratedByName}</Typography>
            </Box>
          ))}
        </Box>
      ) : (
        <p>No rate yet for this user</p>
      )}
    </>
  );
};

export default function CustomerDataCard({
  cardAction = false,
  customer,
  cardTitle
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openDialog, setOpenDialog] = React.useState(false);
  const { id } = useParams();
  const [rateList, setRateList] = useState([]);
  const [ratersName, setRatersName] = useState([]);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDialog = () => {
    handleClose();
    setOpenDialog(prev => !prev);
  };

  const handleGetCustomerRatings = async () => {
    const res = await Admin.getCustomerRatings(id);
    if (res) {
      setRateList(res.data.data);
      // console.log('ratings response>>>>', res);
      // console.log('rating List>>>>', rateList);
    } else {
      console.log('couldnt get customers rate list>>>');
    }
  };

  const classes = useStyles();
  const [customersRating, setCustomersRating] = useState(
    customer?.rating?.rating
  );

  const getCustomerRaterNames = () => {
    rateList.map(rater => setRatersName(rater?.ratedByName));
  };

  useEffect(() => {
    if (customer) {
      setCustomersRating(customer?.rating?.rating);
      handleGetCustomerRatings();
      getCustomerRaterNames();
      // getRaters();
      // console.log('customer ratings>>', customersRating);
    }
  }, [customer]);

  // console.log('rating List>>>>', rateList);

  console.log('raters>>>', ratersName);

  return (
    <Card className={classes.root}>
      <CardHeader title={cardTitle} />
      <Divider />
      <CardContent>
        {typeof customer?.active === 'boolean' && (
          <Box className={classes.py}>
            <Typography
              className={classes.title}
              gutterBottom
              style={{ color: colors.grey[500] }}
            >
              Customer Status
            </Typography>
            <Typography variant="h5" component="h2">
              {customer?.active ? 'Active' : 'Not Active'}
            </Typography>
          </Box>
        )}
        <Divider />
        {customer?.homeAndAway && (
          <Box className={classes.py}>
            <Typography
              className={classes.title}
              gutterBottom
              style={{ color: colors.grey[500] }}
            >
              Customer Default Home Destination
            </Typography>
            <Typography variant="h5" component="h2">
              {customer?.homeAndAway?.home?.address}
            </Typography>
          </Box>
        )}
        {customer?.homeAndAway && (
          <Box className={classes.py}>
            <Typography
              className={classes.title}
              gutterBottom
              style={{ color: colors.grey[500] }}
            >
              Customer Default Away Destination
            </Typography>
            <Typography variant="h5" component="h2">
              {customer?.homeAndAway?.away?.address}
            </Typography>
          </Box>
        )}
        {typeof customer?.isBankVerified === 'boolean' && (
          <Box className={classes.py}>
            <Typography
              className={classes.title}
              gutterBottom
              style={{ color: colors.grey[500] }}
            >
              Bank Verification Status
            </Typography>
            <Typography variant="h5" component="h2">
              {customer?.isBankVerified
                ? 'Bank Information Verified'
                : 'Bank Information Not Verified'}
            </Typography>
          </Box>
        )}
        <Divider />
        <Box
          className={classes.py}
          sx={{ height: '5rem', position: 'relative' }}
        >
          <Box
            sx={{ position: 'absolute', right: 0, cursor: 'pointer' }}
            onClick={handleClick}
          >
            <MoreVertIcon />
          </Box>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            className={classes.menuItem}
          >
            <MenuItem onClick={handleDialog}>View Rate List</MenuItem>
          </Menu>
          <Typography
            className={classes.title}
            gutterBottom
            style={{ color: colors.grey[500] }}
          >
            Customer Overall Ratings
          </Typography>
          <Box>
            <Ratings ratingValue={customersRating} precision readOnly />
          </Box>
        </Box>
        <Divider />
        <Box className={classes.py}>
          <Typography
            className={classes.title}
            gutterBottom
            style={{ color: colors.grey[500] }}
          >
            Customer Registeration Date
          </Typography>
          <Typography variant="h5" component="h2">
            {`${moment(customer?.createdAt).format('DD/MM/YYYY')}`}
          </Typography>
        </Box>
      </CardContent>
      <Divider />
      {cardAction && (
        <CardActions>
          <Button size="small">Learn More</Button>
        </CardActions>
      )}
      {openDialog && (
        <CustomDialog
          open={openDialog}
          handleDialogClose={handleDialog}
          action={handleDialog}
          title="Customer Rate List"
          icons={<StarsIcon />}
          isReadDialog
          message={<RatersComponent rateList={rateList} />}
        />
      )}
    </Card>
  );
}

CustomerDataCard.propTypes = {
  customer: PropTypes.object,
  cardAction: PropTypes.bool.isRequired,
  cardTitle: PropTypes.string
};

RatersComponent.propTypes = {
  rateList: PropTypes.array
};
