import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  makeStyles,
  Container,
  Box,
  Table,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
  Card,
  CircularProgress
} from '@material-ui/core';
import { useQuery } from 'react-query';
import PerfectScrollbar from 'react-perfect-scrollbar';
import config from 'src/config';
import BookingRow from 'src/components/BookingRow';
import Page from 'src/components/Page';
import TablePagination from 'src/components/TablePagination';
import useQueryString from 'src/hooks/useQueryString';
import api from 'src/services/httpService';
import TableLoader from 'src/components/TableLoader';
import storage from 'src/storage';
import Toolbar from './Toolbar';
// import Filter from './Filter';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },

  nowrap: {
    whiteSpace: 'nowrap'
  }
}));

const fetchBookings = async query => {
  const axiosConfig = {
    headers: {
      Authorization: `Bearer ${storage.getToken()}`
    }
  };
  const searchParams = new URLSearchParams(query);

  const { data } = await api.get(
    `${config.rootUrl}/admin/booking?${searchParams}`,
    axiosConfig
  );
  return data;
};

// const searchParams = new URLSearchParams(queries);

export default function BookingsList() {
  const classes = useStyles();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [query, setQueryString] = useQueryString();

  const { data = {} } = useQuery(['trips', query], () => fetchBookings(query), {
    keepPreviousData: true
  });

  const { data: bookings = [] } = data;

  const setLoading = () => {
    if (bookings.length > 0) {
      setIsLoading(false);
    }
  };

  console.log('data>>>', data);

  useEffect(() => {
    setLoading();
  }, [bookings]);

  if (isLoading) {
    return (
      <Page className={classes.root}>
        <Box textAlign="center" py={10}>
          <CircularProgress />
        </Box>
      </Page>
    );
  }

  return (
    <Page className={classes.root} title="Trips">
      <Container maxWidth={false}>
        <Box mb={4}>
          <Toolbar />
          {/* <Filter defaultValues={query} /> */}
        </Box>
        <Card>
          <PerfectScrollbar>
            <Box minWidth={1050}>
              {isLoading ? (
                <TableLoader />
              ) : (
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Departure</TableCell>
                      <TableCell>Destination</TableCell>
                      <TableCell>Amount</TableCell>
                      <TableCell>Payment Status</TableCell>
                      <TableCell>Booking Status</TableCell>
                      <TableCell>Departure Date</TableCell>
                      <TableCell>Start Location</TableCell>
                      <TableCell>Stop Location</TableCell>
                      <TableCell>Departure Time</TableCell>
                      <TableCell>Paid On</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {bookings.map(booking => (
                      <BookingRow
                        key={booking.id}
                        booking={booking}
                        hover
                        onClick={() => navigate(`/app/bookings/${booking.id}`)}
                        className={classes.tableRow}
                        style={{ cursor: 'pointer' }}
                        isLoading={isLoading}
                      />
                    ))}
                  </TableBody>
                </Table>
              )}
            </Box>
          </PerfectScrollbar>
          {data.pagination && (
            <TablePagination
              count={data.pagination.totalCount}
              page={data.pagination.currentPage}
              rowsPerPage={data.pagination.rows}
              onChangeRowsPerPage={rows => setQueryString({ rows })}
              onChangePage={pageNo => setQueryString({ pageNo })}
            />
          )}
        </Card>
      </Container>
    </Page>
  );
}
