function truncateSentence(sentence, maxLength) {
  if (sentence) {
    if (sentence.length <= maxLength) {
      return sentence;
    }
  } else {
    return null;
  }

  return `${sentence.slice(0, maxLength)}, ...`;
}

export default truncateSentence;
