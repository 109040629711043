import React from 'react';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  useTheme,
  makeStyles
} from '@material-ui/core';
import { Doughnut } from 'react-chartjs-2';
import PropTypes from 'prop-types';
import MoneyIcon from '@material-ui/icons/Money';
import InsertChartIcon from '@material-ui/icons/InsertChartOutlined';
import PeopleIcon from '@material-ui/icons/PeopleOutlined';
import TableLoader from './TableLoader';

const Chart = ({
  totalTripCount,
  totalBookingsCount,
  totalUserCount,
  isLoading
}) => {
  const theme = useTheme();

  const data = {
    datasets: [
      {
        data: [totalUserCount, totalTripCount, totalBookingsCount],
        backgroundColor: ['#43a047', '#e53935', '#FB8C00'],
        borderWidth: 8,
        borderColor: '#FFFFFF',
        hoverBorderColor: '#FFFFFF'
      }
    ],
    labels: ['Total Users Count', 'Published Rides', 'Total Bookings']
  };

  const options = {
    animation: false,
    cutoutPercentage: 50,
    layout: { padding: 0 },
    legend: {
      display: true
    },
    maintainAspectRatio: false,
    responsive: true,
    tooltips: {
      backgroundColor: theme.palette.background.paper,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: true,
      mode: 'index',
      titleFontColor: theme.palette.text.primary
    }
  };

  const devices = [
    {
      title: 'Total Users Count',
      value: totalUserCount,
      icon: PeopleIcon,
      color: '#43a047'
    },
    {
      title: 'Published Rides',
      value: totalTripCount,
      icon: MoneyIcon,
      color: '#E53935'
    },
    {
      title: 'Total Bookings',
      value: totalBookingsCount,
      icon: InsertChartIcon,
      color: '#FB8C00'
    }
  ];
  const useStyles = makeStyles({
    cardRoot: {
      height: '100%'
    },
    CardContent: {
      height: '300px',
      position: 'relative'
    },
    CardGargets: {
      display: 'flex',
      justifyContent: 'center',
      paddingTop: theme.spacing(2),
      flexDirection: 'row',
      fontFamily: 'Roboto, sans-serif',
      fontWeight: 500,
      textAlign: 'center'
    },
    individualItem: {
      padding: 1,
      textAlign: 'center',
      marginLeft: theme.spacing(3)
    },
    typographyValue: {
      fontSize: 30,
      fontFamily: 'Roboto, sans-serif',
      fontWeight: 600
    }
  });

  const classes = useStyles();

  return (
    <Card className={classes.cardRoot}>
      <CardHeader title="Users Activities" />
      <Divider />
      {isLoading ? (
        <TableLoader />
      ) : (
        <CardContent>
          <Box className={classes.CardContent}>
            <Doughnut data={data} options={options} />
          </Box>

          <Box className={classes.CardGargets}>
            {devices.map(({ color, icon: Icon, title, value }) => (
              <Box className={classes.individualItem} key={title}>
                <Icon color="action" />
                <Typography
                  color="textPrimary"
                  variant="body1"
                  className={classes.typographyTitle}
                >
                  {title}
                </Typography>
                <Typography
                  style={{
                    color
                  }}
                  variant="h4"
                  className={classes.typographyValue}
                >
                  {value}%
                </Typography>
              </Box>
            ))}
          </Box>
        </CardContent>
      )}
    </Card>
  );
};

Chart.propTypes = {
  totalTripCount: PropTypes.number,
  totalBookingsCount: PropTypes.number,
  totalUserCount: PropTypes.number,
  isLoading: PropTypes.bool
};

export default Chart;
