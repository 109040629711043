const alertSnackbarState = {
  open: false,
  message: '',
  severity: 'error',
};

export default function alertSnackbarReducer(state = alertSnackbarState, action) {
  switch (action.type) {
    case 'alertSnackBar/open':
      return {
        ...state,
        open: action.payload,
      };
    case 'alertSnackBar/message':
      return {
        ...state,
        message: action.payload,
      };

    case 'alertSnackBar/severity':
      return {
        ...state,
        severity: action.payload,
      };

    default: return state;
  }
}
