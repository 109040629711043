import {
  Box,
  CircularProgress,
  Container,
  makeStyles,
  colors,
  ListItem,
  ListItemText,
  Divider,
  Card,
  CardContent,
  Grid,
  List,
  Typography,
  useMediaQuery,
  Button
} from '@material-ui/core';
// import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import Admin from 'src/api/admin';

import Page from 'src/components/Page';

import { Link } from 'react-router-dom';
import moment from 'moment';
import formatPrice from 'src/utils/formatPrice';
import TripStatus from 'src/components/TripStatus';
import CustomDialog from 'src/components/CustomDialog';
import toast from 'react-hot-toast';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },

  cardRoot: {
    display: 'flex',
    justifyContent: 'center'
  },

  infoItem: {
    '&:not(:last-child)': {
      borderBottom: `1px solid ${colors.grey[100]}`
    }
  },
  label: {
    fontWeight: 'bold'
  },

  grid: {
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column-reverse'
    }
  },

  timelineRoot: {
    paddingLeft: 0
  },
  itemRoot: {
    '&::before': {
      display: 'none'
    }
  },
  name: {
    color: colors.grey[600],
    marginBottom: 4
  },
  address: {
    fontWeight: 700
  },

  resolveRow: {
    position: 'relative',
    '&:not(:last-child)': {
      borderBottom: `1px solid ${colors.grey[100]}`
    }
  },

  mobileCard: {
    width: '100%'
  },

  desktopCard: {
    width: '80%'
  },

  value: {
    marginLeft: '1rem'
  },

  toolTip: {
    position: 'absolute',
    top: 0
  },

  cancel: {
    color: 'white',
    background: colors.red[500]
  }
}));

export default function BookingDetails() {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [bookingDetails, setBookingDetails] = useState({});
  const [openCancelBooking, setOpenCancelBooking] = useState(false);
  const [bookingStatus, setBookingStatus] = useState();
  const [isCancelling, setIsCancelling] = useState(false);

  const isMobileView = useMediaQuery('(max-width:768px)');

  const classes = useStyles();

  const handleCancelDialog = () => {
    setOpenCancelBooking(prev => !prev);
  };

  const handleFetchBookingDetails = async bookingId => {
    try {
      const res = await Admin.getCustomerBookingDetails(bookingId);
      if (res) {
        setBookingDetails(res.data);
        setBookingStatus(res.data?.bookingStatus);
        setIsLoading(false);
        // console.log(res.data);
      }
    } catch (e) {
      console.log("couldn't fetch booking details>>>", e);
    }
  };

  const handleCancelBooking = async bookingId => {
    setIsCancelling(true);
    try {
      const res = await Admin.cancelBooking(bookingId);
      if (res) {
        setBookingStatus(res.data.bookingStatus);
        setIsCancelling(false);
        toast.success(res.message);
        console.log('booking cancel>>', res);
      }
    } catch (e) {
      console.log('booking cancelled>>', e);
    }
  };

  useEffect(() => {
    handleFetchBookingDetails(id);
  }, []);

  console.log('booking details>>', bookingDetails);
  console.log('booking status>>', bookingStatus);

  if (isLoading) {
    return (
      <Page className={classes.root}>
        <Box textAlign="center" py={10}>
          <CircularProgress />
        </Box>
      </Page>
    );
  }

  return (
    <Page title="Report Details">
      {openCancelBooking && (
        <CustomDialog
          title="Are you sure you want to Cancel this User Booking!"
          message="Note! User Booking will be deactivated!"
          open={openCancelBooking}
          handleDialogClose={handleCancelDialog}
          action={() => handleCancelBooking(id)}
        />
      )}
      <Container>
        <Grid container className={classes.grid} spacing={3}>
          <Grid item xs={12}>
            <Box mb={4} className={classes.cardRoot}>
              <Card
                className={
                  isMobileView ? classes.mobileCard : classes.desktopCard
                }
              >
                <CardContent>
                  <List>
                    <ListItem className={classes.infoI}>
                      <Typography className={classes.label}>
                        Booking Details
                      </Typography>
                    </ListItem>
                    <Divider />
                    <ListItem className={classes.infoI}>
                      <ListItemText>
                        <Grid container>
                          <Grid item lg={4}>
                            <Typography className={classes.label}>
                              Time of Booking:
                            </Typography>
                          </Grid>
                          <Grid item lg={8}>
                            <Box className={classes.value}>
                              {moment(bookingDetails.createdAt).format(
                                'ddd, MMMM Do YYYY'
                              )}
                            </Box>
                          </Grid>
                        </Grid>
                      </ListItemText>
                    </ListItem>
                    <Divider />
                    <ListItem className={classes.infoI}>
                      <ListItemText>
                        <Grid container>
                          <Grid item lg={4}>
                            <Typography className={classes.label}>
                              Booking Amount:
                            </Typography>
                          </Grid>
                          <Grid item lg={8}>
                            <Box className={classes.value}>
                              {formatPrice(
                                bookingDetails.amount / 100,
                                bookingDetails.amountCurrency
                              )}
                            </Box>
                          </Grid>
                        </Grid>
                      </ListItemText>
                    </ListItem>
                    <Divider />
                    <ListItem className={classes.infoI}>
                      <ListItemText>
                        <Grid container>
                          <Grid item lg={4}>
                            <Typography className={classes.label}>
                              Booking Id:
                            </Typography>
                          </Grid>
                          <Grid item lg={8}>
                            <Box className={classes.value}>
                              {bookingDetails.id}
                            </Box>
                          </Grid>
                        </Grid>
                      </ListItemText>
                    </ListItem>
                    <Divider />
                    <ListItem className={classes.infoI}>
                      <ListItemText>
                        <Grid container>
                          <Grid item lg={4}>
                            <Typography className={classes.label}>
                              Booking Status:
                            </Typography>
                          </Grid>
                          <Grid item lg={8}>
                            <Box className={classes.value}>
                              {isCancelling ? (
                                <Button disabled variant="outline">
                                  Canceling....
                                </Button>
                              ) : (
                                <TripStatus status={bookingStatus} />
                              )}
                            </Box>
                          </Grid>
                        </Grid>
                      </ListItemText>
                    </ListItem>
                    <Divider />
                    <ListItem className={classes.infoI}>
                      <ListItemText>
                        <Grid container>
                          <Grid item lg={4}>
                            <Typography className={classes.label}>
                              Departure Date
                            </Typography>
                          </Grid>
                          <Grid item lg={8}>
                            <Box className={classes.value}>
                              {moment(bookingDetails.departureDate).format(
                                'ddd, MMMM Do YYYY'
                              )}
                            </Box>
                          </Grid>
                        </Grid>
                      </ListItemText>
                    </ListItem>
                    <Divider />
                    <ListItem className={classes.infoI}>
                      <ListItemText>
                        <Grid container>
                          <Grid item lg={4}>
                            <Typography className={classes.label}>
                              Booking Pick Up Location:
                            </Typography>
                          </Grid>
                          <Grid item lg={8}>
                            <Box className={classes.value}>
                              {bookingDetails.departureText}
                            </Box>
                          </Grid>
                        </Grid>
                      </ListItemText>
                    </ListItem>
                    <Divider />
                    <ListItem className={classes.infoI}>
                      <ListItemText>
                        <Grid container>
                          <Grid item lg={4}>
                            <Typography className={classes.label}>
                              Booking Destination:
                            </Typography>
                          </Grid>
                          <Grid item lg={8}>
                            <Box className={classes.value}>
                              {bookingDetails.destinationText}
                            </Box>
                          </Grid>
                        </Grid>
                      </ListItemText>
                    </ListItem>
                    <Divider />
                    <ListItem className={classes.infoI}>
                      <ListItemText>
                        <Grid container>
                          <Grid item lg={4}>
                            <Typography className={classes.label}>
                              Rider Id:
                            </Typography>
                          </Grid>
                          <Grid item lg={8}>
                            <Box className={classes.value}>
                              <Link
                                to={`/app/customers/${bookingDetails.rider}`}
                              >
                                {bookingDetails.rider}
                              </Link>
                            </Box>
                          </Grid>
                        </Grid>
                      </ListItemText>
                    </ListItem>
                    <Divider />
                    <ListItem>
                      <Typography className={classes.label}>
                        Booked Trip Information:
                      </Typography>
                    </ListItem>
                    <Divider />
                    <ListItem className={classes.infoI}>
                      <ListItemText>
                        <Grid container>
                          <Grid item lg={4}>
                            <Typography className={classes.label}>
                              Trip Id:
                            </Typography>
                          </Grid>
                          <Grid item lg={8}>
                            <Box className={classes.value}>
                              <Link to={`/app/trips/${bookingDetails.tripId}`}>
                                {bookingDetails.tripId}
                              </Link>
                            </Box>
                          </Grid>
                        </Grid>
                      </ListItemText>
                    </ListItem>
                    <Divider />
                    <ListItem className={classes.infoI}>
                      <ListItemText>
                        <Grid container>
                          <Grid item lg={4}>
                            <Typography className={classes.label}>
                              Booked Trip Stops:
                            </Typography>
                          </Grid>
                          <Grid item lg={8}>
                            {bookingDetails?.trip?.stops?.map(stop => (
                              <Typography
                                key={stop.name}
                                className={classes.value}
                              >
                                {stop.address}
                              </Typography>
                            ))}
                          </Grid>
                        </Grid>
                      </ListItemText>
                    </ListItem>
                    <Divider />
                    <ListItem className={classes.infoI}>
                      <ListItemText>
                        <Grid container>
                          <Grid item lg={4}>
                            <Typography className={classes.label}>
                              Payment Status:
                            </Typography>
                          </Grid>
                          <Grid item lg={8}>
                            <Box className={classes.value}>
                              <TripStatus
                                status={bookingDetails.paymentStatus}
                              />
                            </Box>
                          </Grid>
                        </Grid>
                      </ListItemText>
                    </ListItem>
                    <Divider />
                    <ListItem className={classes.infoI}>
                      <ListItemText>
                        <Grid container>
                          <Grid item lg={4}>
                            <Typography className={classes.label}>
                              Payment Ref:
                            </Typography>
                          </Grid>
                          <Grid item lg={8}>
                            <Box className={classes.value}>
                              {bookingDetails.paymentRef}
                            </Box>
                          </Grid>
                        </Grid>
                      </ListItemText>
                    </ListItem>
                    <Divider />
                    <ListItem className={classes.resolveRow}>
                      <ListItemText>
                        <Grid container>
                          <Grid item lg={4}>
                            <Typography className={classes.label}>
                              Payment Access Code:
                            </Typography>
                          </Grid>
                          <Grid item lg={8}>
                            <Box className={classes.value}>
                              {bookingDetails.paymentAccessCode}
                            </Box>
                          </Grid>
                        </Grid>
                      </ListItemText>
                    </ListItem>
                    <Divider />
                    <ListItem className={classes.infoI}>
                      <Typography className={classes.label}>
                        Booked Vehicle Information
                      </Typography>
                    </ListItem>
                    <Divider />
                    <ListItem className={classes.infoI}>
                      <ListItemText>
                        <Grid container>
                          <Grid item lg={4}>
                            <Typography className={classes.label}>
                              Vehicle information:
                            </Typography>
                          </Grid>
                          <Grid item lg={8}>
                            <Box className={classes.value}>
                              {bookingDetails.vehicle.color}
                            </Box>
                          </Grid>
                        </Grid>
                      </ListItemText>
                    </ListItem>
                    <Divider />
                    <ListItem className={classes.infoI}>
                      <ListItemText>
                        <Grid container>
                          <Grid item lg={4}>
                            <Typography className={classes.label}>
                              Vehicle License Plate:
                            </Typography>
                          </Grid>
                          <Grid item lg={8}>
                            <Box className={classes.value}>
                              {bookingDetails.vehicle.licensePlate}
                            </Box>
                          </Grid>
                        </Grid>
                      </ListItemText>
                    </ListItem>
                    <Divider />
                    <ListItem className={classes.infoI}>
                      <ListItemText>
                        <Grid container>
                          <Grid item lg={4}>
                            <Typography className={classes.label}>
                              Vehicle Make:
                            </Typography>
                          </Grid>
                          <Grid item lg={8}>
                            <Box className={classes.value}>
                              {bookingDetails.vehicle.make}
                            </Box>
                          </Grid>
                        </Grid>
                      </ListItemText>
                    </ListItem>
                    <Divider />
                    <ListItem className={classes.infoI}>
                      <ListItemText>
                        <Grid container>
                          <Grid item lg={4}>
                            <Typography className={classes.label}>
                              Vehicle Model:
                            </Typography>
                          </Grid>
                          <Grid item lg={8}>
                            <Box className={classes.value}>
                              {bookingDetails.vehicle.model}
                            </Box>
                          </Grid>
                        </Grid>
                      </ListItemText>
                    </ListItem>
                    <Divider />
                    <ListItem className={classes.infoI}>
                      <ListItemText>
                        <Grid container>
                          <Grid item lg={4}>
                            <Typography className={classes.label}>
                              Vehicle Type:
                            </Typography>
                          </Grid>
                          <Grid item lg={8}>
                            <Box className={classes.value}>
                              {bookingDetails.vehicle.type}
                            </Box>
                          </Grid>
                        </Grid>
                      </ListItemText>
                    </ListItem>
                    <Divider />
                    <ListItem className={classes.infoI}>
                      <ListItemText>
                        <Grid container>
                          <Grid item lg={4}>
                            <Typography className={classes.label}>
                              Vehicle Plate Number Expiry:
                            </Typography>
                          </Grid>
                          <Grid item lg={8}>
                            <Box className={classes.value}>
                              {moment(
                                bookingDetails.vehicle.plateNumberExpiryDate,
                                'YYYY-MM-DD'
                              ).format('ddd, MMMM Do YYYY')}
                            </Box>
                          </Grid>
                        </Grid>
                      </ListItemText>
                    </ListItem>
                    <Divider />
                    <ListItem>
                      <Typography className={classes.label}>
                        Booked Driver Information
                      </Typography>
                    </ListItem>
                    <Divider />
                    <ListItem className={classes.infoI}>
                      <ListItemText>
                        <Grid container>
                          <Grid item lg={4}>
                            <Typography className={classes.label}>
                              Driver Name:
                            </Typography>
                          </Grid>
                          <Grid item lg={8}>
                            <Box className={classes.value}>
                              {bookingDetails.driver.firstName}{' '}
                              {bookingDetails.driver.lastName}
                            </Box>
                          </Grid>
                        </Grid>
                      </ListItemText>
                    </ListItem>
                    <Divider />
                    <ListItem className={classes.infoI}>
                      <ListItemText>
                        <Grid container>
                          <Grid item lg={4}>
                            <Typography className={classes.label}>
                              Driver Id:
                            </Typography>
                          </Grid>
                          <Grid item lg={8}>
                            <Box className={classes.value}>
                              <Link
                                to={`/app/customers/${bookingDetails.driver.id}`}
                              >
                                {bookingDetails.driver.id}
                              </Link>
                            </Box>
                          </Grid>
                        </Grid>
                      </ListItemText>
                    </ListItem>
                    <Divider />
                    <ListItem className={classes.infoI}>
                      <ListItemText>
                        <Grid container>
                          <Grid item lg={4}>
                            <Typography className={classes.label}>
                              Driver Mobile:
                            </Typography>
                          </Grid>
                          <Grid item lg={8}>
                            <Box className={classes.value}>
                              {`${bookingDetails.driver.mobileCode}${bookingDetails.driver.mobile}`}
                            </Box>
                          </Grid>
                        </Grid>
                      </ListItemText>
                    </ListItem>
                    <Divider />
                    <ListItem className={classes.infoI}>
                      <ListItemText>
                        <Grid container>
                          <Grid item lg={4}>
                            <Typography className={classes.label}>
                              Driver License Expiry Date:
                            </Typography>
                          </Grid>
                          <Grid item lg={8}>
                            <Box className={classes.value}>
                              {moment(
                                bookingDetails.owner.driverLicenseExpiryDate,
                                'DD/MM/YYYY'
                              ).format('ddd, MMMM Do YYYY')}
                            </Box>
                          </Grid>
                        </Grid>
                      </ListItemText>
                    </ListItem>
                    <Divider />
                    <ListItem className={classes.infoI}>
                      <ListItemText>
                        <Grid container>
                          <Grid item lg={4}>
                            <Typography className={classes.label}>
                              Driver Email:
                            </Typography>
                          </Grid>
                          <Grid item lg={8}>
                            <Box className={classes.value}>
                              {`${bookingDetails.owner.email}`}
                            </Box>
                          </Grid>
                        </Grid>
                      </ListItemText>
                    </ListItem>
                    <Divider />

                    <ListItem className={classes.infoI}>
                      <ListItemText>
                        <Grid container>
                          <Grid item lg={4}>
                            <Typography className={classes.label}>
                              Driver Home and Away Route:
                            </Typography>
                          </Grid>
                          <Grid item lg={8}>
                            <Box className={classes.value}>
                              {`Home: ${bookingDetails?.owner?.homeAndAway?.home?.address}`}
                            </Box>
                            <Box className={classes.value}>
                              {`Away: ${bookingDetails?.owner?.homeAndAway?.away?.address}`}
                            </Box>
                          </Grid>
                        </Grid>
                      </ListItemText>
                    </ListItem>
                    {bookingDetails.bookingStatus === 'Pending' && (
                      <>
                        <ListItem className={classes.resolveRow}>
                          <ListItemText>
                            <Grid container>
                              <Grid item lg={4}>
                                <Typography className={classes.label}>
                                  Cancel Booking:
                                </Typography>
                              </Grid>
                              <Grid item lg={8}>
                                <Box className={classes.value}>
                                  <Button
                                    className={classes.cancel}
                                    variant="contained"
                                    onClick={handleCancelDialog}
                                  >
                                    Cancel Booking
                                  </Button>
                                </Box>
                              </Grid>
                            </Grid>
                          </ListItemText>
                        </ListItem>
                      </>
                    )}
                    <Divider />
                    {bookingDetails?.cancelledByAdmin && (
                      <>
                        <ListItem className={classes.resolveRow}>
                          <ListItemText>
                            <Grid container>
                              <Grid item lg={4}>
                                <Typography className={classes.label}>
                                  Cancel By:
                                </Typography>
                              </Grid>
                              <Grid item lg={8}>
                                <Box className={classes.value}>
                                  {bookingDetails?.cancelledByAdmin?.role} Admin
                                </Box>
                              </Grid>
                            </Grid>
                          </ListItemText>
                        </ListItem>
                        <Divider />
                        {bookingDetails?.cancelledByAdmin && (
                          <ListItem className={classes.resolveRow}>
                            <ListItemText>
                              <Grid container>
                                <Grid item lg={4}>
                                  <Typography className={classes.label}>
                                    Admin Name:
                                  </Typography>
                                </Grid>
                                <Grid item lg={8}>
                                  <Box className={classes.value}>
                                    {
                                      bookingDetails?.cancelledByAdmin
                                        ?.firstName
                                    }{' '}
                                    {bookingDetails?.cancelledByAdmin?.lastName}
                                  </Box>
                                </Grid>
                              </Grid>
                            </ListItemText>
                          </ListItem>
                        )}
                      </>
                    )}
                    <Divider />
                  </List>
                </CardContent>
              </Card>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
