import { adminActionList } from '../action-lists';

/**
 *
 * @param {boolean} data
 */
const setDashboardInfo = (payload) => {
  return { type: adminActionList.dashboardInfo, payload };
};

export default {
  setDashboardInfo
};
