import React, { useState } from 'react';
import { Container, Grid, makeStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import Page from 'src/components/Page';
import Admin from 'src/api/admin';
import userActions from 'src/store/actions/userActions';
import storage from 'src/storage';
import Profile from './Profile';
import ProfileDetails from './ProfileDetails';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const AdminProfile = () => {
  const dispatch = useDispatch();
  const adminData = useSelector(state => state.user);
  const classes = useStyles();

  const [profileFormdata, setProfileFormData] = useState(adminData);
  const [isUpdating, setIsUpdating] = useState(false);
  // const [admin, setAdmin] = useState(adminData);

  const handleChange = e => {
    const { name, value } = e.target;
    setProfileFormData(prev => ({ ...prev, [name]: value }));
  };

  const updateProfileDetails = async () => {
    const changedFields = Object.entries(profileFormdata).reduce(
      (fields, curKeyValuePair) => {
        const [key, value] = curKeyValuePair;
        if (value !== adminData[key]) fields[key] = value;
        return fields;
      },
      {}
    );

    // there are no changed fields, no point sending request
    if (!Object.keys(changedFields).length) return;

    try {
      setIsUpdating(true);
      const data = await Admin.adminEditSelf(changedFields);
      storage.setUser({ ...storage.getUser(), ...data });
      dispatch(userActions.updateUser({ ...storage.getUser(), ...data }));
      setIsUpdating(false);
    } catch (error) {
      setIsUpdating(false);
      console.log(error);
    }
  };

  return (
    <Page className={classes.root} title="Profile">
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item lg={4} md={6} xs={12}>
            <Profile admin={profileFormdata} />
          </Grid>
          <Grid item lg={8} md={6} xs={12}>
            <ProfileDetails
              formData={profileFormdata}
              admin={profileFormdata}
              onChange={handleChange}
              onSave={updateProfileDetails}
              isUpdating={isUpdating}
            />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default AdminProfile;
