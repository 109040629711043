import config from 'src/config';
import api from 'src/services/httpService';
import storage from 'src/storage';

class Pages {
  static getCustomerPage(id) {
    const axiosConfig = {
      headers: {
        Authorization: `Bearer ${storage.getToken()}`
      }
    };
    // console.log(axiosConfig);
    return api
      .get(`${config.rootUrl}/pages/admin-view/customer/${id}`, axiosConfig)
      .then(({ data }) => data);
  }
}

export default Pages;
