import React from 'react';
import routes from 'src/routes';
import { useRoutes } from 'react-router-dom';
import { Backdrop, CircularProgress, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import AlertSnackbar from 'src/components/AlertSnackbar';

const useStyles = makeStyles(themer => ({
  backdrop: {
    zIndex: themer.zIndex.drawer + 1,
    color: '#fff'
  }
}));
const Views = ({ appSettings }) => {
  const routing = useRoutes(routes);
  const classes = useStyles();
  return (
    <>
      <Backdrop className={classes.backdrop} open={appSettings.pageLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <AlertSnackbar />
      {routing}
    </>
  );
};

const mapStateToProps = (state /* , ownProps */) => {
  return {
    appSettings: state.appSettings,
    alertSnackbar: state.alertSnackbar
  };
};

Views.propTypes = {
  appSettings: PropTypes.object
};

export default connect(mapStateToProps)(Views);
