/* eslint-disable react/react-in-jsx-scope */
import React from 'react';
import PropTypes from 'prop-types';

export default function NativeSelects({
  value,
  selectOptions,
  selectHandler,
  label
}) {
  // const [selectValue, setSelectValue] = useState();
  console.log('select state>>>', value);
  console.log('select options>>>', selectOptions);

  const handleChangeSelect = event => {
    selectHandler(event.target.value);
  };

  return (
    <div>
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label htmlFor="options">{label}</label>
      <select
        style={{ width: '100%' }}
        id="options"
        value={value}
        onChange={handleChangeSelect}
      >
        <option value="">Select...</option>
        {selectOptions.map(opt => (
          <option key={opt} value={opt}>
            {opt}
          </option>
        ))}
      </select>
    </div>
  );
}

NativeSelects.propTypes = {
  value: PropTypes.string.isRequired,
  selectHandler: PropTypes.func.isRequired,
  selectOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
  label: PropTypes.string.isRequired
};
