import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import {
  Avatar,
  Box,
  Card,
  Container,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles
} from '@material-ui/core';
import storage from 'src/storage';
import config from 'src/config';
import getInitials from 'src/utils/getInitials';
import qs from 'qs';
import { useLocation, useNavigate } from 'react-router';
import api from 'src/services/httpService';
import TableLoader from 'src/components/TableLoader';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(0)
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  tableRow: {
    cursor: 'pointer'
  },
  sortIcons: {
    display: 'flex',
    flexDirection: 'row',
    overflow: 'hidden',
    width: 'fit-content',
    margin: 0,
    fontWeight: 500,
    alignItems: 'center'
  },
  tableHeadStyling: {
    display: 'flex',
    flexDirection: 'row',
    overflow: 'hidden',
    backgroundColor: 'transparent',
    border: 'none'
  },
  verifiedSortedDriver: {
    display: 'flex',
    justifyContent: 'right'
  },
  tablecell: {
    borderBottom: '1px solid lightgrey'
  }
}));

const Results = ({
  className,
  activities,
  pagination,
  isLoading,
  sortedData,
  ...rest
}) => {
  console.log(activities);
  console.log(pagination);
  const [sortedActivities, setSortedActivities] = useState(sortedData);
  const [switchSort, setSwitchSort] = useState('sorted');
  useEffect(() => {
    setSortedActivities(activities);

    if (switchSort === 'unSortedActivityRead') {
      setSortedActivities(
        activities.sort((a, b) => (a.activityRead > b.activityRead ? -1 : 1))
      );
    } else if (switchSort === 'sorted') {
      setSortedActivities(
        activities.sort((a, b) => (a.firstName < b.firstName ? 1 : -1))
      );
    } else if (switchSort === 'unsorted') {
      setSortedActivities(
        activities.sort((a, b) => (a.firstName < b.firstName ? -1 : 1))
      );
    } else if (switchSort === 'sortedActivityRead') {
      setSortedActivities(
        activities.sort((a, b) => (a.activityRead > b.activityRead ? 1 : -1))
      );
    } else if (switchSort === 'unSortedActivityRead') {
      setSortedActivities(
        activities.sort((a, b) => (a.activityRead > b.activityRead ? -1 : 1))
      );
    } else if (switchSort === 'sortedDate') {
      setSortedActivities(
        activities.sort((a, b) =>
          new Date(a.updatedAt) > new Date(b.updatedAt) ? -1 : 1
        )
      );
    } else if (switchSort === 'unSortedDate') {
      setSortedActivities(
        activities.sort((a, b) =>
          new Date(a.updatedAt) > new Date(b.updatedAt) ? 1 : -1
        )
      );
    }
  }, [switchSort, activities]);

  const location = useLocation();
  const navigate = useNavigate();

  const classes = useStyles();

  const [queries, setQueries] = useState({});

  useEffect(() => {
    const { search } = location;
    setQueries(qs.parse(search, { ignoreQueryPrefix: true }));
  }, [location]);

  const handleLimitChange = event => {
    const { pathname } = location;
    const newQueries = { ...queries, rows: event.target.value };
    navigate(`${pathname}?${qs.stringify(newQueries)}`);
  };

  const handlePageChange = (event, newPage) => {
    const { pathname } = location;
    const newQueries = { ...queries, pageNo: newPage + 1 };
    navigate(`${pathname}?${qs.stringify(newQueries)}`);
  };
  // --------Desmond--Calling-The-Read-Activity-API------//
  const ReadActivity = async id => {
    const auth = {
      headers: { Authorization: `Bearer ${storage.getToken()}` }
    };
    api
      .post(`${config.rootUrl}/admin/activity/${id}/seen?`, '', auth)
      .then(({ data: isData }) => {
        if (isData) console.log('Done');
      })
      .catch(error => {
        console.log(error);
      });
  };
  // --------Desmond--Calling-The-Read-Activity-API------//

  const handleRowClick = (id, activityId) => {
    navigate(`/app/customers/${id}`);
    ReadActivity(activityId);
  };

  return (
    <>
      {isLoading && isLoading ? (
        <TableLoader />
      ) : (
        <Card className={clsx(classes.root, className)} {...rest}>
          <PerfectScrollbar>
            <Container maxWidth={false}>
              <Box minWidth={1050}>
                <Table>
                  <TableHead>
                    <TableRow className={classes.tablecell}>
                      <TableCell
                        onClick={() => {
                          setSwitchSort(
                            switchSort === 'sorted' ? 'unsorted' : 'sorted'
                          );
                        }}
                      >
                        <Typography className={classes.sortIcons}>
                          Name
                          {switchSort === 'sorted' ? (
                            <ArrowUpward />
                          ) : (
                            <ArrowDownward />
                          )}
                        </Typography>
                      </TableCell>
                      <TableCell
                        onClick={() => {
                          setSwitchSort(
                            switchSort === 'sortedActivityRead'
                              ? 'unSortedActivityRead'
                              : 'sortedActivityRead'
                          );
                        }}
                      >
                        <Typography className={classes.sortIcons}>
                          Activity Read
                          {switchSort !== 'sortedActivityRead' ? (
                            <ArrowUpward />
                          ) : (
                            <ArrowDownward />
                          )}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography className={classes.sortIcons}>
                          Activity Type
                        </Typography>
                      </TableCell>
                      <TableCell
                        onClick={() => {
                          setSwitchSort(
                            switchSort === 'sortedDate'
                              ? 'unSortedDate'
                              : 'sortedDate'
                          );
                        }}
                      >
                        <Typography className={classes.sortIcons}>
                          Last Activity
                          {switchSort === 'sortedDate' ? (
                            <ArrowUpward />
                          ) : (
                            <ArrowDownward />
                          )}
                        </Typography>
                      </TableCell>
                      {/* <TableCell>
                  <Typography className={classes.sortIcons}>
                    Last Activity
                  </Typography>
                </TableCell> */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {sortedActivities &&
                      sortedActivities.map((activity, index) => (
                        <TableRow
                          hover
                          // eslint-disable-next-line react/no-array-index-key
                          key={`${activity.id}${index}`}
                          onClick={() => {
                            handleRowClick(
                              activity.userId,
                              activity.activityId
                            );
                          }}
                          className={classes.tableRow}
                        >
                          <TableCell>
                            <Box alignItems="center" display="flex">
                              <Avatar
                                className={classes.avatar}
                                src={activity.avatarUrl}
                              >
                                {getInitials(
                                  `${activity.firstName}  ${activity.lastName}`
                                )}
                              </Avatar>
                              <Typography color="textPrimary" variant="body1">
                                {`${activity.firstName}  ${activity.lastName}`}
                              </Typography>
                            </Box>
                          </TableCell>

                          <TableCell>
                            {activity.activityRead ? 'Read' : 'Unread'}
                          </TableCell>
                          <TableCell>{activity.activityType}</TableCell>
                          <TableCell>
                            {moment(
                              new Date(activity.updatedAt).toLocaleDateString()
                            ).format('ddd, MMM Do YYYY')}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </Box>
            </Container>
          </PerfectScrollbar>
          <TablePagination
            component="div"
            count={pagination.totalCount}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleLimitChange}
            page={pagination.currentPage - 1}
            rowsPerPage={pagination.rows || 10}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </Card>
      )}
    </>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  activities: PropTypes.array.isRequired,
  pagination: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  sortedData: PropTypes.array
};

export default Results;
