import React from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';
import TransactionRow from 'src/components/TransactionRow';

const WalletTransactions = ({ isLoading, recentTransactions }) => {
  return (
    <Card>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Type</TableCell>
                <TableCell>Previous Balance</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>Balance</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Summary</TableCell>
              </TableRow>
            </TableHead>
            {isLoading && <CircularProgress size="2rem" />}
            {recentTransactions ? (
              <TableBody>
                {recentTransactions.map(transaction => (
                  <TransactionRow transaction={transaction} />
                ))}
              </TableBody>
            ) : (
              <Box style={{ margin: '2rem', alignSelf: 'center' }}>
                No transaction yet!
              </Box>
            )}
          </Table>
        </Box>
      </PerfectScrollbar>
    </Card>
  );
};

WalletTransactions.propTypes = {
  isLoading: PropTypes.bool,
  recentTransactions: PropTypes.array
};

export default WalletTransactions;
