import { axiosActionList } from '../action-lists';

const axiosGetUser = (data) => {
  return { type: axiosActionList.axiosGetUser, payload: data };
};

const axiosGetUsers = (data) => {
  return { type: axiosActionList.axiosGetUsers, payload: data };
};

const axiosGetAdmins = (data) => {
  return { type: axiosActionList.axiosGetAdmins, payload: data };
};

const axiosGetActivity = (data) => {
  return {type: axiosActionList.axiosGetActivity, payload: data }
}

export default {
  axiosGetUser,
  axiosGetUsers,
  axiosGetAdmins,
  axiosGetActivity,
};
