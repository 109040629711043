const currenciesSymbol = {
  NGN: '₦'
};

const getCurrencySymbol = currency => currenciesSymbol[currency] || currency;

export default (price = '', currency = 'NGN', converter) => {
  if (typeof converter === 'function') {
    price = converter(price);
  }
  return `${getCurrencySymbol(currency)}${price.toLocaleString()}`;
};



