import React from 'react';
import DeletionRequestList from './DeletionRequestList';

export default function DeletionRequest() {
  return (
    <div>
      <DeletionRequestList />
    </div>
  );
}
