import { combineReducers } from 'redux';
import alertSnackbarReducer from './alertSnackbarReducer';
import userReducer from './userReducer';
import userListReducer from './userListReducer';
import adminListReducer from './adminListReducer';
import appSettingsReducer from './appSettingsReducer';
import userActivityReducer from './userActivityReducer';

/**
 * Combine your reducers here
 */
const rootReducer = combineReducers({
  alertSnackbar: alertSnackbarReducer,
  user: userReducer,
  userList: userListReducer,
  adminList: adminListReducer,
  appSettings: appSettingsReducer,
  userActivity: userActivityReducer
});

export default rootReducer;
